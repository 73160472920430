import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_COMPLETED_LOAN_DETAILS;

export const showDefaultedLoanDetails = createAsyncThunk(
    "defaultedLoan/showDefaultedLoanDetails",
    async (data) => {

        // console.log("Defaulted Loan Details Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Defaulted Loan Details Slice - response:", response);
        const defaultedLoanDetailsPayload = await response.data.data;
        // const defaultedLoanDetailsPayload = await response.data;
        // console.log("Defaulted Loan Details Slice - payload:", defaultedLoanDetailsPayload);

        return defaultedLoanDetailsPayload;
    }
);

const showDefaultedLoanDetailsSlice = createSlice({
    name: "showDefaultedLoanDetailsSlice",
    initialState: {
        loading: false,
        defaultedLoanDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showDefaultedLoanDetails.pending, (state) => {
            state.loading = true;
            state.defaultedLoanDetails = null;
            state.error = null;
        });
        builder.addCase(showDefaultedLoanDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultedLoanDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showDefaultedLoanDetails.rejected, (state, action) => {
            state.loading = false;
            state.defaultedLoanDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getDefaultedLoanDetails = (state) => state.defaultedLoanDetails;
export default showDefaultedLoanDetailsSlice.reducer;
