import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_DETAILS;
// const URL = "http://localhost:8000/user/admin/wallet/details";

export const showPlatformWalletInfo = createAsyncThunk(
    "platformWallet/info",
    async (params) => {
        const data={
            userId:params.userId
           };
           delete params.userId;
            const reqConfig = {
                method: "POST",
                url: URL,
                params,
                data
            };
         const response = await api(reqConfig);

        // console.log("All Wallets Status Log List Slice - URL & Params:", URL, params);
        // const response = await postAPI(URL, params)

        // const allWalletsPayload = await response.data.data.;
        // console.log("All Wallets Details Slice - response:", response);
        const walletInfoPayload = await response.data.data;
        // console.log("All Wallets Details Slice - payload:", walletInfoPayload);
        return walletInfoPayload;
    }
);

const platformWalletInfoSlice = createSlice({
    name: "platformWallet",
    initialState: {
        loading: false,
        info: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showPlatformWalletInfo.pending, (state) => {
            state.loading = true;
            state.info = null;
            state.error = null;
        });
        builder.addCase(showPlatformWalletInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.info = action.payload;
            state.error = null;
        });
        builder.addCase(showPlatformWalletInfo.rejected, (state, action) => {
            state.loading = false;
            state.info = null;
            state.error = action.error.message;
        });
    },
});

export const getPlatformWalletInfo = (state) => state.platformWalletInfo;
export default platformWalletInfoSlice.reducer;

