import React, { useEffect, useState } from "react";

// Elements
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";

import { useDispatch, useSelector } from "react-redux";
import {
  showPlatformWalletInfo,
  getPlatformWalletInfo,
} from "../../../features/wallet-management/plaformWalletInfoSlice";
import {
  formatDate,
  formatTime,
} from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { api, postAPI } from "../../../api/apiCallMethods";

const PLATFORMINFO = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const [userId, setUserId] = useState(useParams().id);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filterType, setfilterType] = useState(undefined);
  const [filterValue, setfilterValue] = useState(undefined);
  const [changedBy, setChangedBy] = useState(undefined);
  const [comment, setComment] = useState(undefined);
  const [changedByUserId, setChangedByUserId] = useState(undefined);
  const [changedReason, setChangedReason] = useState(undefined);

  const params = {
    userId,
    limit,
    page,
    orderBy,
    sortType,
    filterType,
    filterValue,
  };

  const dispatch = useDispatch();
  const platformWalletInfoSlice = useSelector(getPlatformWalletInfo);
  const info = platformWalletInfoSlice?.info;

  // console.log("Here in info, will be displauing the ==info slice== for all wallets", walletInfoSlice);

  console.log(
    "Here in info, will be displaying the ==info== for platform wallet(s)",
    info
  );

  useEffect(() => {
    (async () => {
      await dispatch(showPlatformWalletInfo(params));
    })();
  }, [userId, limit, page, orderBy, sortType, filterType, filterValue]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleActionWallet = async () => {
    const reqConfig = {
      method: "POST",
      url:
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_LIST_ALL_WALLETS_ACTION,
      data: {
        userId,
        changedBy: changedBy ?? "admin",
        comment: comment ?? "static comment",
        changedByUserId: changedByUserId ?? "1",
        changedReason: changedReason ?? "Static Changed Reason",
      },
    };

    await api(reqConfig);

    dispatch(showPlatformWalletInfo(params));
    setShow(false);
  };

  return (
    <>
      <div className="borrowerInfoBox">
        <div className="borrowerInfo">
          <Row>
            <Col xl="9">
              <Row>
                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Created On</h5>
                    <h4>
                      <span className="date">
                        {info?.createdAt ? formatDate(info?.createdAt) : "N/A"}
                      </span>
                      <span className="time">
                        {info?.createdAt ? formatTime(info?.createdAt) : ""}
                      </span>
                    </h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>IBAN</h5>
                    <h4>{info?.viban ? info?.viban : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Holder ID</h5>
                    <h4>{info?.idNumber ? info?.idNumber : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="3">
                  <div className="infoBox">
                    <h5>Holder Name</h5>
                    <h4>{info?.fullName ? info?.fullName : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="1">
                  <div className="infoBox">
                    <h5>Status</h5>
                    <h4 className="status">
                      {info?.active == "true" ? <>Active</> : <>Inactive</>}
                    </h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Wallet Balance</h5>
                    <h4>
                      SAR{" "}
                      {info?.balance ? formattedNumber(info.balance) : "N/A"}{" "}
                    </h4>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl="2"></Col>

            <Col xl="1">
              <div className="btnBox">
                {/* <Button variant="primary" onClick={handleShow}>
                  {info?.active == "true" ? (
                    <>Block Wallet</>
                  ) : (
                    <>Unblock Wallet</>
                  )}
                </Button> */}
              </div>
            </Col>
          </Row>
        </div>

        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="banForm confirm"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="d-flex justify-content-center">
            <Modal.Title id="contained-modal-title-vcenter">
              Are you sure you want to ban this user?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="confirmBtns d-flex justify-content-center">
              <Button className="btn-white me-3" onClick={handleActionWallet}>
                Yes
              </Button>

              <Button onClick={handleClose}>Cancel</Button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="banForm "
    >
      {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Active Loan Application Details
                </Modal.Title>
            </Modal.Header> */}
      <Modal.Body>
        <form>
          <Row className="mb-4">
            <Col lg="11">
              <Row>
                <Col lg="3" className="mb-4">
                  <div className="popupDv">
                    <h4>Wallet IBAN</h4>
                    <h5>0152150111</h5>
                  </div>
                </Col>

                <Col lg="3" className="mb-4">
                  <div className="popupDv">
                    <h4>Holder ID</h4>
                    <h5>12013651586</h5>
                  </div>
                </Col>

                <Col lg="6" className="mb-4">
                  <div className="popupDv">
                    <h4>Holder Name</h4>
                    <h5>Ahmed Fahad Mohammad Saud</h5>
                  </div>
                </Col>

                <Col lg="3" className="mb-4">
                  <div className="popupDv">
                    <h4>Current Status</h4>
                    <h5>
                      <div className="status">
                        <span className="dot"></span>
                        <span>Active</span>
                      </div>
                    </h5>
                  </div>
                </Col>

                <Col lg="3" className="mb-4">
                  <div className="popupDv">
                    <h4>Wallet Balance</h4>
                    <h5>SAR 2500</h5>
                  </div>
                </Col>

                <Col lg="6"></Col>

                <Col lg="5" className="mb-3">
                  <div className="popupDv">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="0">Active</option>
                        <option value="1">Deactive</option>
                        <option value="2">Suspend</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>

                <Col lg="5" className="mb-3">
                  <div className="popupDv">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Reason</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Other</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="12" className="mb-3">
              <div className="popupDv">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-2">Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your comment"
                  />
                </Form.Group>
              </div>
            </Col>

            <Col lg="12">
              <Button className="btn-white me-3" onClick={props.onConfirm}>
                Apply
              </Button>

              <Button onClick={props.onHide}>Cancel</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default PLATFORMINFO;
