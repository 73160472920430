import { jwtDecode } from "jwt-decode";


export function isValidJWT(token) {
    // Regular expression to match JWT token format
    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;

    return jwtRegex.test(token);
};


export function getUserRoleData() {
    let decoded = undefined;
    // get token
    // get user role data
    // compare and extract the current user role
    // return the role data object
    const storedUserData = localStorage.getItem("userRoles");
    const token = localStorage.getItem("userToken");


    if (isValidJWT(token)) {
        decoded = jwtDecode(token);
    } else {

    }

    const { roleId } = decoded;
    if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        const filteredRole = parsedUserData.find(role => role.roleId === String(roleId));
        if (filteredRole) {
            return filteredRole;
        } else {
            console.log("No role data found for the specified role ID.");
        }
    }
}