import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_USER_LISTING;

export const listAdminUsers = createAsyncThunk(
    "adminUsers/listAdminUsers",
    async (params) => {
        
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // // console.log("Admin Users List Slice - URL & Params:", URL, params);
        // // const response = await getAPI(URL, params)
        // const response = await getAPI(URL, params);
        // // const adminUsersPayload = await response.data.data.;
        // // console.log("Admin Users List Slice - response:", response);
        const adminUsersPayload = await response.data;
        // console.log("Admin Users List Slice - payload:", adminUsersPayload);
        return adminUsersPayload;
    }
);

const listAdminUsersSlice = createSlice({
    name: "listAdminUsers",
    initialState: {
        loading: false,
        adminUsers: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listAdminUsers.pending, (state) => {
            state.loading = true;
            state.adminUsers = null;
            state.error = null;
        });
        builder.addCase(listAdminUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.adminUsers = action.payload;
            state.error = null;
        });
        builder.addCase(listAdminUsers.rejected, (state, action) => {
            state.loading = false;
            state.adminUsers = null;
            state.error = action.error.message;
        });
    },
});

export const getAdminUsersList = (state) => state.adminUsersList;
export default listAdminUsersSlice.reducer;

