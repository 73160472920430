import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


// const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LENDER_OPPORTUNITIES;
const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_LENDER_INVESTMENTS;

export const listInvestments = createAsyncThunk(
    "lender/listInvestments",
    async (data) => {
        // console.log("Lender Investments Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data)
        // console.log("Lender Investments Slice - response:", response);
        const investmentPayload = await response.data.data.data;
        // console.log("List Investment Slice - payload:", investmentPayload);

        return investmentPayload;
    }
);

const investmentSlice = createSlice({
    name: "lenderInvestments",
    initialState: {
        loading: false,
        investments: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listInvestments.pending, (state) => {
            state.loading = true;
            state.investments = null;
            state.error = null;
        });
        builder.addCase(listInvestments.fulfilled, (state, action) => {
            state.loading = false;
            state.investments = action.payload;
            state.error = null;
        });
        builder.addCase(listInvestments.rejected, (state, action) => {
            state.loading = false;
            state.investments = null;
            state.error = action.error.message;
        });
    },
});

export const getInvestments = (state) => state.lenderInvestments;

export default investmentSlice.reducer;
