import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_LOGIN_HISTORY;

export const listLogins = createAsyncThunk(
    "user/loginHistory",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };

        const response = await api(reqConfig);
        // console.log("Login History Slice - URL & Params:", URL, params);
        // const response = await getAPI(URL, params);
        // console.log("Login History Slice - response:", response);
        const loginsPayload = await response.data.data;
        // console.log("Login History Slice - payload:", loginsPayload);
        return loginsPayload;
    }
);

const listLoginHistorySlice = createSlice({
    name: "userLoginHistory",
    initialState: {
        loading: false,
        logins: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listLogins.pending, (state) => {
            state.loading = true;
            state.logins = null;
            state.error = null;
        });
        builder.addCase(listLogins.fulfilled, (state, action) => {
            state.loading = false;
            state.logins = action.payload;
            state.error = null;
        });
        builder.addCase(listLogins.rejected, (state, action) => {
            state.loading = false;
            state.logins = null;
            state.error = action.error.message;
        });
    },
});

export const getUserLogins = (state) => state.userLoginsHistory;
export default listLoginHistorySlice.reducer;
