import React from 'react'
import "../../assets/css/activate.css";
import { ColorRing } from 'react-loader-spinner';

const ACTIVATE = () => {


    return (
        <>
        
  <h1>Hello man I am activate componeent</h1>
        </>
    );
};

export default ACTIVATE;
