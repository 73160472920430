import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_DEFAULTED_LOANS_LISTING;

export const listDefaultedLoans = createAsyncThunk(
    "defaultedLoans/listDefaultedLoans",
    async (data) => {

        // console.log("List Defaulted Loans Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const defaultedLoansPayload = await response.data.data;
        // console.log("List Defaulted Loans Slice - response:", response);
        // const defaultedLoansPayload = await response.data;
        // console.log("List Defaulted Loans Slice - payload:", defaultedLoansPayload);
        return defaultedLoansPayload;
    }
);

const listDefaultedLoansSlice = createSlice({
    name: "listDefaultedLoans",
    initialState: {
        loading: false,
        defaultedLoans: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listDefaultedLoans.pending, (state) => {
            state.loading = true;
            state.defaultedLoans = null;
            state.error = null;
        });
        builder.addCase(listDefaultedLoans.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultedLoans = action.payload;
            state.error = null;
        });
        builder.addCase(listDefaultedLoans.rejected, (state, action) => {
            state.loading = false;
            state.defaultedLoans = null;
            state.error = action.error.message;
        });
    },
});

export const getDefaultedLoansList = (state) => state.defaultedLoans;
export default listDefaultedLoansSlice.reducer;
