import React, { useEffect, useState } from "react";
import LoaderSpinner from "../../ui/LoaderSpinner";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
import { api } from "../../../api/apiCallMethods";

const NotificationModal = (props) => {
    const [notificationList, setNotificationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [event, setEvent] = useState("");
    const { user, index, sendNotiShowTop, setSendNotiShowTop } = props;

    const receiverId = user.id;

    const [notificationType, setNotificationType] = useState({
        push: false,
        sms: false,
        email: false
    });

    useEffect(() => {

        (async () => {
            try {
                const reqConfig = {
                    method: "POST",
                    url: process.env.REACT_APP_NOTIFICATION_BASE_URL + process.env.REACT_APP_ADMIN_GET_DIRECT_NOTIFICATION_CONTENT,
                    data: {},
                }
                setLoading(true);
                const response = await api(reqConfig);
                // console.log(response.data);
                if (response.data.code === 200) {
                    setNotificationList(response.data.data);
                } else {
                    throw new Error("Something went wrong!")
                }
            } catch (error) {

                console.log(error.message)
                setError(true);
                setErrorMessage(error.message);

            } finally {
                setLoading(false);
            }
        })()

    }, [])


    const handleEventChange = (e) => {
        setEvent(e.target.value);
        setError(false);
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setNotificationType(prevState => ({
            ...prevState,
            [name]: checked
        }));
        setError(false);
    };

    const handleSendNotification = async (e) => {
        console.log({ event, receiverId, notificationType });
        try {
            if (!event) {
                throw new Error("Please select the notification message!");
            }

            if (!(notificationType.sms || notificationType.push || notificationType.email)) {
                throw new Error("Please select the notification type!");
            }
            const reqConfig = {
                method: "POST",
                url: process.env.REACT_APP_NOTIFICATION_BASE_URL + process.env.REACT_APP_ADMIN_SEND_DIRECT_NOTIFICATION,
                data: {
                    event,
                    receiverId: [receiverId],
                    smsEnabled: notificationType.sms ? "1" : "0",
                    pushEnabled: notificationType.push ? "1" : "0",
                    emailEnabled: notificationType.email ? "1" : "0"
                },
            }
            const response = await api(reqConfig);
            if (response.data.code === 200) {
                setError(false);
                setSuccess(true);
                setSuccessMessage("Notification sent successfully!");
                const updateModalsTop = [...sendNotiShowTop];
                updateModalsTop[index] = false;
                setTimeout(() => {
                    setSendNotiShowTop(updateModalsTop)
                }, 5000);
            } else {
                throw new Error(response.data.message);
            }

        } catch (error) {
            setError(true);
            console.log(error.message);
            setErrorMessage(error.message);
        }

    }


    // console.log("rendered")
    return (
        <>
            {loading && <LoaderSpinner />}
            {/* {error && <h1>An error occured!</h1>} */}
            {!loading && <> <Modal.Header closeButton>
                <Modal.Title>Send manual notification to <strong>{user?.name}</strong></Modal.Title>
            </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formGridState">


                                    <Form.Select defaultValue="Select Template"
                                        onChange={handleEventChange}
                                    >
                                        <option className="light" value="">Select Template</option>
                                        {
                                            notificationList ? notificationList.map((notification, index) => (
                                                <option key={index} value={notification.event}>{notification.contentTitle}</option>
                                            )
                                            ) : null
                                        }

                                    </Form.Select>
                                </Form.Group>

                                {
                                    event &&
                                    <div className="quoteBox"><span>
                                        {
                                            notificationList ? notificationList.map((notification, index) => (
                                                notification.event == event ? notification.contentBody : null
                                            )
                                            ) : null
                                        }
                                    </span></div>
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="checkBoxesRow mb-4">
                                    <Form.Check
                                        aria-label="Push"
                                        label="Push"
                                        name="push"
                                        checked={notificationType.push}
                                        onChange={handleCheckboxChange}
                                    />
                                    <Form.Check
                                        aria-label="SMS"
                                        label="SMS"
                                        name="sms"
                                        checked={notificationType.sms}
                                        onChange={handleCheckboxChange}
                                    />
                                    <Form.Check
                                        aria-label="Email"
                                        label="Email"
                                        name="email"
                                        checked={notificationType.email}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end actionBtns">
                                    <Button variant="" className={`btn-theme-primary`} type="button" onClick={handleSendNotification}>Send Notification</Button>
                                </div>
                            </Col>
                        </Row>

                        {error && <Row className="alert alert-danger mt-5 mx-1 ">

                            {errorMessage}

                        </Row>
                        }

                        {
                            success && <Row className="alert alert-success mt-5 mx-1 ">
                                {successMessage}
                            </Row>
                        }
                    </Form>
                </Modal.Body>
            </>}
        </>
    )
}

export default NotificationModal