import React from "react";
import "../../assets/css/counter/counter.css";
import { useState, useEffect } from "react";
import { showPendingRegApprAnalytics, getPendingRegApprAnalytics } from "../../features/pending-registeration-approvals/pendingRegApprAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";

const EXPIREDAPPCOUNTER = () => {

    const dispatch = useDispatch();
    const counterSlice = useSelector(getPendingRegApprAnalytics)
    const analytics = counterSlice?.analytics;

    // console.log("======The Pending Reg Approval Slice Counter Slice======", counterSlice);
    console.log("======The Pending Reg Approval Analytics======", analytics);
    const params = {
        type: "pendingApproval"
    }

    useEffect(() => {
        (async () => {
            await dispatch(showPendingRegApprAnalytics(params))
        })()
    }, []
    );

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Pending PEP Users</h6>
                        <h1>{analytics?.pep ? analytics?.pep : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Pending Sanctioned Users</h6>
                        <h1>{analytics?.sanctioned ? analytics?.sanctioned : "N/A"}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EXPIREDAPPCOUNTER