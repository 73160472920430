import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_EXPIRED_APPLICATIONS_LISTING;

export const listExpiredApplications = createAsyncThunk(
    "expiredApplication/listExpiredApplication",
    async (data) => {

        // console.log("List Expired Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const expiredApplicationsPayload = await response.data.data;
        // console.log("List Expired Applications List Slice - response:", response);
        // const expiredApplicationsPayload = await response.data;
        // console.log("List Expired Applications List Slice - payload:", expiredApplicationsPayload);
        return expiredApplicationsPayload;
    }
);

const listExpiredApplicationsSlice = createSlice({
    name: "listExpiredApplications",
    initialState: {
        loading: false,
        expiredApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listExpiredApplications.pending, (state) => {
            state.loading = true;
            state.expiredApplications = null;
            state.error = null;
        });
        builder.addCase(listExpiredApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.expiredApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listExpiredApplications.rejected, (state, action) => {
            state.loading = false;
            state.expiredApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getExpiredApplicationsList = (state) => state.expiredApplicationsList;
export default listExpiredApplicationsSlice.reducer;
