import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { listOpportunites, getOpportunitiesList } from "../../../features/lender/opportunintiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const OPPORTUNITIES = () => {

    const [aladetailsShowTop, setAladetailsShowTop] = useState(Array(10).fill(false));
    const [aladetailsShowBottom, setAladetailsShowBottom] = useState(Array(10).fill(false));

    const [lenderId, setLenderId] = useState(useParams().id)
    const [pageFlag, setPageFlag] = useState(1)
    const [limit, setLimit] = useState(undefined)
    const [page, setPage] = useState(undefined)
    const [sortType, setSortType] = useState("desc")
    const [orderBy, setOrderBy] = useState("createdOnDate")
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const params = {
        lenderId,
        pageFlag
    }
    const dispatch = useDispatch();
    const opportunitesSlice = useSelector(getOpportunitiesList)

    const inprocess = opportunitesSlice?.opportunities?.inProcess;
    const opportunityHistory = opportunitesSlice?.opportunities?.opportunityHistory;

    // console.log("opportunity List Slice./..//./../", opportunitesSlice);
    // console.log("opportunity List", inprocess);


    useEffect(() => {
        (async () => {
            await dispatch(listOpportunites(params))
        })()
    }, [lenderId]
    );


    const handleAladetailsShowTop = (index) => {
        // setAladetailsShow(true);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = true;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsCloseTop = (index) => {
        // setAladetailsShow(false);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = false;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsShowBottom = (index) => {
        // setAladetailsShow(true);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = true;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const handleAladetailsCloseBottom = (index) => {
        // setAladetailsShow(false);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = false;
        setAladetailsShowBottom(updateModalsBottom)
    }


    const reload = () => {
        dispatch(listOpportunites(params))
    }

    return (
        <>
            <div className="content">
                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">In Progress Opportunities</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp mb-5">
                    <Table responsive style={{ width: "1870px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Opportunity ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Created on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expiry</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Date</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Invested Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Opportunity Status</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI  %</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI </span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Funding Status</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {opportunitesSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!opportunitesSlice?.loading && opportunitesSlice?.error ? <div>Error: {opportunitesSlice?.error}</div> : null}

                            {!opportunitesSlice?.loading && inprocess?.length ?
                                (inprocess.map((inprocessList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="loan-application-id">
                                                <span>{inprocessList.opportunityId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime createdOnDate">
                                                <span className="date">{inprocessList.createdOnDate ? formatDate(inprocessList.createdOnDate) : "N/A"}</span><br />
                                                <span className="time">{inprocessList.createdOnDate ? formatTime(inprocessList.createdOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{inprocessList.expiryDate ? formatDate(inprocessList.expiryDate) : "N/A"}</span><br />
                                                <span className="time">{inprocessList.expiryDate ? formatTime(inprocessList.expiryDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{inprocessList.investmentDate ? formatDate(inprocessList.investmentDate) : "N/A"}</span><br />
                                                <span className="time">{inprocessList.investmentDate ? formatTime(inprocessList.investmentDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance">
                                                {
                                                    inprocessList.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(inprocessList.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="walletBalance">
                                                {
                                                    inprocessList.investedAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(inprocessList.investedAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="opportunity-status">
                                                <span>{inprocessList.opportunityStatus}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="expected-roi-percentage">
                                                <span>{inprocessList.expectedRoi === "0%" ? "N/A" : inprocessList.expectedRoi == "" ? "N/A" : inprocessList.expectedRoi}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance expectedRoiAmount">
                                                {
                                                    inprocessList.expectedRoiAmount == "0" ?
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                        : inprocessList.expectedRoiAmount ?
                                                            <>
                                                                <span className="price">{formattedNumber(inprocessList.expectedRoiAmount)}</span>
                                                                <span className="currency">SAR</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span >N/A</span>
                                                            </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="funding-status">
                                                <span>{inprocessList.fundingStatus ? <>{inprocessList.fundingStatus}</> : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowTop(index)} >View</Button>

                                                <MyVerticallyCenteredModalTop show={aladetailsShowTop[index]} onHide={() => handleAladetailsCloseTop(index)} inprocess={inprocessList} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!opportunitesSlice?.loading && !inprocess?.length && !opportunitesSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>

                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Opportunities History</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records vertical-scroll active-loans-scroll">
                    <Table responsive style={{ width: "1680px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Opportunity ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Created on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expiry</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Date</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Invested Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "100px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Status</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI %</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Refund Status</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {opportunitesSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!opportunitesSlice?.loading && opportunitesSlice?.error ? <div>Error: {opportunitesSlice?.error}</div> : null}

                            {!opportunitesSlice?.loading && opportunityHistory?.length ?
                                (opportunityHistory.map((opportunityHistoryList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="opportunity-id">
                                                <span>{opportunityHistoryList.opportunityId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime createdOnDate">
                                                <span className="date">{opportunityHistoryList.createdOnDate ? formatDate(opportunityHistoryList.createdOnDate) : "N/A"}</span><br />
                                                <span className="time">{opportunityHistoryList.createdOnDate ? formatTime(opportunityHistoryList.createdOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime expiryDate">
                                                <span className="date">{opportunityHistoryList.expiryDate ? formatDate(opportunityHistoryList.expiryDate) : "N/A"}</span><br />
                                                <span className="time">{opportunityHistoryList.expiryDate ? formatTime(opportunityHistoryList.expiryDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime investmentDate">
                                                <span className="date">{opportunityHistoryList.investmentDate ? formatDate(opportunityHistoryList.investmentDate) : "N/A"}</span><br />
                                                <span className="time">{opportunityHistoryList.investmentDate ? formatTime(opportunityHistoryList.investmentDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="walletBalance">
                                                {
                                                    opportunityHistoryList.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(opportunityHistoryList.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance">
                                                {
                                                    opportunityHistoryList.investedAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(opportunityHistoryList.investedAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <div className="status">
                                                <span>{opportunityHistoryList.opportunityStatus}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="expected-roi-percentage">
                                                <span>{opportunityHistoryList.expectedRoi === "0%" ? "N/A" : opportunityHistoryList.expectedRoi == "" ? "N/A" : opportunityHistoryList.expectedRoi}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="walletBalance expected-roi">
                                                {/* {opportunityHistoryList.expectedRoiAmount} */}
                                                {
                                                    opportunityHistoryList.expectedRoiAmount == "0" ?
                                                        <>
                                                            <span >N/A</span>
                                                        </>
                                                        : opportunityHistoryList.expectedRoiAmount === "" ?
                                                            <>
                                                                <span >N/A</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span className="price">{formattedNumber(opportunityHistoryList.expectedRoiAmount)}</span>
                                                                <span className="currency">SAR</span>
                                                            </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="refund-status">
                                                <span>{opportunityHistoryList.fundingStatus ? <>{opportunityHistoryList.fundingStatus} Funded</> : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowBottom(index)} >View</Button>

                                                <MyVerticallyCenteredModalBottom show={aladetailsShowBottom[index]} onHide={() => handleAladetailsCloseBottom(index)} opportunityHistory={opportunityHistoryList} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!opportunitesSlice?.loading && !opportunityHistory?.length && !opportunitesSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModalTop(props) {
    // console.log("Top Props", props);
    const { inprocess } = props;
    // console.log("Top loan Applications", loanApplication);

    const modelDetails = () => {
        // console.log("active");

        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>In Progress Opportunity</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Opportunity ID</h4>
                                        <h5>{inprocess.opportunityId}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Funding Status</h4>
                                        <h5>{inprocess.fundingStatus ? inprocess.fundingStatus : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI %</h4>
                                        <h5>{inprocess.expectedRoi ? inprocess.expectedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Created on</h4>
                                        <div className="dateTime">
                                            <h5>{inprocess.createdOnDate ? formatDate(inprocess.createdOnDate) : "N/A"}</h5>
                                            <span className="time">{inprocess.createdOnDate ? formatTime(inprocess.createdOnDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        {
                                            inprocess.loanAmount ?
                                                <><h5>SAR {formattedNumber(inprocess.loanAmount)}</h5></>
                                                :
                                                <><span>N/A</span></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI</h4>
                                        {
                                            inprocess.expectedRoiAmount ?
                                                <><h5>SAR {formattedNumber(inprocess.expectedRoiAmount)}</h5></>
                                                :
                                                <><span>N/A</span></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expiry</h4>
                                        <div className="dateTime">
                                            <h5>{inprocess.expiryDate ? formatDate(inprocess.expiryDate) : "N/A"}</h5>
                                            <span className="time">{inprocess.expiryDate ? formatTime(inprocess.expiryDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Invested Amount</h4>
                                        {
                                            inprocess.investedAmount ?
                                                <><h5>SAR {formattedNumber(inprocess.investedAmount)}</h5></>
                                                :
                                                <><span>N/A</span></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2 pe-0">
                                    <div className="popupDv">
                                        <h4>Investment Transaction ID</h4>
                                        <h5>{inprocess.investmentTransactionId ? inprocess.investmentTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Opportunity Status</h4>
                                        <h5>{inprocess.opportunityStatus ? inprocess.opportunityStatus : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Date</h4>
                                        <div className="dateTime">
                                            <h5>{inprocess.investmentDate ? formatDate(inprocess.investmentDate) : "N/A"}</h5>
                                            <span className="time">{inprocess.investmentDate ? formatTime(inprocess.investmentDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )

    }
    return (
        modelDetails()
    )
}

function MyVerticallyCenteredModalBottom(props) {
    // console.log("Bottom Props", props);

    const { opportunityHistory } = props;

    const modelDetails = () => {
        // console.log("history");
        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm oppHistory" >
                    <Modal.Header closeButton>
                        <Modal.Title>Opportunity History</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Opportunity ID</h4>
                                        <h5>{opportunityHistory.opportunityId}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Funding Status</h4>
                                        <h5>{opportunityHistory.fundingStatus ? <>{opportunityHistory.fundingStatus} Funded</> : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI %</h4>
                                        <h5>{opportunityHistory.expectedRoi ? opportunityHistory.expectedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Refund Date</h4>
                                        <div className="dateTime refundDate">
                                            <h5>{opportunityHistory.refundDate ? formatDate(opportunityHistory.refundDate) : "N/A"}</h5>
                                            <span className="time">{opportunityHistory.refundDate ? formatTime(opportunityHistory.refundDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Created on</h4>
                                        <div className="dateTime">
                                            <h5>{opportunityHistory.createdOnDate ? formatDate(opportunityHistory.createdOnDate) : "N/A"}</h5>
                                            <span className="time">{opportunityHistory.createdOnDate ? formatTime(opportunityHistory.createdOnDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        {
                                            opportunityHistory.loanAmount ?
                                                <><h5>SAR {formattedNumber(opportunityHistory.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI</h4>
                                        {
                                            opportunityHistory.expectedRoiAmount ?
                                                <><h5>SAR {formattedNumber(opportunityHistory.expectedRoiAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Refunded Amount</h4>
                                        {
                                            opportunityHistory.refundAmount ?
                                                <><h5>SAR {formattedNumber(opportunityHistory.refundAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expiry</h4>
                                        <div className="dateTime">
                                            <h5>{opportunityHistory.expiryDate ? formatDate(opportunityHistory.expiryDate) : "N/A"}</h5>
                                            <span className="time">{opportunityHistory.expiryDate ? formatTime(opportunityHistory.expiryDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Invested Amount</h4>
                                        {
                                            opportunityHistory.investedAmount ?
                                                <><h5>SAR {formattedNumber(opportunityHistory.investedAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Transaction ID</h4>
                                        <h5>{opportunityHistory.investmentTransactionId ? opportunityHistory.investmentTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Refunded Transaction ID</h4>
                                        <h5>{opportunityHistory.refundTransactionId ? opportunityHistory.refundTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Opportunity Status</h4>
                                        <h5>{opportunityHistory.opportunityStatus ? opportunityHistory.opportunityStatus : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Date</h4>
                                        <div className="dateTime">
                                            <h5>{opportunityHistory.investmentDate ? formatDate(opportunityHistory.investmentDate) : "N/A"}</h5>
                                            <span className="time">{opportunityHistory.investmentDate ? formatTime(opportunityHistory.investmentDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Refunded Status</h4>
                                        <h5>{opportunityHistory.refundStatus ? opportunityHistory.refundStatus : "N/A"}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return (
        modelDetails()
    )
}

export default OPPORTUNITIES
