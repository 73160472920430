import { ColorRing } from 'react-loader-spinner';
const LoaderSpinner = () => {
    return (
        <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperclassName="color-ring-wrapper"
            colors={["#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089"]}
        />
    )
}

export default LoaderSpinner