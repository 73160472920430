import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

import { useDispatch, useSelector } from "react-redux";
import { showInfo, getInfo } from "../../../features/user/infoSlice";

import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { api, postAPI } from "../../../api/apiCallMethods";
import { jwtDecode } from "jwt-decode";

const INFO = () => {
    const [modalShow, setModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);

    const [reason, setReason] = useState("");
    const [comments, setComments] = useState("");

    const openConfirmModal = () => {
        setConfirmModalShow(true); // Open the confirmation modal
    };

    const handleBan = async () => {
       
        const reqConfig = {
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_BAN,
         data:{
                banReason: reason,
                banComment: comments,
                id
            }
        };
       await api(reqConfig);

        // await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_BAN, {
        //     banReason: reason,
        //     banComment: comments,
        //     id
        // });

        setModalShow(false); // Close the first modal
        setConfirmModalShow(false); // Close the confirmation modal
        setReason("")
        setComments("");
        dispatch(showInfo(params));
    };

    const handleCancel = () => {
        setConfirmModalShow(false); // Close only the confirmation modal
    };

    const [id, setId] = useState(useParams().id);
    const dispatch = useDispatch();
    const infoSlice = useSelector(getInfo)
    const info = infoSlice?.info;
    // console.log("This is the info slice (Lenders),=============", infoSlice);

    // console.log("This is the info (lENDERS),=============", info);

    const params = {
        id
    }

    useEffect(() => {
        (async () => {
            dispatch(showInfo(params));
        })()
    }, []);

    // User Roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");
    
    //     setuseRoleId(storedUserId);
    
    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Find the object with the specified role ID
    //         const filteredRole = parsedUserData.find(role => role.roleId === useRoleId);
    
    //         if (filteredRole) {
    //             // If a role with the specified ID is found, set the state with that role object
    //             setuseRoleData(filteredRole);
    //         } else {
    //             // If no role with the specified ID is found
    //             console.log("No role data found for the specified role ID.");
    //         }
    //     }
    // }, [useRoleId]);


    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;
        
        setuseRoleId(decoded.roleId);
        
        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            // //console.log("parsedUserData======", parsedUserData)
        
            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    return (
        <>
            <div className="borrowerInfoBox">
                <div className="borrowerStatusBar">
                    <ul>
                        <li>
                            <div className="label">Account Status</div>
                            <div className="value">
                                <span className="dot"></span>
                                <span className="title">{info?.accountStatus ? info?.accountStatus : "N/A"}</span>
                            </div>
                        </li>

                        <li>
                            <div className="label">Wallet Status</div>
                            <div className="value">
                                <span className="dot"></span>
                                <span className="title">{info?.walletStatus ? info?.walletStatus : "N/A"}</span>
                            </div>
                        </li>

                        {/* <li>
                            <div className="label">Risk Score</div>
                            <div className="value">
                                <span className="dot"></span>
                                <span className="title">Low Risk</span>
                            </div>
                        </li> */}

                        <li>
                            <div className="label">Sanctioned</div>
                            <div className="value green">
                                <span className="title">{info?.sanctioned ? info?.sanctioned : "N/A"}</span>
                            </div>
                        </li>

                        <li>
                            <div className="label">PEP</div>
                            <div className="value green">
                                <span className="title">{info?.pep ? info?.pep : "N/A"}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="borrowerInfo">
                    <Row>
                        <Col lg="11">
                            <Row className="row-cols-lg-5">
                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>Full Name</h5>
                                        <h4>{info?.fullName ? info?.fullName : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>Sanction Screening Date</h5>
                                        <h4>
                                            <span className="date">{info?.sanctionScreeningDate ? formatDate(info?.sanctionScreeningDate) : "N/A"}</span>
                                            <span className="time">{info?.sanctionScreeningDate ? formatTime(info?.sanctionScreeningDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Total Investments</h5>
                                        <h4>{info?.totalInvestments ? info?.totalInvestments : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Total Invested</h5>
                                        {
                                            info?.totalInvested
                                                ? <h4>SAR {formattedNumber(info?.totalInvested)}</h4>
                                                : <h4>N/A</h4>
                                        }

                                    </div>
                                </Col>

                                <Col lg="2"></Col>

                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>National ID</h5>
                                        <h4>{info?.nationalId ? info?.nationalId : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>Last Login</h5>
                                        <h4>
                                            <span className="date">{info?.lastLogin ? formatDate(info?.lastLogin) : "N/A"}</span>
                                            <span className="time">{info?.lastLogin ? formatTime(info?.lastLogin) : ""}</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Active Investments</h5>
                                        <h4>{info?.activeInvestments ? info?.activeInvestments : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Total ROI</h5>
                                        {
                                            info?.totalRoi
                                                ? <h4>SAR {formattedNumber(info?.totalRoi)}</h4>
                                                : <h4>N/A</h4>
                                        }

                                    </div>
                                </Col>

                                <Col lg="2"></Col>

                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>Account Created</h5>
                                        <h4>
                                            <span className="date">11/10/2023</span>
                                            <span className="time">17:55:33</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <div className="infoBox">
                                        <h5>Wallet Balance</h5>
                                        {
                                            info?.walletBalance
                                                ? <h4>SAR {formattedNumber(info?.walletBalance)}</h4>
                                                : <h4>N/A</h4>
                                        }
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Active Opportunities </h5>
                                        <h4>{info?.activeOpportunities ? info?.activeOpportunities : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                </Col>
                            </Row>
                        </Col>
                        
                        {
                            useRoleData["appUserManagmentBan"] === "1" ? 
                                <>
                                    <Col lg="1">
                                        <div className="btnBox">
                                            <Button variant="primary" onClick={() => setModalShow(true)}>
                                                {/* Ban Account */}
                                                {info?.accountStatus.toLowerCase() !== "banned" ? "Ban Account" : "Unban Account"}
                                            </Button>
                                            <MyVerticallyCenteredModal 
                                                show={modalShow} 
                                                onHide={() => setModalShow(false)} 
                                                onConfirm={openConfirmModal} 
                                                userId={info?.nationalId}
                                                fullName={info?.fullName}
                                                status={info?.accountStatus}
                                                reason={reason}
                                                setReason={setReason}
                                                comments={comments}
                                                setComments={setComments}
                                            />
                                            <MyConfirmModal 
                                                show={confirmModalShow} 
                                                onHide={() => setConfirmModalShow(false)} 
                                                onConfirm={handleBan} 
                                                onCancel={handleCancel} 
                                                status={info?.accountStatus}
                                            />
                                        </div>
                                    </Col>
                                </>
                            : null
                        }
                    </Row>
                </div >
            </div >
        </>
    )
}

function MyVerticallyCenteredModal(props) {
    // const [confirmModalShow, setConfirmModalShow] = React.useState(false);

    const { userId, fullName, status, reason, setReason, comments, setComments } = props;

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="banForm">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to {status ? (status.toLowerCase() !== "banned" ? "ban" : "unban") : null} this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row>
                        <Col lg="3" className="mb-4">
                            <div className="popupDv">
                                <h4>User ID</h4>
                                {/* <h5>0152150111</h5> */}
                                <h5>{userId ? userId : "N/A"}</h5>
                            </div>
                        </Col>
                        <Col lg="9" className="mb-4">
                            <div className="popupDv">
                                <h4>Full Name</h4>
                                {/* <h5>Ahmed Fahad Mohammad Saud</h5> */}
                                <h5>{fullName ? fullName : "N/A"}</h5>
                            </div>
                        </Col>

                        <Col lg="5" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Select aria-label="Default select example" value={reason} onChange={(e) => setReason(e.target.value)}>
                                        <option>Other</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your comment"  value={comments} onChange={(e) => setComments(e.target.value)} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Button className="btn-white me-3" onClick={props.onConfirm}>
                                {status ? (status.toLowerCase() !== "banned" ? "Ban" : "Unban") : null}
                            </Button>

                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>

    );
}

function MyConfirmModal(props) {
    const { status } = props;
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm">
            <Modal.Header className="d-flex justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to {status ? (status.toLowerCase() !== "banned" ? "ban" : "unban") : null} this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmBtns d-flex justify-content-center">
                    <Button className="btn-white me-3" onClick={props.onConfirm}>Yes</Button>
                    <Button onClick={props.onHide}>Cancel</Button>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}

export default INFO