import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_DETAILS;
// const URL = "http://localhost:8000/user/admin/wallet/details";

export const showAllWalletInfo = createAsyncThunk(
    "allWallet/info",
    async (params) => {
       const data={
        userId:params.userId
       };
       delete params.userId;
        const reqConfig = {
            method: "POST",
            url: URL,
            params,
            data
        };
     const response = await api(reqConfig);
        // console.log("All Wallets Details Slice - URL & Params:", URL, params);
        // const response = await postAPI(URL, params)

        // const allWalletsPayload = await response.data.data.;
        // console.log("All Wallets Details Slice - response:", response);
        const allWalletInfoPayload = await response.data.data;
        // console.log("All Wallets Details Slice - payload:", allWalletInfoPayload);
        return allWalletInfoPayload;
    }
);

const allWalletInfoSlice = createSlice({
    name: "allWalletInfo",
    initialState: {
        loading: false,
        info: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showAllWalletInfo.pending, (state) => {
            state.loading = true;
            state.info = null;
            state.error = null;
        });
        builder.addCase(showAllWalletInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.info = action.payload;
            state.error = null;
        });
        builder.addCase(showAllWalletInfo.rejected, (state, action) => {
            state.loading = false;
            state.info = null;
            state.error = action.error.message;
        });
    },
});

export const getAllWalletInfo = (state) => state.allWalletInfo;
export default allWalletInfoSlice.reducer;

