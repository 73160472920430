import axiosInstance from './middleware/axiosInterceptors';

// const applicationName = "TheLendingHub";
// const applicationVersion = "1.0";
// const applicationNameDev = "TheLendingHubDev";
// const applicationVersionDev = "1.0";
// const apiid = "LendingHubData";
// const apikey = "ajksdhfwuerjasdhqu2312ead";

const applicationName = process.env.REACT_APP_APPLICATION_NAME;
const applicationVersion = process.env.REACT_APP_APPLICATION_VERSION;
const applicationNameDev = process.env.REACT_APP_APPLICATION_NAME_DEV;
const applicationVersionDev = process.env.REACT_APP_APPLICATION_VERSION_DEV;
const apiid = process.env.REACT_APP_APIID;
const apikey = process.env.REACT_APP_APIKEY;


export const getAPI = async (url, params) => {
    try {
        console.log(applicationName);
        const response = await axiosInstance.get(
            url,
            {
                params,
                headers: {
                    apiid,
                    apikey
                },
                data: {
                    application: applicationName,
                    applicationVersion: applicationVersion
                }
            })
        console.log(response);
        return response

    } catch (error) {
        return error.message
    }

}

export const postAPI = async (url, data = {}) => {
    try {
        data.application = applicationName;
        data.applicationVersion = applicationVersion;
        const response = await axiosInstance.post(url, data, {
            headers: {
                apiid,
                apikey
            }
        })
        // console.log(response);
        return response
    } catch (error) {
        return error.message
    }

}

export const patchAPI = async (url, data = {}) => {
    try {
        console.log(url, data)
        const response = await axiosInstance.patch(url, data, {
            headers: {
                apiid,
                apikey
            }
        })
        return response;
    } catch (error) {
        return error.message;
    }
}


export const api = async (reqConfig) => {
    const hasHeader = reqConfig.hasOwnProperty("headers");
    const hasData = reqConfig.hasOwnProperty("data");

    if (hasHeader) {
        reqConfig.headers = {
            ...reqConfig.headers,
            apiid,
            apikey
        }
    } else {
        reqConfig["headers"] = {
            apiid,
            apikey
        }
    }

    if (hasData) {
        reqConfig["data"] = {
            ...reqConfig.data,
            application: applicationNameDev,
            applicationVersion: applicationVersionDev
        }
    } else {
        reqConfig.data = {
            application: applicationNameDev,
            applicationVersion: applicationVersionDev
        }
    }
    try {
        const response = await axiosInstance(reqConfig)
        return response
    } catch (error) {
        return error.message
    }

}