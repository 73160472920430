import React from 'react'
import "../../assets/css/404 Not Found/404.css"
const NotFound = () => {
    return (
        <main>
            <section>
                <span>404</span>
                <p>The requested path could not be found</p>
            </section>
        </main>
    )
}

export default NotFound