import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_CANCELLED_APPLICATIONS_LISTING;

export const listCancelledApplications = createAsyncThunk(
    "cancelledApplication/listCancelledApplication",
    async (data) => {

        // console.log("List Cancelled Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const cancelledApplicationsPayload = await response.data.data;
        // console.log("List Cancelled Applications List Slice - response:", response);
        // const cancelledApplicationsPayload = await response.data;
        // console.log("List Cancelled Applications List Slice - payload:", cancelledApplicationsPayload);
        return cancelledApplicationsPayload;
    }
);

const listCancelledApplicationsSlice = createSlice({
    name: "listCancelledApplications",
    initialState: {
        loading: false,
        cancelledApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listCancelledApplications.pending, (state) => {
            state.loading = true;
            state.cancelledApplications = null;
            state.error = null;
        });
        builder.addCase(listCancelledApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.cancelledApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listCancelledApplications.rejected, (state, action) => {
            state.loading = false;
            state.cancelledApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getCancelledApplicationsList = (state) => state.cancelledApplicationsList;
export default listCancelledApplicationsSlice.reducer;
