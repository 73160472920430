import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

// Components
import HEADER from "../../../component/header/header";
import BREADCRUMB from "../../../component/breadcrumb/breadcrumb";
import INFO from "../../../component/loan-management/active-loans/info/info";
import SIDEBAR from "../../../component/sidebar/sidebar";


import { useDispatch, useSelector } from "react-redux";
import { showActiveLoanDetails, getActiveLoanDetails } from "../../../features/loan-management/activeLoanDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const BORROWERS = () => {


    const [id, setId] = useState(useParams().id)
    const dispatch = useDispatch();
    const activeLoanDetailsSlice = useSelector(getActiveLoanDetails)
    const activeLoanDetails = activeLoanDetailsSlice?.activeLoanDetails;
    const installmentDetails = activeLoanDetails?.installmentDetails;
    const fundingDetails = activeLoanDetails?.fundingDetails;

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showActiveLoanDetails(params));
        })()
    }, []);
    // console.log("This is the id", id);

    // console.log("Here are the active Loan Details Slic....", activeLoanDetailsSlice);

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />

                    <BREADCRUMB />

                    <div className="borrowers">
                        <INFO />

                        <div className="content accountSummary mb-3 pt-0 px-0 mb-5 mx-0">
                            <div className="optionsBox">
                                <Row>
                                    <Col lg="6">
                                        <div className="titleDv">
                                            <h3 className="mb-3">Installment Details</h3>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="pagination">
                                            <span className="reloadList" style={{ cursor: "pointer" }} onClick={() => dispatch(showActiveLoanDetails(params))}>
                                                <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                                    <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                                    <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="records vertical-scroll active-loans-scroll">
                                <Table responsive className="mb-0" style={{ width: "1420px" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '170px', whiteSpace: 'nowrap' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Installment Number</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '160px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Amount</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '170px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Status</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '160px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Due Date</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '150px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Paid Date</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '150px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Payment<br />Delayed</span>
                                                </div>
                                            </th>
                                            <th >
                                                <div className="labelIco">
                                                    <span className="headTitle">Payment<br />Ref. No.</span>
                                                </div>
                                            </th>
                                            {/* <th style={{ width: '130px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Penalty<br />Amount</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '130px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Penalty <br />Applied on</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '130px' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Penalty <br />Paid on</span>
                                                </div>
                                            </th> 
                                            <th >
                                                <div className="labelIco">
                                                    <span className="headTitle">Penalty <br />Payment Ref. No.</span>
                                                </div>
                                            </th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {activeLoanDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                        {!activeLoanDetailsSlice?.loading && activeLoanDetailsSlice?.error ? <div>Error: {activeLoanDetailsSlice?.error}</div> : null}
                                        {!activeLoanDetailsSlice?.loading && installmentDetails?.length ? (

                                            installmentDetails.map(detail =>
                                            (

                                                <tr>
                                                    <td align="center" style={{ width: '170px' }}>
                                                        <div className="Installment-number">
                                                            <span>{detail?.installmentNo}</span>
                                                            {/* <span>1</span> */}
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '160px' }}>
                                                        <div className="walletBalance">
                                                            {
                                                                detail?.amount ?
                                                                    <>
                                                                        <span className="price">{formattedNumber(detail?.amount)}</span>
                                                                        <span className="currency">SAR</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="price">-</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '170px' }}>
                                                        <div className="status">
                                                            <span>{detail?.status ? detail?.status : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '160px' }}>
                                                        <div className="dateTime">
                                                            <span className="date">{detail?.dueDate ? formatDate(detail?.dueDate) : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="dateTime">
                                                            <span className="date">{detail?.paidDate ? formatDate(detail?.paidDate) : "N/A"}</span><br />
                                                            <span className="time">{detail?.paidDate ? formatTime(detail?.paidDate) : ""}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="Payment-delayed">
                                                            <span>{detail?.paymentDelayed ? <>{detail?.paymentDelayed} Days</> : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="Paymen-ref">
                                                            <span>{detail?.paymentRefNo ? detail?.paymentRefNo : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    {/* <td style={{ width: '130px' }}>
                                                        <div className="walletBalance">
                                                            {
                                                                detail?.penalityAmount ?
                                                                    <>
                                                                        <span className="price">{formattedNumber(detail?.penalityAmount)}</span>
                                                                        <span className="currency">SAR</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="price">N/A</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '130px' }}>
                                                        <div className="Penalty-applied">
                                                            <div className="dateTime">

                                                                <span className="date">{detail?.penalityAppliedOn ? formatDate(detail?.penalityAppliedOn) : "N/A"}</span><br />
                                                                <span className="time">{detail?.penalityAppliedOn ? formatTime(detail?.penalityAppliedOn) : ""}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '130px' }}>
                                                        <div className="Penalty-paid">
                                                            <div className="dateTime">
                                                                <span className="date">{detail?.penalityPaidOn ? formatDate(detail?.penalityPaidOn) : "N/A"}</span><br />
                                                                <span className="time">{detail?.penalityPaidOn ? formatTime(detail?.penalityPaidOn) : ""}</span>
                                                            </div>
                                                        </div>
                                                    </td> 
                                                    <td >
                                                        <div className="Penalty-payment-ref">
                                                            <span>{detail?.penalityPaymentRefNo ? detail?.penalityPaymentRefNo : "N/A"}</span>
                                                        </div>
                                                    </td>*/}
                                                </tr>
                                            )
                                            )
                                        )
                                            : null
                                        }
                                    </tbody>
                                </Table>

                                {!activeLoanDetailsSlice?.loading && !installmentDetails?.length && !installmentDetails?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                            </div>
                        </div>

                        <hr className="mb-4" />

                        <div className="content accountSummary mb-3 pt-0 px-0 mb-5 mx-0">
                            <div className="optionsBox">
                                <Row>
                                    <Col lg="6">
                                        <div className="titleDv">
                                            <h3 className="mb-3">Funding Details</h3>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="pagination">
                                            <span className="reloadList" style={{ cursor: "pointer" }} onClick={() => dispatch(showActiveLoanDetails(params))}>
                                                <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                                    <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                                    <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="records vertical-scroll active-loans-scroll">
                                <Table responsive className="mb-0" style={{ width: "1600px" }}>
                                    <thead className="undefined sticky-header">
                                        <tr>
                                            <th style={{ width: '70px', whiteSpace: 'nowrap' }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Sr. No.</span>
                                                </div>
                                            </th>
                                            <th style={{ width: '120px' }}>
                                                <div className="labelIco"><span className="headTitle">Investor ID</span></div>
                                            </th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Investor Name</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Investment<br />Amount</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Investment<br />Percentage</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Investment<br />Date</span></div></th>
                                            <th style={{ width: '160px' }}><div className="labelIco"><span className="headTitle">Payment<br />Ref. No.</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">ROI<br />Percentage</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Expected <br />ROI Amount</span></div></th>
                                            <th style={{ width: '150px' }}><div className="labelIco"><span className="headTitle">Expected <br />Return Amount</span></div></th>
                                            <th ><div className="labelIco"><span className="headTitle">Expected <br />Return Date</span></div></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {activeLoanDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                        {!activeLoanDetailsSlice?.loading && activeLoanDetailsSlice?.error ? <div>Error: {activeLoanDetailsSlice?.error}</div> : null}
                                        {!activeLoanDetailsSlice?.loading && fundingDetails?.length ? (

                                            fundingDetails.map(detail =>
                                            (

                                                <tr>
                                                    <td align="center" style={{ width: '70px' }}>
                                                        <div className="Installment-number label-val">
                                                            <span>{detail?.sNo ? detail?.sNo : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '120px' }}>
                                                        <div className="investor-id label-val">
                                                            <span>{detail?.investorId ? detail?.investorId : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="investor-name label-val">
                                                            <span>{detail?.investorName ? detail?.investorName : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="walletBalance label-val">
                                                            {
                                                                formattedNumber(detail?.investmentAmount) ?
                                                                    <>
                                                                        <span className="price">{formattedNumber(detail?.investmentAmount)}</span>
                                                                        <span className="currency">SAR</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="price">N/A</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="investment-percentage label-val">
                                                            <span>{detail?.investmentPercentage ? detail?.investmentPercentage : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="dateTime label-val">
                                                            <span className="date">{detail?.investmentDate ? formatDate(detail?.investmentDate) : "N/A"}</span><br />
                                                            <span className="time">{detail?.investmentDate ? formatTime(detail?.investmentDate) : ""}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '160px' }}>
                                                        <div className="payment-ref-no label-val">
                                                            <span>{detail?.paymentRefNo ? detail?.paymentRefNo : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="roi-percentage label-val">
                                                            <span>{detail?.roi ? detail?.roi : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="walletBalance label-val">
                                                            {
                                                                formattedNumber(detail?.expectedRoiAmount) ?
                                                                    <>
                                                                        <span className="price">{formattedNumber(detail?.expectedRoiAmount)}</span>
                                                                        <span className="currency">SAR</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="price">N/A</span>
                                                                    </>
                                                            }
                                                        </div>

                                                    </td>
                                                    <td style={{ width: '150px' }}>
                                                        <div className="walletBalance label-val">
                                                            {
                                                                formattedNumber(detail?.expectedReturnAmount) ?
                                                                    <>
                                                                        <span className="price">{formattedNumber(detail?.expectedReturnAmount)}</span>
                                                                        <span className="currency">SAR</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="price">N/A</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="dateTime label-val">
                                                            <span className="date">{detail?.expectedReturnDate ? formatDate(detail?.expectedReturnDate) : "N/A"}</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                            )
                                        )
                                            : null
                                        }

                                    </tbody>
                                </Table>

                                {!activeLoanDetailsSlice?.loading && !fundingDetails?.length && !activeLoanDetailsSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BORROWERS