import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_APPLICATIONS_LISTING;

export const listActiveApplications = createAsyncThunk(
    "activeApplications/list",
    async (data) => {

        // console.log("List Active Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("List Active Applications List Slice - response:", response);
        const activeApplicationsPayload = await response.data.data;
        // const activeApplicationsPayload = await response.data;
        // console.log("List Active Applications List Slice - payload:", activeApplicationsPayload);
        return activeApplicationsPayload;
    }
);

const activeApplicationsListSlice = createSlice({
    name: "activeApplicationsList",
    initialState: {
        loading: false,
        activeApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listActiveApplications.pending, (state) => {
            state.loading = true;
            state.activeApplications = null;
            state.error = null;
        });
        builder.addCase(listActiveApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.activeApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listActiveApplications.rejected, (state, action) => {
            state.loading = false;
            state.activeApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getActiveApplicationsList = (state) => state.activeApplicationsList;
export default activeApplicationsListSlice.reducer;
