import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


// import { post } from "../utilities/api";
const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_ACCOUNT_DETAILS;

export const showAccountDetails = createAsyncThunk(
  "user/accountDetails",
  async (params) => {
    const reqConfig = {
      method: "POST",
      url: URL,
      params
  };

 const response = await api(reqConfig);
    // console.log("Account Details Slice - URL & Params:", URL, params);

    // const response = await getAPI(URL, params)
    // console.log("Account Details Slice - response:", response);

    const accountDetailsPayload = response.data.data

    // console.log("Account Details Slice - payload:", accountDetailsPayload);

    return accountDetailsPayload;
  }
);

const showAccountDetailsSlice = createSlice({
  name: "userAccountDetails",
  initialState: {
    loading: false,
    accountDetails: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showAccountDetails.pending, (state) => {
      state.loading = true;
      state.accountDetails = null;
      state.error = null;
    });
    builder.addCase(showAccountDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.accountDetails = action.payload;
      state.error = null;
    });
    builder.addCase(showAccountDetails.rejected, (state, action) => {
      state.loading = false;
      state.accountDetails = null;
      state.error = action.error.message;
      // console.log(action.error.message);
    });
  },
});
export const getAccountDetails = (state) => state.userAccountDetails;
export default showAccountDetailsSlice.reducer;
