import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_CANCELLED_APPLICATIONS_DETAILS;

export const showCancelledApplicationDetails = createAsyncThunk(
    "cancelledApplication/showCancelledApplicationDetails",
    async (data) => {

        // console.log("CancelledApplication Details Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("CancelledApplication Details Slice - response:", response);
        const cancelledApplicationDetailsPayload = await response.data.data;
        // const cancelledApplicationDetailsPayload = await response.data;
        // console.log("CancelledApplication Details Slice - payload:", cancelledApplicationDetailsPayload);

        return cancelledApplicationDetailsPayload;
    }
);

const showCancelledApplicationDetailsSlice = createSlice({
    name: "showCancelledApplicationDetailsSlice",
    initialState: {
        loading: false,
        cancelledApplicationDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showCancelledApplicationDetails.pending, (state) => {
            state.loading = true;
            state.cancelledApplicationDetails = null;
            state.error = null;
        });
        builder.addCase(showCancelledApplicationDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.cancelledApplicationDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showCancelledApplicationDetails.rejected, (state, action) => {
            state.loading = false;
            state.cancelledApplicationDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getCancelledApplicationDetails = (state) => state.cancelledApplicationsDetails;
export default showCancelledApplicationDetailsSlice.reducer;
