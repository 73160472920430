import React, { useState, useEffect } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

// Components
import SIDEBAR from "../../component/sidebar/sidebar";
import HEADER from "../../component/header/header";
import BREADCRUMB from "../../component/breadcrumb/breadcrumb";
import LENDERINFO from "../../component/lender/info/info";
import LENDERPROFILE from "../../component/lender/profile/profile";
import LENDEROTHERDETAILS from "../../component/lender/otherdetails/otherdetails";
import LENDERACCOUNTDETAILS from "../../component/lender/accountdetails/accountdetails";
// import LENDERLOAN from "../../component/lender/loan/loan";
import OPPORTUNITIES from "../../component/lender/opportunities/opportunities";
import LENDERINVESTMENT from "../../component/lender/investment/investment";
import LENDERTRANSACTIONHISTORY from '../../component/lender/transactionhistory/transactionhistory';
import LENDERLOGINHISTORY from "../../component/lender/loginhistory/loginhistory";
import MANUALNOTIFICATION from "../../component/lender/manualNotification/manualNotification";
import useAuth from "../../hooks/permission-hook";

import { jwtDecode } from "jwt-decode";

const BORROWERS = () => {

    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");

    //     setuseRoleId(storedUserId);

    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Find the object with the specified role ID
    //         const filteredRole = parsedUserData.find(role => role.roleId === storedUserId);

    //         if (filteredRole) {
    //             // If a role with the specified ID is found, set the state with that role object
    //             setuseRoleData(filteredRole);
    //         } else {
    //             // If no role with the specified ID is found
    //             console.log("No role data found for the specified role ID.");
    //         }
    //     }
    // }, [useRoleId]); 

    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            //console.log("parsedUserData======", parsedUserData)

            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    const [permissions, checkPermission]  = useAuth();

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />

                    <BREADCRUMB />

                    <div className="borrowers">
                        <LENDERINFO />

                        <div className="borrowerTabs">
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="">
                                <Tab eventKey="profile" title="Profile">

                                    {
                                         checkPermission({subject:"app user management", action:"view/update profiles"}) ?
                                            <>
                                                <LENDERPROFILE />
                                            </>
                                            :
                                            null
                                    }
                                </Tab>

                                <Tab eventKey="other-details" title="Other Details">
                                    <LENDEROTHERDETAILS />
                                </Tab>

                                <Tab eventKey="account-details" title="Account Details">
                                    <LENDERACCOUNTDETAILS />
                                </Tab>

                                <Tab eventKey="opportunities" title="Opportunities">
                                    <OPPORTUNITIES />
                                </Tab>

                                <Tab eventKey="investment" title="Investment">
                                    <LENDERINVESTMENT />
                                </Tab>

                                <Tab eventKey="transaction-history" title="Transaction History">
                                    <LENDERTRANSACTIONHISTORY />
                                </Tab>

                                <Tab eventKey="login-history" title="Login History">
                                    <LENDERLOGINHISTORY />
                                </Tab>
                                <Tab eventKey="manual-notification" title="Manual Notification">
                                    <MANUALNOTIFICATION />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BORROWERS