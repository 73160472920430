import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_PENDING_APPLICATION_APPROVALS_LISTING;

export const listPendingApplicationApprovals = createAsyncThunk(
    "pendingApplicationApproval/listpendingApplicationApprovals",
    async (data) => {

        // console.log("List Rejected Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = {
        // "action": "/admin/application/list",
        //     "code": 200,
        //         "status": true,
        //             "data": {
        //     "result": [
        //         {
        //             "applicationId": "36",
        //             "createdOn": "2024-03-08T06:50:01.000Z",
        //             "borrowerId": "1222222222",
        //             "expiryDate": "2024-04-08T06:50:01.000Z",
        //             "loanReason": "Travel & Entertainment",
        //             "duration": "2",
        //             "noOfInstallments": "2",
        //             "amount": "1000"
        //         }
        //     ],
        //         "page": "1",
        //             "perPage": "10",
        //                 "total": "1",
        //                     "totalPages": "1"
        // },
        // "message": "",
        //     "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiT21lciBBYmVkIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImlkIjoyNCwicm9sZUlkIjoxLCJ1c2VyVHlwZSI6ImFkbWluIiwiaWF0IjoxNzE2ODg5Mzg0LCJleHAiOjE3MTY4ODk2ODR9.PtKSh1KGJWRULwtiHbnQkdanVGbgepLRyr_EqBJTwOE"
        // }
        // const response = await getAPI('http://localhost:3004/get-data', params);


        const pendingApplicationApprovalsPayload = await response.data.data;
        // const pendingApplicationApprovalsPayload = response.data;


        // console.log("List Rejected Applications List Slice - response:", response);
        // const pendingApplicationApprovalsPayload = await response.data;
        // console.log("List Rejected Applications List Slice - payload:", pendingApplicationApprovalsPayload);
        return pendingApplicationApprovalsPayload;
    }
);

const listPendingApplicationApprovalsSlice = createSlice({
    name: "listPendingApplicationApprovals",
    initialState: {
        loading: false,
        pendingApplicationApprovals: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPendingApplicationApprovals.pending, (state) => {
            state.loading = true;
            state.pendingApplicationApprovals = null;
            state.error = null;
        });
        builder.addCase(listPendingApplicationApprovals.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingApplicationApprovals = action.payload;
            state.error = null;
        });
        builder.addCase(listPendingApplicationApprovals.rejected, (state, action) => {
            state.loading = false;
            state.pendingApplicationApprovals = null;
            state.error = action.error.message;
        });
    },
});

export const getPendingApplicationApprovalsList = (state) => state.pendingApplicationApprovalsList;
export default listPendingApplicationApprovalsSlice.reducer;
