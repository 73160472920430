import React from "react";
import "../../../assets/css/counter/counter.css";
// import { useState, useEffect } from "react";

const ACTIVEREQUESTLOANLISTCOUNTER = () => {

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Active Loans</h6>
                        <h1>3000</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ACTIVEREQUESTLOANLISTCOUNTER