import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_REJECTED_APPLICATIONS_DETAILS;

export const showRejectedApplicationDetails = createAsyncThunk(
    "rejectedApplication/showRejectedApplicationDetails",
    async (data) => {

        // console.log("Rejected Application Details Slice - URL & Params:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Rejected Application Details Slice - response:", response);
        const rejectedApplicationDetailsPayload = await response.data.data;
        // const rejectedApplicationDetailsPayload = await response.data;
        // console.log("Rejected Application Details Slice - payload:", rejectedApplicationDetailsPayload);

        return rejectedApplicationDetailsPayload;
    }
);

const showRejectedApplicationDetailsSlice = createSlice({
    name: "showRejectedApplicationDetailsSlice",
    initialState: {
        loading: false,
        rejectedApplicationDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showRejectedApplicationDetails.pending, (state) => {
            state.loading = true;
            state.rejectedApplicationDetails = null;
            state.error = null;
        });
        builder.addCase(showRejectedApplicationDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.rejectedApplicationDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showRejectedApplicationDetails.rejected, (state, action) => {
            state.loading = false;
            state.rejectedApplicationDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getRejectedApplicationDetails = (state) => state.rejectedApplicationsDetails;
export default showRejectedApplicationDetailsSlice.reducer;
