import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { listInvestments, getInvestments } from "../../../features/lender/investmentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const INVESTEMNT = () => {

    const [aladetailsShowTop, setAladetailsShowTop] = useState(Array(10).fill(false));
    const [aladetailsShowBottom, setAladetailsShowBottom] = useState(Array(10).fill(false));

    const [lenderId, setLenderId] = useState(useParams().id)
    const [pageFlag, setPageFlag] = useState(1)
    const [limit, setLimit] = useState(undefined)
    const [page, setPage] = useState(undefined)
    const [sortType, setSortType] = useState("desc")
    const [orderBy, setOrderBy] = useState("loanId")
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const params = {
        lenderId,
        pageFlag,
    }
    const dispatch = useDispatch();
    const investmentSlice = useSelector(getInvestments)
    const inprogress = investmentSlice?.investments?.inProgress;
    const investmentHistory = investmentSlice?.investments?.investmentHistory;

    useEffect(() => {
        (async () => {
            await dispatch(listInvestments(params));
        })()
    }, []
    );


    const handleAladetailsShowTop = (index) => {
        // setAladetailsShow(true);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = true;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsCloseTop = (index) => {
        // setAladetailsShow(false);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = false;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsShowBottom = (index) => {
        // setAladetailsShow(true);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = true;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const handleAladetailsCloseBottom = (index) => {
        // setAladetailsShow(false);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = false;
        setAladetailsShowBottom(updateModalsBottom)
    }


    const reload = () => {
        dispatch(listInvestments(params));
    }

    return (
        <>
            <div className="content">
                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">In Progress Investments</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp mb-5">
                    <Table responsive style={{ width: "1540px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Status</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Date</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI  %</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected Payout Date</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {investmentSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!investmentSlice?.loading && investmentSlice?.error ? <div>Error: {investmentSlice?.error}</div> : null}

                            {!investmentSlice?.loading && inprogress?.length ?
                                (inprogress.map((inprogressList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="loan-id">
                                                <span>{inprogressList?.loanId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="status">
                                                <span>{inprogressList?.status ? inprogressList?.status : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{inprogressList?.investmentDate ? formatDate(inprogressList?.investmentDate) : "N/A"}</span><br />
                                                <span className="time">{inprogressList?.investmentDate ? formatTime(inprogressList?.investmentDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance">
                                                {
                                                    inprogressList?.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(inprogressList?.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="walletBalance">
                                                {
                                                    inprogressList?.investedAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(inprogressList?.investedAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="duration">
                                                {
                                                    inprogressList?.duration ?
                                                        <>
                                                            <span>{inprogressList?.duration} Month</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="expected-roi-percentage">
                                                <span>{inprogressList?.expectedRoi ? inprogressList?.expectedRoi : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="expected-roi">
                                                {
                                                    inprogressList?.expectedRoiAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(inprogressList?.expectedRoiAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="dateTime">
                                                <span className="date">{inprogressList?.expectedRoiPayoutDate ? formatDate(inprogressList?.expectedRoiPayoutDate) : "N/A"}</span><br />
                                                <span className="time">{inprogressList?.expectedRoiPayoutDate ? formatTime(inprogressList?.expectedRoiPayoutDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowTop(index)} >View</Button>

                                                <MyVerticallyCenteredModalTop show={aladetailsShowTop[index]} onHide={() => handleAladetailsCloseTop(index)} inprocess={inprogressList} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!investmentSlice?.loading && !inprogress?.length && !investmentSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>

                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Investment History</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records vertical-scroll active-loans-scroll">
                    <Table responsive style={{ width: "1890px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Opportunity ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Status</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Date</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Investment Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI %</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected ROI</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expected Payout Date</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Payout</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {investmentSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!investmentSlice?.loading && investmentSlice?.error ? <div>Error: {investmentSlice?.error}</div> : null}

                            {!investmentSlice?.loading && investmentHistory?.length ?
                                (investmentHistory.map((investmentHistoryList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="opportunity-id">
                                                <span>{investmentHistoryList?.loanId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="status">
                                                <span>{investmentHistoryList?.status ? investmentHistoryList?.status : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{investmentHistoryList?.investmentDate ? formatDate(investmentHistoryList?.investmentDate) : "N/A"}</span><br />
                                                <span className="time">{investmentHistoryList?.investmentDate ? formatTime(investmentHistoryList?.investmentDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance">
                                                {
                                                    investmentHistoryList?.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(investmentHistoryList?.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="walletBalance">
                                                {
                                                    investmentHistoryList?.investedAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(investmentHistoryList?.investedAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="duration">
                                                {
                                                    investmentHistoryList?.duration ?
                                                        <>
                                                            <span>{investmentHistoryList?.duration} Month</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="expectedRoi">
                                                <span>{investmentHistoryList?.expectedRoi ? investmentHistoryList?.expectedRoi : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="expectedRoiAmount">
                                                {
                                                    investmentHistoryList?.f
                                                        ?
                                                        <>
                                                            <span className="price">{formattedNumber(investmentHistoryList?.expectedRoiAmount)}</span>
                                                            <span className="currency"> SAR</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>N/A</span>
                                                        </>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="dateTime">
                                                <span className="date">{investmentHistoryList?.expectedRoiPayoutDate ? formatDate(investmentHistoryList.expectedRoiPayoutDate) : "N/A"}</span><br />
                                                <span className="time">{investmentHistoryList?.expectedRoiPayoutDate ? formatTime(investmentHistoryList.expectedRoiPayoutDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="totalPayoutAmount">
                                                {investmentHistoryList?.totalPayoutAmount
                                                    ?
                                                    <>
                                                        <span>SAR {formattedNumber(investmentHistoryList?.totalPayoutAmount)}</span>
                                                    </>
                                                    : <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowBottom(index)} >View</Button>

                                                <MyVerticallyCenteredModalBottom show={aladetailsShowBottom[index]} onHide={() => handleAladetailsCloseBottom(index)} historyList={investmentHistoryList} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!investmentSlice?.loading && !investmentHistory?.length && !investmentSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModalTop(props) {
    // console.log("Top Props", props);
    const { inprocess } = props;
    // console.log("Top loan Applications", loanApplication);

    const modelDetails = () => {
        // console.log("active");

        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>In Progress Investment</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Application ID</h4>
                                        <h5>{inprocess?.loanId}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Invested Amount</h4>
                                        {
                                            inprocess?.investedAmount ?
                                                <h5>SAR {formattedNumber(inprocess?.investedAmount)}</h5>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI </h4>
                                        <h5>{inprocess?.expectedRoi ? inprocess?.expectedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Status</h4>
                                        <h5>{inprocess?.status ? inprocess?.status : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2 pe-0">
                                    <div className="popupDv">
                                        <h4>Investment Transaction ID</h4>
                                        <h5>{inprocess?.investmentTransactionId ? inprocess?.investmentTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected Payout Date</h4>
                                        <div className="dateTime">
                                            <h5>{inprocess?.expectedRoiPayoutDate ? formatDate(inprocess?.expectedRoiPayoutDate) : "N/A"}</h5>
                                            <span className="time">{inprocess?.expectedRoiPayoutDate ? formatTime(inprocess?.expectedRoiPayoutDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Date</h4>
                                        <div className="dateTime">
                                            <h5>{inprocess?.investmentDate ? formatDate(inprocess?.investmentDate) : "N/A"}</h5>
                                            <span className="time">{inprocess?.investmentDate ? formatTime(inprocess?.investmentDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        {
                                            inprocess?.duration ?
                                                <><h5>{inprocess?.duration} Month</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4"></Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        <h5>{inprocess?.loanAmount ? formattedNumber(inprocess?.loanAmount) : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI %</h4>
                                        <h5>{inprocess?.expectedRoi ? inprocess?.expectedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4"></Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )

    }
    return (
        modelDetails()
    )
}

function MyVerticallyCenteredModalBottom(props) {
    // console.log("Bottom Props", props);

    const { historyList } = props;

    const modelDetails = () => {
        // console.log("history");
        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm oppHistory" >
                    <Modal.Header closeButton>
                        <Modal.Title>Investment History</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan ID</h4>
                                        <h5>{historyList?.loanId}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Invested Amount</h4>
                                        {
                                            historyList?.investedAmount ?
                                                <><h5>SAR {formattedNumber(historyList?.investedAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI</h4>
                                        {
                                            historyList.expectedRoiAmount ?
                                                <><h5>SAR {historyList?.expectedRoiAmount}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Payout Amount</h4>
                                        {
                                            historyList.totalPayoutAmount ?
                                                <> <h5>SAR {formattedNumber(historyList?.totalPayoutAmount)}</h5></>
                                                :
                                                <> <h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Status</h4>
                                        <h5>{historyList?.status ? historyList?.status : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Transaction ID</h4>
                                        <h5>{historyList?.investmentTransactionId ? historyList?.investmentTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected Payout Date</h4>
                                        <div className="dateTime">
                                            <h5>{historyList?.expectedRoiPayoutDate ? formatDate(historyList?.expectedRoiPayoutDate) : "N/A"}</h5>
                                            <span className="time">{historyList?.expectedRoiPayoutDate ? formatTime(historyList?.expectedRoiPayoutDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Payout Deposited (Wallet)</h4>
                                        <div className="dateTime">
                                            <h5>{historyList?.payoutDepositedDate ? formatDate(historyList?.payoutDepositedDate) : "N/A"}</h5>
                                            <span className="time">{historyList?.payoutDepositedDate ? formatTime(historyList.payoutDepositedDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Investment Date</h4>
                                        <div className="dateTime">
                                            <h5>{historyList?.investmentDate ? formatDate(historyList?.investmentDate) : "N/A"}</h5>
                                            <span className="time">{historyList?.investmentDate ? formatTime(historyList?.investmentDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        {
                                            historyList?.duration ?
                                                <></>
                                                :
                                                <></>
                                        }
                                        <h5>{historyList?.duration} Month</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Earned ROI %</h4>
                                        <h5>{historyList?.earnedRoi ? historyList?.earnedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Payout Transaction ID</h4>
                                        <h5>{historyList?.payoutTransactionId ? historyList?.payoutTransactionId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        {
                                            historyList?.loanAmount ?
                                                <><h5>SAR {formattedNumber(historyList?.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expected ROI %</h4>
                                        <h5>{historyList?.expectedRoi ? historyList?.expectedRoi : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Earned ROI</h4>
                                        {
                                            historyList?.earnedRoi
                                                ? <h5>SAR {historyList?.earnedRoi}</h5>
                                                : <h5>N/A</h5>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return (
        modelDetails()
    )
}

export default INVESTEMNT
