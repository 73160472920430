// const accountDetailsObj = {
//   // Account Summary
//   accountCreate: "",
//   sanctionScreeningDate: "",
//   creditScoreCheckDate: "", //error in UI
//   phoneVerificationDate: "",
//   emailVerificationDate: "",
//   accountStatus: "",
//   sanctionResult: "",
//   creditScore_SIMA: "",
//   riskScore: "",

//   // Wallet Details
//   virtualWallet_IBAN: "",
//   walletStatus: "",
//   totalSpending: "",
//   walletCreated: "",
//   totalDeposit: "",
//   totalWithdrawl: "",
//   walletBalance: "",

//   // Personal Bank Details
//   IBAN_Number: "",
//   referenceNumber: "",
//   accountNumber: "",
//   issuanceDate_AD: "",
//   issuanceDate_AH: "",
// };

// const initialState = {
//   loading: false,
//   details: [],
//   error: "",
// };


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

// const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BORROWER_ACTIVE_LOAN;
const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_BORROWER_LOANS;

export const showLoans = createAsyncThunk(
    "borrower/loans",
    async (data) => {
        // console.log("Active Loans Slice - URL & data:", URL);
        const response = await postAPI(URL, data);
        // console.log("Borrower Loans Slice - response:", response);
        const loansPayload = await response.data.data;
        // console.log("Borrower Loans Slice - payload:", loansPayload);

        return loansPayload;
    }
);

const showLoansSlice = createSlice({
    name: "borrowerLoans",
    initialState: {
        loading: false,
        loans: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showLoans.pending, (state) => {
            state.loading = true;
            state.loans = null;
            state.error = null;
        });
        builder.addCase(showLoans.fulfilled, (state, action) => {
            state.loading = false;
            state.loans = action.payload;
            state.error = null;
        });
        builder.addCase(showLoans.rejected, (state, action) => {
            state.loading = false;
            state.loans = null;
            state.error = action.error.message;
        });
    },
});
export const getLoans = (state) => state.borrowerLoans;
export default showLoansSlice.reducer;
