import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
// import { postAPI } from "../../api/apiCallMethods";
import { postAPI } from "../../../api/apiCallMethods";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getListPermissions, listPermissions } from "../../../features/role-management/listPermissionsSlice";

const ROLEMANAGEMENTSUBJECTTAB = (props) => {
    const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_PERMISSIONS_BY_SUBJECT;

    const { subject } = props;
    console.log("subject", subject)
    const [btnActive, setBtnActive] = useState("");

    const permissionsSlice = useSelector(getListPermissions);
    const dispatch = useDispatch();

    const [permissions, setPermissions] = useState([]);

    const [showUserPer, setshowUserPer] = useState(false);
    const [userActionPep, setUserActionPep] = useState("button");

    const handleCloseUserPer = () => setshowUserPer(false);
    // const handleShow = () => setShow(true);

    const handleShowPer = () => {
        setshowUserPer(true);
    }

    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    const getPermissions = async () => {
        try {
            const response = await postAPI(URL, { subject });
            if (response.status == 200) {
                console.log(response?.data?.data)
                setPermissions(response?.data?.data?.permissions ?? []);
            } else {
                console.error("Error: Oops something went wrong");
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
    }

    // State to store the checked checkboxes
    const [checkedItems3, setCheckedItems3] = useState([]);
    const [checkedItems2, setCheckedItems2] = useState([]);
    const [checkedItems1, setCheckedItems1] = useState([]);
    const data = {
        subject
    }
    useEffect(() => {
        (async () => {
            await getPermissions();
        })()
    }, []
    );

    useEffect(() => {
        // Retrieve data from localStorage
        // const storedUserData = localStorage.getItem("userRoles");
        // const storedUserId = localStorage.getItem("userRoleId");
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(storedUserId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == useRoleId);

            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
                // console.log("show filter data ", useRoleData);
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }

        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            const filteredRoles1 = parsedUserData.find(role => role.roleId == "1");
            const filteredRoles2 = parsedUserData.find(role => role.roleId == "2");
            const filteredRoles3 = parsedUserData.find(role => role.roleId == "3");

            console.log("filter data 1", filteredRoles2);

            setCheckedItems3(filteredRoles3);
            setCheckedItems2(filteredRoles2);
            setCheckedItems1(filteredRoles1);
        }
    }, [useRoleId]);

    // Function to handle checkbox change
    const handleCheckboxChange3 = (event) => {
        const { name, checked } = event.target;

        setBtnActive("active");

        // Create a copy of the checkedItems array
        const newCheckedItems = { ...checkedItems3 };

        // Find the index of the item with the given name in the checkedItems array
        newCheckedItems[name] = checked ? "1" : "0";

        // Update the state with the new array of checked items
        setCheckedItems3(newCheckedItems);

        console.log(newCheckedItems);
    };

    // Function to handle checkbox change
    const handleCheckboxChange2 = (event) => {
        const { name, checked } = event.target;

        setBtnActive("active");

        // Create a copy of the checkedItems array
        const newCheckedItems = { ...checkedItems2 };

        // Find the index of the item with the given name in the checkedItems array
        newCheckedItems[name] = checked ? "1" : "0";

        // Update the state with the new array of checked items
        setCheckedItems2(newCheckedItems);

        //console.log(newCheckedItems);
    };

    // Function to handle checkbox change
    const handleCheckboxChange1 = (event) => {
        const { name, checked } = event.target;
        console.log(name, checked);


        setBtnActive("active");

        // Create a copy of the checkedItems array
        const newCheckedItems = { ...checkedItems1 };

        // Find the index of the item with the given name in the checkedItems array
        newCheckedItems[name] = checked ? "1" : "0";

        // Update the state with the new array of checked items
        setCheckedItems1(newCheckedItems);

        //console.log(newCheckedItems);
    };

    const handleAppUserManagement = async () => {
        let roleData;

        if (useRoleId == "1") {
            roleData = [checkedItems3, checkedItems2, checkedItems1];
        } else if (useRoleId == "2") {
            roleData = [checkedItems2, checkedItems3];
        } else {
            roleData = [checkedItems3];
        }

        try {
            const response = await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_USER_ROLES_UPDATE, {
                roleData: roleData
            });

            if (response.status == 200) {
                // Extract data from the response
                const responseData = response.data.data;

                // Handle the response data as needed
                if (useRoleId == "1") {
                    setCheckedItems1(responseData);
                    localStorage.setItem("userRoles", JSON.stringify(responseData));
                } else if (useRoleId == "2") {
                    setCheckedItems2(responseData);
                    localStorage.setItem("userRoles", JSON.stringify(responseData));
                } else if (useRoleId == "3") {
                    setCheckedItems3(responseData);
                    localStorage.setItem("userRoles", JSON.stringify(responseData));
                }

                setshowUserPer(false);
            } else {
                // Handle non-200 status codes
                console.error("Error: Unexpected status code", response.status);
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle the error
        }
    };

    return (
        <>
            <div className="records  p-4">
                <Form >
                    <Table responsive>
                        <thead>
                            <tr>
                                <th style={{ width: "450px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Permissions / Features</span>
                                    </div>
                                </th>
                                <th style={{ width: "200px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Operation User</span>
                                    </div>
                                </th>
                                {
                                    useRoleData["roleId"] !== "3" ?
                                        <>

                                            <th style={{ width: "200px" }}>
                                                <div className="labelIco">
                                                    <span className="headTitle">Operation Admin</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="labelIco">
                                                    <span className="headTitle">Super Admin</span>
                                                </div>
                                            </th>

                                        </>
                                        :
                                        <>
                                            <th></th>
                                        </>
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                permissions.map((permission, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="permission">
                                                    <span>
                                                        <strong>{permission?.permission?.action} </strong><br />
                                                        {permission?.permission?.description}
                                                    </span>
                                                </div>
                                            </td>

                                            {permission?.checks?.map((check) => {
                                                return (<td>
                                                    <div className="operation-user permission-check">
                                                        <Form.Check
                                                            aria-label="user"
                                                            name="appUserManagmentBan"
                                                            checked={check === 'true'}
                                                        />
                                                    </div>
                                                </td>)
                                            })}



                                        </tr>
                                    )
                                })
                            }



                        </tbody>
                    </Table>
                    {/* {useRoleId} */}
                    {
                        useRoleId == "1" || useRoleId == "2" ?
                            <>
                                <div className="d-flex justify-content-end">
                                    <Button variant="" className={`btn-theme-secondary ${btnActive}`} type="button" onClick={handleShowPer}>
                                        Save Changes
                                    </Button>
                                </div>
                            </>
                            :
                            null
                    }

                    <Modal show={showUserPer} aria-labelledby="contained-modal-title-vcenter" centered className="userPepModal permissionModal" onHide={handleCloseUserPer} >
                        <Modal.Body>
                            <h3>Are you sure you want to change role of this user?</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type="submit" onClick={handleAppUserManagement}>
                                Yes, Change
                            </Button>
                            <Button variant="primary" onClick={handleCloseUserPer}>
                                Cancel
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </Form>
            </div>
        </>
    )
}

export default ROLEMANAGEMENTSUBJECTTAB