import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LIST_ALL_WALLETS;
// const URL = "http://localhost:8000/user/admin/wallet/all/listing";

export const listAllWallets = createAsyncThunk(
    "allWallets/list",
    async (data) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data
        };

        // console.log("All Wallets List Slice - URL & Params:", URL, data);
        // const response = await postAPI(URL, data)
        // console.log("All Wallets List Slice - response:", response);
        const response = await api(reqConfig);
        const allWalletsPayload = await response.data.data;
        // console.log("All Wallets List Slice - payload:", allWalletsPayload);
        return allWalletsPayload;
    }
);

const listAllWalletsSlice = createSlice({
    name: "allWalletsList",
    initialState: {
        loading: false,
        allWallets: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listAllWallets.pending, (state) => {
            state.loading = true;
            state.allWallets = null;
            state.error = null;
        });
        builder.addCase(listAllWallets.fulfilled, (state, action) => {
            state.loading = false;
            state.allWallets = action.payload;
            state.error = null;
        });
        builder.addCase(listAllWallets.rejected, (state, action) => {
            state.loading = false;
            state.allWallets = null;
            state.error = action.error.message;
        });
    },
});

export const getAllWalletsList = (state) => state.allWalletsList;
export default listAllWalletsSlice.reducer;

