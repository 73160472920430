import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { listLender, getLenderList } from "../../../features/lender/listLenderSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { jwtDecode } from "jwt-decode";
import NotificationModal from "./notification-modal";
import { CSVLink, CSVDownload } from "react-csv";

const CONTENT = () => {
    const [sendNotiShowTop, setSendNotiShowTop] = useState(Array(10).fill(false));

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const [sortType, setSortType] = useState("desc")
    const [orderBy, setOrderBy] = useState("createdAt")
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const params = {
        limit,
        page,
        orderBy,
        sortType,
        filterType,
        filterValue
    }
    const dispatch = useDispatch();
    const lenderSlice = useSelector(getLenderList)
    const lenders = lenderSlice?.lenders?.lendersList;
    const [lendersCSV, setLendersCSV] = useState([]);

    useEffect(() => {
        (async () => {
            await dispatch(listLender(params))
        })()
    }, [limit, page, orderBy, sortType, filterType, filterValue]
    );
    // console.log("Here are the lenders Slice....", lenderSlice);
    // console.log("Here are the lenders....", lenders);

    useEffect(() => {
        // Update state values when borrowerSlice changes
        setTotal(lenderSlice?.lenders?.total);
        setTotalPages(lenderSlice?.lenders?.totalPages);
        setLendersCSV(lenders ? lenders.map(lender => ({
            "Regstration Date": formatDate(lender.createdAt),
            "User ID": lender.idNumber,
            "Borrower Name": lender.name,
            "Status": lender.status,
            "Active Investments": lender.activeInvestments,
            "Pending Request": lender.pendingRequests,
            "Wallet Balance": lender.walletBalance,
        })) : []);

    }, [lenderSlice]);

    const [perPage, setPerPage] = useState()
    const [total, setTotal] = useState(lenderSlice?.lenders?.total)
    const [totalPages, setTotalPages] = useState(lenderSlice?.lenders?.totalPages)

    const handleSearchChange = (e) => {
        e.preventDefault();
        const idNumber = e.currentTarget.value

        if (idNumber.length <= 10 && !isNaN(Number(idNumber))) {
            // if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
            setfilterType("idNumber");
            setfilterValue(idNumber);
        }
        if (idNumber.length === 0) {
            setfilterType(undefined);
            setfilterValue(undefined);
        }

        setSortType(undefined);
        setOrderBy(undefined);
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const idNumber = e.currentTarget.elements.serachInput.value
        if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
            setfilterType("idNumber");
            setfilterValue(idNumber);
            setSortType(undefined);
            setOrderBy(undefined);
        }
    }

    const handlePageNumber = (e) => {
        const value = e.currentTarget.value
        if (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= totalPages) {
            setPage(value);
        }
    }

    const handlPrevClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) >= 1
            //  && Number(value) <= totalPages
        ) {
            if (Number(value) === 1) {
                setPage(1)
            } else {
                setPage(state => Number(state) - 1)
            }
        }
    }

    const handlNextClick = () => {
        const value = page;
        if (!isNaN(Number(value))
            && Number(value) <= totalPages
            // && Number(value) >= 1
        ) {
            if (Number(value) === totalPages) {
                setPage(totalPages)
            } else {
                setPage(state => Number(state) + 1)
            }
        }
    }

    const handleDateSort = (e) => {
        if (sortType === undefined || sortType === 'asc') setSortType("desc");
        else if (sortType !== "asc") setSortType("asc");
        setOrderBy("createdAt")
    }

    const handleStatusFilter = (status) => {
        if (status === "all") {
            setfilterType(undefined);
            setfilterValue(undefined);
            setSortType(undefined);
            setOrderBy(undefined);

        } else {
            setfilterType("status");
            setfilterValue(status);
        }
    }

    const handleSortActiveInvestment = () => {
        // setfilterType("");
        // setfilterValue("");
        // setSortType(undefined);
        // setOrderBy(undefined);
    }

    const handleSortPendingRequest = () => {
        // setfilterType("");
        // setfilterValue("");
        // setSortType(undefined);
        // setOrderBy(undefined);
    }

    const handleSortActiveWallet = () => {
        // setfilterType("");
        // setfilterValue("");
        // setSortType(undefined);
        // setOrderBy(undefined);
    }

    const reload = () => {
        if (page !== 1 || sortType !== "desc" || orderBy !== "createdAt" || filterType !== undefined || filterValue !== undefined) {
            setPage(1);
            setSortType("desc");
            setOrderBy("createdAt");
            setfilterType(undefined);
            setfilterValue(undefined);
        } else {
            dispatch(listLender(params))
        }
    }

    // User Roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");

    //     setuseRoleId(storedUserId);

    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Filter the parsed data based on roleId
    //         const filteredRoles = parsedUserData.filter(role => role.roleId === useRoleId);

    //         if (filteredRoles.length > 0) {
    //             // If there are roles with the specified roleId
    //             const filterRoleData = filteredRoles[0];
    //             setuseRoleData(filterRoleData); // Set the role data
    //             console.log("show filter data ", useRoleData);
    //         } else {
    //             // If there are no roles with the specified roleId
    //             console.log("No role data found for the specified roleId.");
    //         }
    //     }
    // }, [useRoleId]); 

    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            //console.log("parsedUserData======", parsedUserData)

            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    const handleSendNotiShowTop = (index) => {

        const updateModalsTop = [...sendNotiShowTop];
        updateModalsTop[index] = true;
        setSendNotiShowTop(updateModalsTop)
    }

    const handleSendNotiCloseTop = (index) => {

        const updateModalsTop = [...sendNotiShowTop];
        updateModalsTop[index] = false;
        setSendNotiShowTop(updateModalsTop)
    }

    const handleDownloadClick = (e) => {
        if (lenders?.length) {
            return true;
        }
        return false;
    }


    return (
        <div className="content">
            <div className="optionsBox">
                <Row>
                    <Col lg="6">
                        {
                            useRoleData["appUserManagmentSearch"] === "1" ?
                                <>
                                    <div className="searchBox">
                                        <Form className="searchForm" onSubmit={handleSearchSubmit}>
                                            <Form.Control
                                                className=""
                                                type="text"
                                                id="serachInput"
                                                placeholder="Search by User ID"
                                                maxLength={10}
                                                onChange={handleSearchChange}
                                            />
                                            <Button type="submit" className="">
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z"
                                                        stroke="url(#paint0_linear_15_1117)"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <defs>
                                                        <linearGradient
                                                            id="paint0_linear_15_1117"
                                                            x1="1.46638"
                                                            y1="1.79751"
                                                            x2="15.8646"
                                                            y2="16.1974"
                                                            gradientUnits="userSpaceOnUse"
                                                        >
                                                            <stop stopColor="#236092" />
                                                            <stop offset="1" stopColor="#270089" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </Button>
                                        </Form>
                                    </div>
                                </>
                                :
                                null
                        }
                    </Col>

                    <Col lg="6">
                        <div className="pagination">
                            <span className="totalRecord me-3">{total ? total : "N/A"} Records</span>
                            {/* <span className="totalRecord me-3">3000 </span> */}
                            <Form.Control
                                className=""
                                id="pageCount"
                                placeholder=""
                                value={page}
                                onChange={handlePageNumber}
                            />
                            {/* <span>{totalPages}</span> */}
                            <span className="sep">- </span>
                            <span className="showRecord">{totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;</span>
                            {/* <span className="showRecord">50</span> */}
                            {/* <span className="of">of </span> */}
                            <span className="prePage" style={{ cursor: "pointer" }} onClick={handlPrevClick}>
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="25"
                                        height="25"
                                        rx="12.5"
                                        stroke="#696969"
                                    />
                                    <g clipPath="url(#clip0_0_1)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z"
                                            fill="#696969"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="nextPage" style={{ cursor: "pointer" }} onClick={handlNextClick}>
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="25"
                                        height="25"
                                        rx="12.5"
                                        stroke="#696969"
                                    />
                                    <g clipPath="url(#clip0_0_1)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z"
                                            fill="#696969"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="translate(4 22) rotate(-90)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>

                            <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                    <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                    <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                </svg>
                            </span>


                            <span style={{ marginLeft: "4px" }}>
                                <CSVLink
                                    data={lendersCSV ? lendersCSV : []}
                                    filename={"lenders-list.csv"}
                                    target="_blank"
                                    onClick={handleDownloadClick}
                                >
                                    <svg fill="#696969" height="25" width="25" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path d="M256 0c70.426 0 134.432 28.793 180.819 75.181C483.207 121.568 512 185.574 512 256c0 70.419-28.793 134.432-75.181 180.819C390.432 483.207 326.426 512 256 512c-70.419 0-134.432-28.793-180.819-75.181C28.793 390.432 0 326.419 0 256c0-70.419 28.793-134.432 75.181-180.819C121.568 28.793 185.581 0 256 0zm-49.893 210.248c-3.825.163-6.542 1.432-8.109 3.818-4.258 6.379 1.55 12.688 5.584 17.127 11.439 12.556 39.477 42.733 45.122 49.373 4.277 4.731 10.369 4.731 14.65 0 5.831-6.812 35.277-38.364 46.15-50.574 3.774-4.25 8.441-10.044 4.512-15.926-1.604-2.386-4.295-3.655-8.119-3.818h-23.223v-57.525c0-6.128-5.029-11.161-11.161-11.161H240.5c-6.136 0-11.161 5.026-11.161 11.161v57.525h-23.232zm-74.67 63.529c-3.554-14.697 18.708-20.095 22.258-5.387 1.485 6.135 2.761 12.301 3.99 18.491 2.291 11.567 3.986 18.823 8.257 22.437 4.525 3.828 13.08 5.148 29.474 5.148h124.106c15.134 0 23.033-1.445 27.189-5.202 3.997-3.617 5.563-10.697 7.594-21.564l.044-.213c1.195-6.389 2.43-12.779 3.966-19.097 3.55-14.695 25.788-9.317 22.258 5.364l-.006.023c-1.449 6.011-2.603 12.045-3.73 18.119l-.003.024c-2.988 16.011-5.439 26.993-13.646 34.705-8.084 7.601-20.748 10.817-43.666 10.817H195.416c-23.961 0-37.125-2.884-45.596-10.387-8.68-7.685-11.31-18.847-14.664-35.778-1.161-5.851-2.315-11.699-3.719-17.5zM256 24.604c127.271 0 231.396 104.125 231.396 231.396 0 127.271-104.125 231.396-231.396 231.396-127.267 0-231.396-104.125-231.396-231.396C24.604 128.729 128.733 24.604 256 24.604z" /></svg>
                                </CSVLink></span>

                        </div>
                    </Col>
                </Row>
            </div>

            <div className="records activeLoanApp">
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ width: '190px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Registration Date</span>
                                    <span className="icon" style={{ cursor: "pointer" }} onClick={handleDateSort}>
                                        <svg
                                            width="18"
                                            height="16"
                                            viewBox="0 0 18 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z"
                                                fill="#270089"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </th>
                            <th style={{ width: '120px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">ID</span>
                                </div>
                            </th>
                            <th style={{ width: '240px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Lender Name</span>
                                </div>
                            </th>
                            <th style={{ width: '120px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Status</span>

                                    <Dropdown align={{ lg: "end" }} className="statusDropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <span className="icon">
                                                <svg
                                                    width="17"
                                                    height="16"
                                                    viewBox="0 0 17 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M15.579 0C15.2539 0 1.58822 0 1.26316 0C0.565897 0 0 0.565897 0 1.26316C0 1.96043 0.565897 2.52632 1.26316 2.52632C1.58822 2.52632 15.2539 2.52632 15.579 2.52632C16.2763 2.52632 16.8422 1.96043 16.8422 1.26316C16.8422 0.565897 16.2763 0 15.579 0Z"
                                                        fill="#270089"
                                                    />
                                                    <path
                                                        opacity="0.35"
                                                        d="M1.68433 2.52637L4.72349 6.17354C5.48055 7.08133 5.89487 8.2266 5.89487 9.40807V13.9588C5.89487 14.7824 6.49024 15.4847 7.30203 15.6203L9.47635 15.9824C10.2469 16.1113 10.9475 15.5176 10.9475 14.7369V9.40892C10.9475 8.2266 11.3618 7.08217 12.1189 6.17438L15.1581 2.52637H1.68433Z"
                                                        fill="#270089"
                                                    />
                                                </svg>
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleStatusFilter("all")}> All</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleStatusFilter("active")}>Active</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleStatusFilter("banned")}>Banned</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleStatusFilter("pending")}>Pending KYC</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleStatusFilter("deactivated")}>Deactivated</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </th>
                            <th style={{ width: '200px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Active Investments</span>
                                    {/* <span className="icon" style={{ cursor: "pointer" }} onClick={handleSortActiveInvestment}>
                                        <svg
                                            width="18"
                                            height="16"
                                            viewBox="0 0 18 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z"
                                                fill="#270089"
                                            />
                                        </svg>
                                    </span> */}
                                </div>
                            </th>
                            <th style={{ width: '180px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Pending Request</span>
                                    {/* <span className="icon" style={{ cursor: "pointer" }} onClick={handleSortPendingRequest}>
                                        <svg
                                            width="18"
                                            height="16"
                                            viewBox="0 0 18 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z"
                                                fill="#270089"
                                            />
                                        </svg>
                                    </span> */}
                                </div>
                            </th>
                            <th style={{ width: '170px' }}>
                                <div className="labelIco">
                                    <span className="headTitle">Wallet Balance</span>
                                    {/* <span className="icon" style={{ cursor: "pointer" }} onClick={handleSortActiveWallet}>
                                        <svg
                                            width="18"
                                            height="16"
                                            viewBox="0 0 18 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z"
                                                fill="#270089"
                                            />
                                            <path
                                                d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z"
                                                fill="#270089"
                                            />
                                            <path
                                                opacity="0.35"
                                                d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z"
                                                fill="#270089"
                                            />
                                        </svg>
                                    </span> */}
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lenderSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                        {!lenderSlice?.loading && lenderSlice?.error ? <div>Error: {lenderSlice?.error}</div> : null}
                        {!lenderSlice?.loading && lenders?.length ? (

                            lenders.map((lender, index) => (
                                <tr key={lender.id}>
                                    <td>
                                        <div className="dateTime">
                                            <span className="date">{formatDate(lender?.createdAt)}</span>
                                            <br />
                                            <span className="time">{formatTime(lender?.createdAt)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="userId">
                                            <span>{lender?.govId}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="name">
                                            <span>{lender?.fullName}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="status">
                                            <span>{lender?.status ? lender?.status : "N/A"}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="loan" align="center">
                                            <span>{lender?.activeInvestments ? lender?.activeInvestments : "N/A"}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="activeApp" align="center">
                                            <span>{lender?.pendingRequests ? lender?.pendingRequests : "N/A"}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="walletBalance">
                                            {
                                                lender?.walletBalance !== "" ?
                                                    <>
                                                        <span className="price">{formattedNumber(lender?.walletBalance)}</span>
                                                        <span className="currency">SAR</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>N/A</span>
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            useRoleData["appUserManagmentSearch"] === "1" ?
                                                <>

                                                    <div className="d-flex align-items-center">

                                                        <div className="action me-1">
                                                            <Link to={`/lenders-details/${lender.id}`} className="btn btn-view"> View </Link>
                                                        </div>
                                                        <div className="sendNotifi">
                                                            <Button onClick={() => handleSendNotiShowTop(index)}>
                                                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.00195 17H5.60636C4.34793 17 3.71872 17 3.58633 16.9023C3.4376 16.7925 3.40126 16.7277 3.38515 16.5436C3.37082 16.3797 3.75646 15.7486 4.52776 14.4866C5.32411 13.1835 6.00031 11.2862 6.00031 8.6C6.00031 7.11479 6.63245 5.69041 7.75766 4.6402C8.88288 3.59 10.409 3 12.0003 3C13.5916 3 15.1177 3.59 16.2429 4.6402C17.3682 5.69041 18.0003 7.11479 18.0003 8.6C18.0003 11.2862 18.6765 13.1835 19.4729 14.4866C20.2441 15.7486 20.6298 16.3797 20.6155 16.5436C20.5994 16.7277 20.563 16.7925 20.4143 16.9023C20.2819 17 19.6527 17 18.3943 17H15.0003M9.00195 17L9.00031 18C9.00031 19.6569 10.3435 21 12.0003 21C13.6572 21 15.0003 19.6569 15.0003 18V17M9.00195 17H15.0003" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </Button>
                                                            <SendCustomNotificationModal show={sendNotiShowTop[index]} onHide={() => handleSendNotiCloseTop(index)} user={lender} status="active" index={index} sendNotiShowTop={sendNotiShowTop} setSendNotiShowTop={setSendNotiShowTop} />
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                            ))
                        )
                            : null
                        }

                    </tbody>
                </Table>

                {!lenderSlice?.loading && !lenders?.length && !lenderSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
            </div>
        </div>
    );
};


function SendCustomNotificationModal(props) {
    const { user, index, sendNotiShowTop, setSendNotiShowTop } = props;
    // console.log("Top Props", props);

    return (
        <>
            <Modal {...props} size="lg" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="customNotiModal" >
                <NotificationModal user={user} index={index} sendNotiShowTop={sendNotiShowTop} setSendNotiShowTop={setSendNotiShowTop} />
            </Modal>
        </>
    )

}

export default CONTENT;
