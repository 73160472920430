import React, { useEffect, useState } from "react";

// Elements
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";

import { useDispatch, useSelector } from "react-redux";
import {
  listAllWalletsStatusLog,
  getListAllWalletsStatusLogList,
} from "../../../features/wallet-management/allWalletStatusLogSlice";

import {
  formatDate,
  formatTime,
} from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const WalletStatus = () => {
  // const [transactionTypeFilter, setTransactionTypeFilter] = useState("LOAN_POOL_TRANSACTION,INSTALLMENT_POOL_TRANSACTION,TOPUP_TRANSACTION,WITHDRAWAL_TRANSACTION");
  const [userId, setUserId] = useState(useParams().id);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterType, setfilterType] = useState(undefined);
  const [filterValue, setfilterValue] = useState(undefined);

  const params = {
    userId,
    pageSize,
    pageNo,
    sortBy,
    sortOrder,
    filterType,
    filterValue,
  };
  // console.log("Params", params);
  const dispatch = useDispatch();
  const listWalletStatusSlice = useSelector(getListAllWalletsStatusLogList);
  const walletStatusLog =
    listWalletStatusSlice?.statusLog?.virtualAccountStatusLogs;
  // console.log("In list walletslice .....", listWalletStatusSlice);
  // console.log("wallet status log .....", listWalletStatusSlice?.statusLog);

  useEffect(() => {
    (async () => {
      await dispatch(listAllWalletsStatusLog(params));
    })();
  }, [userId, pageSize, pageNo, sortBy, sortOrder, filterType, filterValue]);

  useEffect(() => {
    // Update state values when borrowerSlice changes
    setTotal(listWalletStatusSlice?.statusLog?.totalVirtualAccounts);
    setTotalPages(listWalletStatusSlice?.statusLog?.totalPages);
  }, []);

  const [perPage, setPerPage] = useState();
  const [total, setTotal] = useState();
  const [totalPages, setTotalPages] = useState();

  const handlePageNumber = (e) => {
    const value = e.currentTarget.value;

    if (
      !isNaN(Number(value)) &&
      Number(value) >= 1 &&
      Number(value) <= totalPages
    ) {
      setPageNo(value);
    }
  };

  const handlPrevClick = () => {
    const value = pageNo;
    if (
      !isNaN(Number(value)) &&
      Number(value) >= 1
      //  && Number(value) <= totalPages
    ) {
      if (Number(value) === 1) {
        setPageNo(1);
      } else {
        setPageNo((state) => Number(state) - 1);
      }
    }
  };

  const handlNextClick = () => {
    const value = pageNo;
    if (
      !isNaN(Number(value)) &&
      Number(value) < totalPages &&
      Number(value) >= 1
    ) {
      setPageNo((state) => Number(state) + 1);
      // if (Number(value) === totalPages) {
      //   setPage(totalPages)
      // }
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    console.log("Here in search");

    const idNumber = e.currentTarget.value;

    // console.log(idNumber);

    if (idNumber.length <= 9 && !isNaN(Number(idNumber))) {
      // if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
      setfilterType("userId");
      setfilterValue(idNumber);
      // setId(idNumber)
    }
    if (idNumber.length === 0) {
      setfilterType(undefined);
      setfilterValue(undefined);
      // setId(undefined)
    }

    setSortBy(undefined);
    setSortOrder(undefined);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const idNumber = e.currentTarget.elements.serachInput.value;
    // console.log(idNumber)
    if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
      setfilterType("userId");
      setfilterValue(idNumber);
      // setId(idNumber)
      setSortBy(undefined);
      setSortOrder(undefined);
    }
  };

  return (
    <>
      <div className="accountSummary mb-3 pt-0 px-0 mb-0">
        <h3 className="mb-3">Wallet Status Log:</h3>
      </div>

      <div className="content m-0">
        <div className="optionsBox">
          <Row>
            <Col lg="6">
              <div className="searchBox">
                <Form className="searchForm" onSubmit={handleSearchSubmit}>
                  <Form.Control
                    className=""
                    id="serachInput"
                    placeholder="Search by Wallet IBAN, Holder ID"
                    onChange={handleSearchChange}
                  />
                  <Button type="submit" className="">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z"
                        stroke="url(#paint0_linear_15_1117)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_15_1117"
                          x1="1.46638"
                          y1="1.79751"
                          x2="15.8646"
                          y2="16.1974"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#236092" />
                          <stop offset="1" stopColor="#270089" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Button>
                </Form>
                {/* <Form className="searchForm">
                                    <Form.Control className="" id="serachInput" placeholder="Search by Wallet IBAN, Holder ID" />
                                    <Button type="submit" className="">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z" stroke="url(#paint0_linear_15_1117)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_15_1117" x1="1.46638" y1="1.79751" x2="15.8646" y2="16.1974" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#236092" />
                                                    <stop offset="1" stopColor="#270089" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Button>
                                </Form> */}
              </div>
            </Col>

            <Col lg="6">
              <div className="pagination">
                <span className="totalRecord me-3">
                  {total ? total : "N/A"} Records{" "}
                </span>
                <Form.Control
                  className=""
                  id="pageCount"
                  placeholder=""
                  value={pageNo}
                  onChange={handlePageNumber}
                />

                <span className="sep">- </span>
                <span className="showRecord">
                  {totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;
                </span>

                <span
                  className="prePage"
                  style={{ cursor: "pointer" }}
                  onClick={handlPrevClick}
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="25"
                      height="25"
                      rx="12.5"
                      stroke="#696969"
                    />
                    <g clipPath="url(#clip0_0_1)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z"
                        fill="#696969"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_0_1">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <span
                  className="nextPage"
                  onClick={handlNextClick}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="25"
                      height="25"
                      rx="12.5"
                      stroke="#696969"
                    />
                    <g clipPath="url(#clip0_0_1)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z"
                        fill="#696969"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_0_1">
                        <rect
                          width="18"
                          height="18"
                          fill="white"
                          transform="translate(4 22) rotate(-90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="accountSummary mb-3 pt-0 px-0 mb-5">
        <div className="records vertical-scroll active-loans-scroll">
          <Table responsive className="mb-0">
            <thead className="sticky-header">
              <tr>
                <th style={{ width: "140px", whiteSpace: "nowrap" }}>
                  <div className="labelIco">
                    <span className="headTitle">Date & Time</span>
                  </div>
                </th>
                <th style={{ width: "160px" }}>
                  <div className="labelIco">
                    <span className="headTitle">Last Status</span>
                  </div>
                </th>
                <th style={{ width: "160px" }}>
                  <div className="labelIco">
                    <span className="headTitle">Changed Status</span>
                  </div>
                </th>
                <th style={{ width: "160px" }}>
                  <div className="labelIco">
                    <span className="headTitle">Change Reason</span>
                  </div>
                </th>
                <th style={{ width: "300px" }}>
                  <div className="labelIco">
                    <span className="headTitle">Changed By</span>
                  </div>
                </th>
                <th style={{ width: "160px" }}>
                  <div className="labelIco">
                    <span className="headTitle">User ID</span>
                  </div>
                </th>
                <th>
                  <div className="labelIco">
                    <span className="headTitle">Comment</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {listWalletStatusSlice?.loading && (
                <div className="notifyNoRecord">Loading...</div>
              )}
              {!listWalletStatusSlice?.loading &&
                listWalletStatusSlice?.error ? (
                <div>Error: {listWalletStatusSlice?.error}</div>
              ) : null}
              {!listWalletStatusSlice?.loading && walletStatusLog?.length
                ? walletStatusLog.map((walletStatusLogList, index) => (
                  <tr key={walletStatusLogList?.id}>
                    <td style={{ width: "140px" }}>
                      <div className="dateTime label-val">
                        <span className="date">10/10/2023</span>
                        <br />
                        <span className="time">17:55:33</span>
                        <span className="date">
                          {formatDate(walletStatusLogList?.createdAt)}
                        </span>
                        <br />
                        <span className="time">
                          {formatTime(walletStatusLogList?.createdAt)}
                        </span>
                      </div>
                    </td>
                    <td style={{ width: "160px" }}>
                      <div className="status">
                        <span
                          className={`dot ${walletStatusLogList?.lastStatus === "false"
                            ? "banned"
                            : ""
                            }`}
                        ></span>
                        <span>{walletStatusLogList?.lastStatus}</span>
                      </div>
                    </td>
                    <td style={{ width: "160px" }}>
                      <div className="status">
                        <span
                          className={`dot ${walletStatusLogList?.changedStatus === "false"
                            ? "banned"
                            : ""
                            }`}
                        ></span>
                        <span>{walletStatusLogList?.changedStatus}</span>
                      </div>
                    </td>
                    <td style={{ width: "160px" }}>
                      <div className="changeReason label-val">
                        <span>{walletStatusLogList?.changedReason}</span>
                      </div>
                    </td>
                    <td style={{ width: "300px" }}>
                      <div className="changedBy label-val">
                        <span>{walletStatusLogList?.changedBy}</span>
                      </div>
                    </td>
                    <td style={{ width: "160px" }}>
                      <div className="userId label-val">
                        <span>{walletStatusLogList?.userId}</span>
                      </div>
                    </td>
                    <td>
                      <div className="Comment label-val">
                        <span>{walletStatusLogList?.comment}</span>
                      </div>
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default WalletStatus;
