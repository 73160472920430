import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_PENDING_APPLICATION_APPROVALS_DETAILS;


export const showPendingApplicationApprovalsDetails = createAsyncThunk(
    "rejectedApplication/showRejectedApplicationDetails",
    async (data) => {

        // console.log("Rejected Application Details Slice - URL & Params:", URL, data);
        const response = await postAPI(URL, data);

        // const response = {
        //     "action": "/admin/application/detail",
        //     "code": 200,
        //     "status": true,
        //     "data": {
        //         "borrowerName": "Omer Abed ALBAHLAL",
        //         "borrowerId": "1222222222",
        //         "applicationId": "36",
        //         "createdOnDate": "2024-03-08T06:50:01.000Z",
        //         "applicationExpiryDate": "2024-04-08T06:50:01.000Z",
        //         "reqStatus": "pending approval",
        //         "loanAmount": "1000",
        //         "loanReason": "Travel & Entertainment",
        //         "duration": "2",
        //         "noOfInstallments": "0",
        //         "profitRate": "",
        //         "perInstallmentAmount": "",
        //         "totalRepayAmount": ""
        //     },
        //     "message": "",
        //     "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiT21lciBBYmVkIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImlkIjoyNCwicm9sZUlkIjoxLCJ1c2VyVHlwZSI6ImFkbWluIiwiaWF0IjoxNzE2ODkwNDM0LCJleHAiOjE3MTY4OTA3MzR9.9pYL15g4BqTqEPfjW_bFQnjUYhxzAnjgXLOCPwobSfs"
        // }
        // const response = await getAPI('http://localhost:3004/get-data', params);

        // console.log("Pending Application Approvals Details Slice - response:", response);

        const pemdingApplicationApprovalsDetailsPayload = await response.data.data;



        // const pemdingApplicationApprovalsDetailsPayload = await response.data;
        // const pemdingApplicationApprovalsDetailsPayload = await response.data;
        // console.log("Rejected Application Details Slice - payload:", pemdingApplicationApprovalsDetailsPayload);

        return pemdingApplicationApprovalsDetailsPayload;
    }
);

const showPendingApplicationApprovalsDetailsSlice = createSlice({
    name: "showRejectedApplicationDetailsSlice",
    initialState: {
        loading: false,
        pendingApplicationApprovalsDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showPendingApplicationApprovalsDetails.pending, (state) => {
            state.loading = true;
            state.pendingApplicationApprovalsDetails = null;
            state.error = null;
        });
        builder.addCase(showPendingApplicationApprovalsDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingApplicationApprovalsDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showPendingApplicationApprovalsDetails.rejected, (state, action) => {
            state.loading = false;
            state.pendingApplicationApprovalsDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getPendingApplicationApprovalsDetails = (state) => state.pendingApplicationApprovalsDetails;
export default showPendingApplicationApprovalsDetailsSlice.reducer;
