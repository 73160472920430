import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";

// const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BORROWER_ACTIVE_LOAN;
const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_INFO;

export const showInfo = createAsyncThunk(
    "user/info",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // console.log("User Info Slice - URL & Params:", URL);
        // const response = await getAPI(URL, params);
        // console.log("User Info Slice - response:", response);
        const infoPayload = await response.data.data;
        // console.log("User Info Slice - payload:", infoPayload);

        return infoPayload;
    }
);

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        loading: false,
        info: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showInfo.pending, (state) => {
            state.loading = true;
            state.info = null;
            state.error = null;
        });
        builder.addCase(showInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.info = action.payload;
            state.error = null;
        });
        builder.addCase(showInfo.rejected, (state, action) => {
            state.loading = false;
            state.info = null;
            state.error = action.error.message;
        });
    },
});
export const getInfo = (state) => state.info;
export default userInfoSlice.reducer;
