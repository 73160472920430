import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

// Components
import HEADER from "../../../component/header/header";
import BREADCRUMB from "../../../component/breadcrumb/breadcrumb";

import DASHBOARD from "../../../component/admin-users/role-management/dasboard";
import APPUSERMANAGEMENT from "../../../component/admin-users/role-management/app-user-management";
import LOANAPPLICATIONMANAGEMENT from "../../../component/admin-users/role-management/loan-application-management";
import LOANMANAGEMENT from "../../../component/admin-users/role-management/loan-management";
import WALLETMANAGEMENT from "../../../component/admin-users/role-management/wallet-management";
import ADMINUSERMANAGEMENT from "../../../component/admin-users/role-management/admin-user-management";
import PLATFORMMANAGEMENT from "../../../component/admin-users/role-management/platform-management";

import SIDEBAR from "../../../component/sidebar/sidebar";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getAdminSubjectsList, listAdminSubjects } from "../../../features/role-management/listSubjectsSlice";
import ROLEMANAGEMENTSUBJECTTAB from "../../../component/admin-users/role-management/subject-management";

const USERRIGHTS = () => {
    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();
    const subjectsSlice = useSelector(getAdminSubjectsList)
    const dispatch = useDispatch();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");

    //     setuseRoleId(storedUserId);

    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Find the object with the specified role ID
    //         const filteredRole = parsedUserData.find(role => role.roleId === storedUserId);

    //         if (filteredRole) {
    //             // If a role with the specified ID is found, set the state with that role object
    //             setuseRoleData(filteredRole);
    //         } else {
    //             // If no role with the specified ID is found
    //             console.log("No role data found for the specified role ID.");
    //         }
    //     }
    // }, [useRoleId]);

    useEffect(()=>{
        console.log("subjects",subjectsSlice)

    }, [subjectsSlice])

    useEffect(() => {
        (async () => {
            await dispatch(listAdminSubjects({}))
        })()
    }, [])

    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            //console.log("parsedUserData======", parsedUserData)

            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />

                    <BREADCRUMB />

                    <div className="borrowers">
                        <h3 className="page-title mb-4">User Rights</h3>

                        <div className="borrowerTabs">
                            <Tabs defaultActiveKey="app-user-management" id="uncontrolled-tab-example" className="">
                                {/* <Tab eventKey="dashboard" title="Dashboard">
                                    <DASHBOARD />
                                </Tab> */}
                                {
                                    subjectsSlice?.subjects?.subjects?.map((item, index) => {
                                        return (
                                            <Tab eventKey={item.subject.replaceAll(" ","-")} title={item.subject}>
                                                <ROLEMANAGEMENTSUBJECTTAB key={index} subject={item.subject} />
                                            </Tab>
                                        )
                                    })
                                }
                                {/* <Tab eventKey="app-user-management" title="App User Management">
                                    <APPUSERMANAGEMENT />
                                </Tab>

                                <Tab eventKey="loan-applications" title="Loan Applications">
                                    <LOANAPPLICATIONMANAGEMENT />
                                </Tab>

                                <Tab eventKey="loan-management" title="Loan Management">
                                    <LOANMANAGEMENT />
                                </Tab>

                                <Tab eventKey="wallet-management" title="Wallet Management">
                                    <WALLETMANAGEMENT />
                                </Tab> */}

                                {/* <Tab eventKey="account-management" title="Account Management">
                                    <ADMINUSERMANAGEMENT />
                                </Tab>

                                <Tab eventKey="platform-settings" title="Platform Settings">
                                    <PLATFORMMANAGEMENT />
                                </Tab> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default USERRIGHTS