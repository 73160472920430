import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/apiCallMethods";




export const showNotifications = createAsyncThunk(
    "lender/notifications",
    async (reqConfig) => {
        // console.log("====== Dispatching action ========")
        // console.log("Notifications", reqConfig);
        const response = await api(reqConfig);
        // console.log("Borrower Notifications Slice - response:", response);
        const notificationsPayload = await response.data.data;
        // console.log("Borrower Notifications Slice - payload:", notificationsPayload);

        return notificationsPayload;
    }
);

const showNotificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        loading: false,
        notifications: [],
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showNotifications.pending, (state) => {
            state.loading = true;
            state.notifications = null;
            state.error = null;
        });
        builder.addCase(showNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.notifications = action.payload;
            state.error = null;
        });
        builder.addCase(showNotifications.rejected, (state, action) => {
            state.loading = false;
            state.notifications = null;
            state.error = action.error.message;
        });
    },
});
export const getNotifications = (state) => state.lenderNotifications;
export default showNotificationsSlice.reducer;
