import React from "react";
import "../../../assets/css/counter/counter.css";
import { useState, useEffect } from "react";
import { showAdminAnalytics, getAdminAnalytics } from "../../../features/admin-users/adminAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";

const EXPIREDAPPCOUNTER = () => {
    const dispatch = useDispatch();
    const counterSlice = useSelector(getAdminAnalytics)
    const analytics = counterSlice?.analytics;

    console.log("======The Admin Counter Slice======", counterSlice);
    console.log("======The Admin Analytics======", analytics);
    const params = {
        type: "admin"
    }

    useEffect(() => {
        (async () => {
            await dispatch(showAdminAnalytics(params))
        })()
    }, []
    );

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Users</h6>
                        <h1>{analytics?.all ? analytics?.all : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Total Active Users</h6>
                        <h1>{analytics?.active ? analytics?.active : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Total Locked Users</h6>
                        <h1>{analytics?.locked ? analytics?.locked : "N/A"}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EXPIREDAPPCOUNTER