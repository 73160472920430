import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import { showAccountDetails, getAccountDetails } from "../../../features/user/accountDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";


const ACCOUNTDETAILS = () => {

    const [id, setId] = useState(useParams().id);
    const dispatch = useDispatch();
    const accountDetailsSlice = useSelector(getAccountDetails)
    const accountDetails = accountDetailsSlice?.accountDetails;

    const params = {
        id
    }

    useEffect(() => {
        (async () => {
            dispatch(showAccountDetails(params));
        })()
    }, []);

    // console.log("Here are the lenders account details", accountDetails);

    // tooltip Code 
    const Link = ({ id, children, title, placement }) => (
        <OverlayTrigger
            key="bottom"
            placement="bottom" overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a href="#">{children}</a>
        </OverlayTrigger>
    );

    return (
        <>
            <div className="accountSummary d-none">
                <h3 className="mb-3">Account Summary</h3>

                <Row>
                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Account Created</h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Sanction Screening Date</h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Credit Score - SIMA </h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Phone Verification Date</h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="4" className="mb-3">
                        <div className="summaryBox">
                            <h4>Email Verification Date</h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Account Status</h4>
                            <h5>Active</h5>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Sanction Result</h4>
                            <h5>---</h5>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">
                            <h4>Credit Score - SIMA </h4>
                            <div className="dateTime">
                                <h5>11/10/2023</h5>
                                <span className="time">17:55:33</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="2" className="mb-3">
                        <div className="summaryBox">

                            <h4>Risk Score</h4>
                            <h5>600</h5>
                        </div>
                    </Col>
                </Row>
            </div>

            <hr className="d-none" />

            <div className="accountSummary">
                <h3 className="mb-3">Wallet Details</h3>

                <Row>
                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Virtual Wallet IBAN</h4>
                            <h5>{accountDetails?.viban ? accountDetails?.viban : "N/A"}
                            </h5>
                        </div>
                    </Col>

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Wallet Status</h4>
                            <h5>{accountDetails?.walletStatus ? accountDetails?.walletStatus : "N/A"}</h5>
                        </div>
                    </Col>

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Total Spending</h4>
                            {
                                accountDetails?.totalSpending ?
                                    <>
                                        <h5>SAR {formattedNumber(accountDetails?.totalSpending)}</h5>
                                    </>
                                    :
                                    <>
                                        <h5>N/A</h5>
                                    </>
                            }
                        </div>
                    </Col>

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Wallet Created</h4>
                            <div className="dateTime">
                                <h5>{accountDetails?.walletCreatedDate ? formatDate(accountDetails?.walletCreatedDate) : "N/A"}</h5>
                                <span className="time">{accountDetails?.walletCreatedDate ? formatTime(accountDetails?.walletCreatedDate) : ""}</span>
                            </div>
                        </div>
                    </Col>

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Total Deposit</h4>
                            {
                                accountDetails?.totalDeposit ?
                                    <>
                                        <h5>SAR {formattedNumber(accountDetails?.totalDeposit)}</h5>
                                    </>
                                    :
                                    <>
                                        <h5>N/A</h5>
                                    </>
                            }
                        </div>
                    </Col>

                    {/* <Col lg="3"></Col> */}

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Total Withdrawal</h4>
                            {
                                accountDetails?.totalWithdrawl ?
                                    <>
                                        <h5>SAR {formattedNumber(accountDetails?.totalWithdrawl)}</h5>
                                    </>
                                    :
                                    <>
                                        <h5>N/A</h5>
                                    </>
                            }
                        </div>
                    </Col>

                    <Col lg="3" className="mb-3">
                        <div className="summaryBox">
                            <h4>Wallet Balance</h4>
                            {
                                accountDetails?.walletBalance ?
                                    <>
                                        <h5>SAR {formattedNumber(accountDetails?.walletBalance)}</h5>
                                    </>
                                    :
                                    <>
                                        <h5>N/A</h5>
                                    </>
                            }
                        </div>
                    </Col>
                    {/* 
                    <Col lg="8">
                    </Col> */}
                </Row>
            </div>

            <hr />

            <div className="accountSummary pt-1 mb-3">
                <h3 className="mb-3">Personal Bank Details</h3>

                <Row>
                    <Col lg="10">
                        <Row>
                            <Col lg="4" className="mb-3">
                                <div className="summaryBox">
                                    <h4 className="d-flex">
                                        ACTIVE IBAN Number

                                    </h4>
                                    <div className="verify-dv">
                                        <h5 className="d-inline">{accountDetails?.activeIbanNumber ? accountDetails?.activeIbanNumber : "N/A"}  {accountDetails?.activeIbanNumber ?
                                            <div className="verify-dv ms-2 d-inline">
                                                <span className="badge bg-success">
                                                    Verified
                                                    <Link title={formatDate(accountDetails?.ibanVerifiedDate) + " | " + formatTime(accountDetails?.ibanVerifiedDate)} id="t-1" aria-describedby="tooltip-bottom" placement="bottom">
                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.95825 4.87501H7.04159V3.79168H5.95825M6.49992 10.8333C4.11117 10.8333 2.16659 8.88876 2.16659 6.50001C2.16659 4.11126 4.11117 2.16668 6.49992 2.16668C8.88867 2.16668 10.8333 4.11126 10.8333 6.50001C10.8333 8.88876 8.88867 10.8333 6.49992 10.8333ZM6.49992 1.08334C5.78859 1.08334 5.08423 1.22345 4.42705 1.49566C3.76987 1.76788 3.17274 2.16686 2.66976 2.66985C1.65393 3.68567 1.08325 5.06342 1.08325 6.50001C1.08325 7.9366 1.65393 9.31435 2.66976 10.3302C3.17274 10.8332 3.76987 11.2321 4.42705 11.5044C5.08423 11.7766 5.78859 11.9167 6.49992 11.9167C7.93651 11.9167 9.31426 11.346 10.3301 10.3302C11.3459 9.31435 11.9166 7.9366 11.9166 6.50001C11.9166 5.78868 11.7765 5.08432 11.5043 4.42714C11.2321 3.76996 10.8331 3.17283 10.3301 2.66985C9.8271 2.16686 9.22997 1.76788 8.57279 1.49566C7.91561 1.22345 7.21125 1.08334 6.49992 1.08334ZM5.95825 9.20834H7.04159V5.95834H5.95825V9.20834Z" fill="#044E18" />
                                                        </svg>
                                                    </Link>
                                                </span>
                                            </div> : ""}</h5>
                                    </div>
                                </div>
                            </Col>

                            {/* <Col lg="3" className="mb-3">
                                <div className="summaryBox">
                                    <h4>Previous IBAN Number</h4>
                                    <h5>{accountDetails?.previousIbanNumber ? accountDetails?.previousIbanNumber : "N/A"}</h5>
                                </div>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </div>

            <hr />

            <div className="accountSummary pt-2 mb-3">
                <h3 className="mb-3">Ban History</h3>

                <div className="records vertical-scroll active-loans-scroll">
                    <Table responsive className="mb-0">
                        <thead>
                            <tr>
                                <th style={{ width: '130px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Action Date</span>
                                    </div>
                                </th>
                                <th style={{ width: '140px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Action By</span>
                                    </div>
                                </th>
                                <th style={{ width: '120px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">User ID</span>
                                    </div>
                                </th>
                                <th style={{ width: '350px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Comment</span>
                                    </div>
                                </th>
                                <th style={{ width: '240px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Last State</span>
                                    </div>
                                </th>
                                <th style={{ width: '170px' }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Action</span>
                                    </div>
                                </th>
                                <th >
                                    <div className="labelIco">
                                        <span className="headTitle">Reason</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ height: '118px' }}>
                            {accountDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!accountDetailsSlice?.loading && accountDetailsSlice?.error ? <div>Error: {accountDetailsSlice?.error}</div> : null}
                            {!accountDetailsSlice?.loading && accountDetails?.accountHistroy?.length ? (
                                accountDetails?.accountHistroy.map((history, index) => (
                                    <tr key={index}>
                                        <td style={{ width: '130px' }}>
                                            <div className="dateTime">
                                                <span className="date">{formatDate(history?.actionDate)}</span><br />
                                                <span className="time">{formatTime(history?.actionDate)}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: '140px' }}>
                                            <div className="actionBy">
                                                <span>{history?.actionBy ? history?.actionBy : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: '120px' }}>
                                            <div className="userId">
                                                <span>{history?.userID ? history?.userID : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: '350px' }}>
                                            <div className="comment pe-5">
                                                <span>{history?.comment ? history?.comment : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: '240px' }}>
                                            <div className="lastState">
                                                <span>{history?.lastState ? history?.lastState : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: '170px' }}>
                                            <div className="status">
                                                <span>{history?.action ? history?.action : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="reason">
                                                <span>{history?.reason ? history?.reason : "N/A"}</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )
                                : null
                            }

                        </tbody>
                    </Table>

                    {!accountDetailsSlice?.loading && !accountDetails?.accountHistroy?.length && !accountDetailsSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>


            </div>
        </>
    )
}

export default ACCOUNTDETAILS