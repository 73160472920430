import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { showOtherDetails, getOtherDetails } from "../../../features/user/otherDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const OTHERDETAILS = () => {
    const [id, setId] = useState(useParams().id)
    const dispatch = useDispatch();
    const otherDetailsSlice = useSelector(getOtherDetails)
    const otherDetails = otherDetailsSlice?.otherDetails;

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showOtherDetails(params));
        })()
    }, []);
    // console.log("Here are the borrowers other details Slice.....", otherDetailsSlice);
    // console.log("Here are the lenders other details", otherDetails);

    return (
        <>
            <Row>
                <Col lg="7">
                    <div className="personal-details mb-0">
                        <h3>PEP Details</h3>


                        <Row>
                            <Col lg="12">
                                <div className="identityBox">
                                    <h4>Do you have a relationship to a politically exposed person (PEP) individual?</h4>
                                    <h5>{otherDetails?.haveRelationshipToPEP}</h5>
                                </div>
                            </Col>

                            <Col lg="12">
                                <div className="identityBox">
                                    <h4>Is any of your relatives (First and second degree known as PEP?</h4>
                                    <h5>{otherDetails?.havePEPRelative}</h5>
                                </div>
                            </Col>

                            <Col lg="12">
                                <div className="identityBox">
                                    <h4>Have you held any political positions in the last ten years?</h4>
                                    <h5>{otherDetails?.politicalPosition}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details mt-5 pt-1 mb-0">
                        <h3></h3>

                        <Row className="mb-0">
                            <Col lg="12">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> <span>؟(PEP)</span>هل لديك علاقة بشخص مكشوف سياسيًا</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arHaveRelationshipToPEP}</h5>
                                </div>
                            </Col>

                            <Col lg="12">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> <span>؟(PEP)</span>هل أحد من أقاربك (الدرجة الأولى والثانية معروف بـ</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arHavePEPRelative}</h5>
                                </div>
                            </Col>

                            <Col lg="12">
                                <div className="identityBox">
                                    <h4 className="text-end arabi">هل تقلدت أي مناصب سياسية خلال السنوات العشر الأخيرة؟</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arPoliticalPosition}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr className="mt-0 mb-2" />

            <Row>
                <Col lg="7">
                    <div className="personal-details mt-2 mb-0">
                        <h3>Professional Details</h3>

                        <Row>
                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Education Level</h4>
                                    <h5>{otherDetails?.educationLevel ? otherDetails?.educationLevel : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Job Sector</h4>
                                    <h5>{otherDetails?.jobSector ? otherDetails?.jobSector : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Job Title:</h4>
                                    <h5>{otherDetails?.jobTitle ? otherDetails?.jobTitle : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Nature of Field</h4>
                                    <h5>{otherDetails?.natureOfField ? otherDetails?.natureOfField : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4>Employment Date</h4>
                                    <h5>{otherDetails?.employmentDate ? formatDate(otherDetails?.employmentDate) : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4>Salary</h4>
                                    <h5>{otherDetails?.salary ? <>{formattedNumber(otherDetails?.salary)}</> : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>
                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details mt-2 mb-3">
                        <h3 className="text-end arabi">تفاصيل احترافية</h3>

                        <Row className="mb-0 row-cols-lg-3">
                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> قطاع العمل</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arJobSector ? otherDetails?.arJobSector : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> مستوى التعليم</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arEducationLevel ? otherDetails?.arEducationLevel : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi">طبيعة المجال</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arNatureOfField ? otherDetails?.arNatureOfField : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> مسمى وظيفي:</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arJobTitle ? otherDetails?.arJobTitle : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4 className="text-end arabi">مرتب</h4>
                                    <h5 className="text-end arabi">{otherDetails?.salary ? <>{formattedNumber(otherDetails?.salary)}</> : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4 className="text-end arabi"> تاريخ التوظيف</h4>
                                    <h5 className="text-end arabi">{otherDetails?.employmentDate ? formatDate(otherDetails?.employmentDate) : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr className="mt-0 mb-3" />

            <Row>
                <Col lg="7">
                    <div className="personal-details my-0">
                        <Row>
                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4>Source of Income</h4>
                                    <h5>{otherDetails?.srcOfIncome ? otherDetails?.srcOfIncome : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4>Source of Wealth</h4>
                                    <h5>{otherDetails?.srcOfWealth ? otherDetails?.srcOfWealth : "N/A"}</h5>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details my-0">
                        <Row className="mb-0 row-cols-lg-3">
                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4 className="text-end arabi"> مصدر الثروة</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arSrcOfWealth ? <>{formattedNumber(otherDetails?.arSrcOfWealth)}</> : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox mb-0">
                                    <h4 className="text-end arabi">مصدرا للدخل</h4>
                                    <h5 className="text-end arabi">{otherDetails?.arSrcOfIncome ? <>{formattedNumber(otherDetails?.arSrcOfIncome)}</> : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col lg="7">
                    <div className="personal-details my-2">
                        <h3>Family Details</h3>

                        <Row>
                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>House</h4>
                                    <h5>{otherDetails?.house ? otherDetails?.house : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Type of Home</h4>
                                    <h5>{otherDetails?.typeOfHome ? otherDetails?.typeOfHome : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Martial Status</h4>
                                    <h5>{otherDetails?.maritialStatus ? otherDetails?.maritialStatus : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>No. of Dependents</h4>
                                    <h5>{otherDetails?.numOfDependents ? otherDetails?.numOfDependents : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>No. of Domestic Labour</h4>
                                    <h5>{otherDetails?.numOfDomesticLabour || otherDetails?.numOfDomesticLabour == 0 ? otherDetails?.numOfDomesticLabour : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details my-2">
                        <h3 className="text-end arabi">تفاصيل عائلية</h3>

                        <Row className="mb-0 row-cols-lg-3">
                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> نوع من المنزل </h4>
                                    <h5 className="text-end arabi">{otherDetails?.arTypeOfHome ? otherDetails?.arTypeOfHome : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> منزل </h4>
                                    <h5 className="text-end arabi">{otherDetails?.arHouse ? otherDetails?.arHouse : "N/A"} </h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> عدد المُعالين</h4>
                                    <h5 className="text-end arabi">{otherDetails?.numOfDependents ? otherDetails?.numOfDependents : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi">الحالة الاجتماعية</h4>
                                    <h5 className="text-end arabi"> {otherDetails?.arMaritialStatus ? otherDetails?.arMaritialStatus : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4"></Col>

                            <Col lg="4"></Col>

                            <Col lg="12">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> عدد العمالة المنزلية </h4>
                                    <h5 className="text-end arabi">{otherDetails?.numOfDomesticLabour || otherDetails?.numOfDomesticLabour == 0 ? otherDetails?.numOfDomesticLabour : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr className="mt-0 mb-3" />

            <Row>
                <Col lg="7">
                    <div className="personal-details my-2">
                        <h3>Expenses Details</h3>

                        <Row>
                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>
                                        Main Expenditures<br />
                                        <span className="small">
                                            (Includes Rent, School, Bills,<br />
                                            Insurance and Hospital)
                                        </span>
                                    </h4>
                                    <h5>{otherDetails?.mainExpenditure ? formattedNumber(otherDetails?.mainExpenditure) : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>
                                        Secondary Expenditures<br />
                                        <span className="small">
                                            (Other then main expenses)
                                        </span><br /><br />
                                    </h4>
                                    <h5>{otherDetails?.secondaryExpenditures ? formattedNumber(otherDetails?.secondaryExpenditures) : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details my-2">
                        <h3 className="text-end arabi">تفاصيل النفقات</h3>

                        <Row className="mb-4 row-cols-lg-3">
                            <Col lg="4"></Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi">نوع من المنزل <br /> <span className="small">(النفقات الأخرى ثم الرئيسية)</span><br /><br /></h4>
                                    <h5 className="text-end arabi">{otherDetails?.secondaryExpenditures ? formattedNumber(otherDetails?.secondaryExpenditures) : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4 className="text-end arabi"> النفقات الرئيسية<br /><span className="small">(يشمل الإيجار، المدرسة، الفواتير،التأمين والمستشفى)</span> </h4>
                                    <h5 className="text-end arabi">{otherDetails?.mainExpenditure ? formattedNumber(otherDetails?.mainExpenditure) : "N/A"}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default OTHERDETAILS