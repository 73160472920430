import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LIST_PLATFORM_WALLETS;
// const URL = "http://localhost:8000/user/admin/wallet/platform/listing";

export const listPlatformWallets = createAsyncThunk(
    "platformWallets/list",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };

        // console.log("Platform Wallets List Slice - URL & Params:", URL, params);
        // const response = await postAPI(URL, params)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // const platformWalletsPayload = await response.data.data.;
        // console.log("Platform Wallets List Slice - response:", response);

        const response = await api(reqConfig);
        const platformWalletsPayload = await response.data.data;
        // console.log("Platform Wallets List Slice - payload:", platformWalletsPayload);
        return platformWalletsPayload;
    }
);

const listPlatformWalletsSlice = createSlice({
    name: "platformWallets/list",
    initialState: {
        loading: false,
        platformWallets: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPlatformWallets.pending, (state) => {
            state.loading = true;
            state.platformWallets = null;
            state.error = null;
        });
        builder.addCase(listPlatformWallets.fulfilled, (state, action) => {
            state.loading = false;
            state.platformWallets = action.payload;
            state.error = null;
        });
        builder.addCase(listPlatformWallets.rejected, (state, action) => {
            state.loading = false;
            state.platformWallets = null;
            state.error = action.error.message;
        });
    },
});

export const getPlatformWalletsList = (state) => state.platformWalletsList;
export default listPlatformWalletsSlice.reducer;

