import React, { useEffect, useState } from "react";
import "../../../assets/css/counter/counter.css";
// import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { listPlatformWallets, getPlatformWalletsList } from "../../../features/wallet-management/listPlatformWalletsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const COUNTER = () => {

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [sortType, setSortType] = useState(undefined)
    const [orderBy, setOrderBy] = useState(undefined)
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const params = {
        limit,
        page,
        orderBy,
        sortType,
        filterType,
        filterValue
    }

    const dispatch = useDispatch();
    const platformWalletsSlice = useSelector(getPlatformWalletsList)
    const platformWalletsStats = platformWalletsSlice?.platformWallets?.stats;

    // console.log("plaform wallet listing Stats", platformWalletsStats)

    useEffect(() => {
        (async () => {
            await dispatch(listPlatformWallets(params))
        })()
    }, [limit, page, orderBy, sortType, filterType, filterValue]
    );

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Platform Wallets</h6>
                        <h1>{platformWalletsStats?.total}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Active Wallets</h6>
                        <h1>{platformWalletsStats?.active}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Inactive Wallets</h6>
                        <h1>0</h1>
                    </div>

                    <div className="countBox">
                        <h6>Suspended Wallets</h6>
                        <h1>{platformWalletsStats?.suspended}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Total Platform Wallets Balance</h6>
                        <h1>
                            {formattedNumber(platformWalletsStats?.balance)}
                            <span className="currency">SAR</span>
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default COUNTER