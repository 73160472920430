import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

import { useDispatch, useSelector } from "react-redux";
import { showInfo, getInfo } from "../../../../features/loan-management/loanInfoSlice";

import { formatDate, formatTime } from "../../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../../utilities/helper/formatAmount/formatNumber";

const INFO = () => {
    const [modalShow, setModalShow] = React.useState(false);

    const [id, setId] = useState(useParams().id);
    const dispatch = useDispatch();
    const loanInfoSlice = useSelector(getInfo)
    const info = loanInfoSlice?.info;
    // console.log("This is the Active loan info slice,=============", loanInfoSlice);

    // console.log("This is the Active loan,=============", info);

    const params = {
        id
    }

    useEffect(() => {
        (async () => {
            dispatch(showInfo(params));
        })()
    }, []);


    return (
        <>
            <div className="borrowerInfoBox">
                <div className="borrowerInfo">
                    <Row>
                        <Col lg="11">
                            <Row >
                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan ID</h5>
                                        <h4>{info?.loanId ? info?.loanId : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Application Created</h5>
                                        <h4>
                                            <span className="date">{info?.applicationCreatedDate ? formatDate(info?.applicationCreatedDate) : "N/A"}</span>
                                            <span className="time">{info?.applicationCreatedDate ? formatTime(info?.applicationCreatedDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan Amount</h5>
                                        {
                                            info?.loanAmount
                                                ? <h4>SAR {formattedNumber(info?.loanAmount)}</h4>
                                                : <h4>N/A</h4>
                                        }
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Installment Remaining</h5>
                                        <h4>{info?.installmentRemaining ? info?.installmentRemaining : "N/A"}</h4>
                                    </div>
                                </Col>

                                {/* <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Promissory Signing </h5>
                                        <h4>
                                            <span className="date">{info?.promissorySiginingDate ? formatDate(info?.promissorySiginingDate) : "N/A"}</span>
                                            <span className="time">{info?.promissorySiginingDate ? formatTime(info?.promissorySiginingDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col> */}

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Application Fee Paid</h5>
                                        {
                                            info?.applicationFeePaid
                                                ? <h4>SAR {formattedNumber(info?.applicationFeePaid)}</h4>
                                                : <h4>N/A</h4>
                                        }

                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Borrower ID</h5>
                                        <h4>{info?.borrowerId ? info?.borrowerId : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Application Expiry</h5>
                                        {
                                            info?.applicationExpiryDate
                                                ? <h4>{formatDate(info?.applicationExpiryDate)}</h4>
                                                : <h4>N/A</h4>
                                        }
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan Duration</h5>
                                        {
                                            info?.loanDuration
                                                ? <h4>{info?.loanDuration} Months</h4>
                                                : <h4>N/A</h4>
                                        }
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Installment Paid</h5>
                                        <h4>{info?.installmentsPaid ? info?.installmentsPaid : "N/A"}</h4>
                                    </div>
                                </Col>

                                {/* <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan Transfer</h5>
                                        <h4>
                                            <span className="date">{info?.loanTransferDate ? formatDate(info?.loanTransferDate) : "N/A"}</span>
                                            <span className="time">{info?.loanTransferDate ? formatTime(info?.loanTransferDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col> */}

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Application Ref. No.</h5>
                                        <h4>{info?.applicationFeeRefNo ? info?.applicationFeeRefNo : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Borrower Name</h5>
                                        <h4>{info?.borrowerName ? info?.borrowerName : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Application Matured</h5>
                                        <h4>
                                            <span className="date">{info?.applicationMaturedDate ? formatDate(info?.applicationMaturedDate) : "N/A"}</span>
                                            <span className="time">{info?.applicationMaturedDate ? formatTime(info?.applicationMaturedDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan Reason</h5>
                                        <h4>{info?.loanReason ? info?.loanReason : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Total Installment</h5>
                                        <h4>{info?.totalInstallments ? info?.totalInstallments : "N/A"}</h4>
                                    </div>
                                </Col>

                                {/* <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Loan Transfer Ref. No.</h5>
                                        <h4>{info?.loanTransferRefNo ? info?.loanTransferRefNo : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Promissory Note</h5>
                                        {
                                            info?.promissoryNote
                                                ? <h4>Download Now &gt;</h4>
                                                : <h4>N/A</h4>
                                        }
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModal(props) {
    const [confirmModalShow, setConfirmModalShow] = React.useState(false);

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="banForm">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to ban this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row>
                        <Col lg="3" className="mb-4">
                            <div className="popupDv">
                                <h4>User ID</h4>
                                <h5>0152150111</h5>
                            </div>
                        </Col>
                        <Col lg="9" className="mb-4">
                            <div className="popupDv">
                                <h4>Full Name</h4>
                                <h5>Ahmed Fahad Mohammad Saud</h5>
                            </div>
                        </Col>

                        <Col lg="5" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Other</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" rows={3} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            {/* <Button className="btn-white me-3" type="submit" >Ban</Button> */}
                            <Button className="btn-white me-3" onClick={() => setConfirmModalShow(true)} >Ban</Button>
                            <MyConfirmModal show={confirmModalShow} onHide={() => setConfirmModalShow(false)} />

                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>

    );
}

function MyConfirmModal(props) {
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to ban this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmBtns">
                    <Button className="btn-white me-3" onClick={props.onHide}>Yes</Button>
                    <Button onClick={props.onHide}>Cancel</Button>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}

export default INFO