import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

// const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BORROWER_ACTIVE_LOAN;
const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_LOAN_INFO;

export const showInfo = createAsyncThunk(
    "user/info",
    async (data) => {
        // console.log("Loan Info Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // console.log("Loan Info Slice - response:", response);
        const infoPayload = await response.data.data;
        // console.log("Loan Info Slice - payload:", infoPayload);

        return infoPayload;
    }
);

const loanInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        loading: false,
        info: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showInfo.pending, (state) => {
            state.loading = true;
            state.info = null;
            state.error = null;
        });
        builder.addCase(showInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.info = action.payload;
            state.error = null;
        });
        builder.addCase(showInfo.rejected, (state, action) => {
            state.loading = false;
            state.info = null;
            state.error = action.error.message;
        });
    },
});
export const getInfo = (state) => state.loanInfo;
export default loanInfoSlice.reducer;
