import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_EXPIRED_APPLICATIONS_DETAILS;

export const showExpiredApplicationDetails = createAsyncThunk(
    "expiredApplication/showExpiredApplicationDetails",
    async (data) => {

        // console.log("Expired Application Details Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Expired Application Details Slice - response:", response);
        const expiredApplicationDetailsPayload = await response.data.data;
        // const expiredApplicationDetailsPayload = await response.data;
        // console.log("Expired Application Details Slice - payload:", expiredApplicationDetailsPayload);

        return expiredApplicationDetailsPayload;
    }
);

const showExpiredApplicationDetailsSlice = createSlice({
    name: "showExpiredApplicationDetailsSlice",
    initialState: {
        loading: false,
        expiredApplicationDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showExpiredApplicationDetails.pending, (state) => {
            state.loading = true;
            state.expiredApplicationDetails = null;
            state.error = null;
        });
        builder.addCase(showExpiredApplicationDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.expiredApplicationDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showExpiredApplicationDetails.rejected, (state, action) => {
            state.loading = false;
            state.expiredApplicationDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getExpiredApplicationDetails = (state) => state.expiredApplicationsDetails;
export default showExpiredApplicationDetailsSlice.reducer;
