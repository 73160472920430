import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROFILE_DETAILS;

export const showProfileDetails = createAsyncThunk(
  "user/profileDetails",
  async (params) => {
    const reqConfig = {
      method: "POST",
      url: URL,
      params
  };

 const response = await api(reqConfig);

    // console.log("Profile Slice - URL & Params:", URL, params);

    // const response = await getAPI(URL, params);
    // console.log("Profile Slice - response:", response);
    const otherDetailsPayload = await response.data.data;
    // console.log("Profile Slice - payload:", otherDetailsPayload);

    return otherDetailsPayload;
  }
);

const showProfileDetailsSlice = createSlice({
  name: "userProfileDetails",
  initialState: {
    loading: false,
    profileDetails: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showProfileDetails.pending, (state) => {
      state.loading = true;
      state.profileDetails = null;
      state.error = null;
    });
    builder.addCase(showProfileDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.profileDetails = action.payload;
      state.error = null;
    });
    builder.addCase(showProfileDetails.rejected, (state, action) => {
      state.loading = false;
      state.profileDetails = null;
      state.error = action.error.message;
      console.log(action.error.message);
    });
  },
});
export const getProfileDetails = (state) => state.userProfileDetails;
export default showProfileDetailsSlice.reducer;
