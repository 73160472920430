import axios from "axios";

import { jwtDecode } from "jwt-decode";


export function isValidJWT(token) {
    // Regular expression to match JWT token format
    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;

    return jwtRegex.test(token);
};
// import { useNavigate } from "react-router-dom";

///////           Token validation testing   ///////////////
/////////////////////////////////////////////////////////////

// const userToken = localStorage.getItem("userToken");
// const { isExpired } = useJwt(userToken);

// const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSm9obiBEb2UiLCJyb2xlIjoiU3VwZXIgQWRtaW4iLCJleHAiOjE2OTY1ODk0MDB9.mB6HM4XWdTzhmwUkFOFuNIJhUryzaBdFT0eD1__Gl5k"; // Replace with your JWT
// const secretKey = "secret"; // Replace with your secret key

// try {
//   // Decode the token (no verification yet)
//   const decodedToken = jwt.decode(token, { complete: true });
//   console.log("_____---___-- Decoded Token ----___--__", decodedToken.payload);
//   if (!decodedToken) {
//     throw new Error("Invalid token");
//   }

//   // Verify the token
//   const verifiedToken = jwt.verify(token, secretKey);

//   // Access the decoded data
//   console.log("Decoded Token:", verifiedToken);

//   // Check expiration
//   const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
//   if (verifiedToken.exp <= currentTimestamp) {
//     console.error("Token has expired");
//   } else {
//     console.log("Token is valid");
//   }
// } catch (error) {
//   console.error("Invalid token:", error.message);
// }

// console.log(jwt);

/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////

const axiosInstance = axios.create();

// // Request interceptor to add the authentication token to each request

axiosInstance.interceptors.request.use(
    (config) => {












        const token = localStorage.getItem("userToken");
        const decodedToken = token ? jwtDecode(token) : "";
        const userId = decodedToken?.id;



        if (token) {
            config.headers["accessToken"] = token;
            config.headers["application"] = "TheLendingHub";
            config.headers["applicationVersion"] = "1.0";
            config.headers["ignore_encryption"] = true;
            config.headers["userId"] = userId;
            // console.log("The token is,.,.,,,.,", token);

            //   config.headers["Authorization"] = `Bearer ${ token }`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async (response) => {

        if (response.data.code === 403) {

            // window.location.replace("/");
            console.log(response)
            console.log("Status code is 403");

            window.location.replace("/");

            // localStorage.removeItem("userToken");
            localStorage.removeItem("metaData");
            // localStorage.removeItem("userToken");
            localStorage.removeItem("userRoles");
            localStorage.removeItem("userId");

        } else if (response.data.code === 404) {
            window.location.replace("/*")
            console.log("Status code is 404");
        }

        return response;
    },
    async (error) => {
        console.log("I am in the response interceptor and the error is/./././.", error);

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        //   if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;

        //     try {
        //       const refreshToken = localStorage.getItem('refreshToken');
        //       const response = await axios.post('/api/refresh-token', { refreshToken });
        //       const { token } = response.data;

        //       localStorage.setItem('token', token);

        //       // Retry the original request with the new token
        //       originalRequest.headers.Authorization = `Bearer ${ token }`;
        //       return axios(originalRequest);
        //     } catch (error) {
        //       // Handle refresh token error or redirect to login
        //     }
        //   }

        return Promise.reject(error);
    }
);

// axiosInstance.interceptors.response.use(
//   ((response) => response,
//   (error) => {
//     // extracting response and config objects
//     const { response, config } = error;
//     // checking if error is Authorized error
//     if (response.status === 401) {
//       console.log("Error 401 Detected");
//       // console.log("axios instance");
//     }
//     return error;
//   })
// );

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     //extracting response and config objects
//     if (error.response.status === 401) {
//       localStorage.removeItem("userToken");
//       console.log("The get error is recieved", error.response.status);
//       setTimeout(() => {
//         // location.replace("/");
//         window.location.replace("/")
//       }, 5000);
//     }
//     //checking if error is Aunothorized error

//     //clear local storage and log user out
//     return Promise.reject(error);
//   }
// );





export default axiosInstance