import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_USER_DETAILS;

export const showAdminUserDetails = createAsyncThunk(
    "adminUser/adminUserDetailsDetails",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };

        // console.log("Admin User Details Slice - URL & Params:", URL, params);
        // const response = await getAPI(URL, params);
        // // const response = await getAPI('http://localhost:3004/get-data', params);
        // // console.log("Admin User Details Slice - response:", response);
        // // const adminUserDetailsDetailsPayload = await response.data.data;
        const response = await api(reqConfig);
        const adminUserDetailsDetailsPayload = await response.data;
        // console.log("Admin User Details Slice - payload:", adminUserDetailsDetailsPayload);

        return adminUserDetailsDetailsPayload;
    }
);

const adminUserDetailsDetailsSlice = createSlice({
    name: "showAdminUserDetailsSlice",
    initialState: {
        loading: false,
        adminUserDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showAdminUserDetails.pending, (state) => {
            state.loading = true;
            state.adminUserDetails = null;
            state.error = null;
        });
        builder.addCase(showAdminUserDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.adminUserDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showAdminUserDetails.rejected, (state, action) => {
            state.loading = false;
            state.adminUserDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getAdminUserDetails = (state) => state.adminUsersDetails;
export default adminUserDetailsDetailsSlice.reducer;
