import React, { useState, useEffect } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

// Components
import HEADER from "../../component/header/header";
import BREADCRUMB from "../../component/breadcrumb/breadcrumb";
import INFO from "../../component/borrower/info/info";
import PROFILE from "../../component/borrower/profile/profile";
import OTHERDETAILS from "../../component/borrower/otherdetails/otherdetails";
import ACCOUNTDETAILS from "../../component/borrower/accountdetails/accountdetails";
import LOANAPPLICATIONS from "../../component/borrower/loan-applications/loan-applications";
import LOANS from "../../component/borrower/loans/loans";
import TRANSACTIONHISTORY from '../../component/borrower/transactionhistory/transactionhistory';
import LOGINHISTORY from "../../component/borrower/loginhistory/loginhistory";
import MANUALNOTIFICATION from "../../component/borrower/manualNotification/manualNotification";
import SIDEBAR from "../../component/sidebar/sidebar";
import { jwtDecode } from "jwt-decode";
import LoaderSpinner from "../../component/ui/LoaderSpinner";
import useAuth from "../../hooks/permission-hook";
const BORROWERS = () => {

    const [permissions, checkPermission]  = useAuth();

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />

                    <BREADCRUMB />

                    <div className="borrowers">
                        <INFO />
                        <div className="borrowerTabs">
                            <Tabs
                                defaultActiveKey='profile'
                                id="uncontrolled-tab-example"
                                className=""
                            >

                                <Tab eventKey="profile" title="Profile" >
                                    {
                                        checkPermission({subject:"app user management", action:"view summary"}) ?
                                            <>
                                                <PROFILE />
                                            </>
                                            :
                                            null
                                    }
                                </Tab>

                                <Tab eventKey="other-details" title="Other Details">
                                    <OTHERDETAILS />
                                </Tab>

                                <Tab eventKey="account-details" title="Account Details">
                                    <ACCOUNTDETAILS />
                                </Tab>

                                <Tab eventKey="loan-application" title="Loan Applications">
                                    <LOANAPPLICATIONS />
                                </Tab>

                                <Tab eventKey="loans" title="Loans">
                                    <LOANS />
                                </Tab>

                                <Tab eventKey="transaction-history" title="Transaction History">
                                    <TRANSACTIONHISTORY />
                                </Tab>

                                <Tab eventKey="login-history" title="Login History">
                                    <LOGINHISTORY />
                                </Tab>
                                <Tab eventKey="manual-notification" title="Manual Notification">
                                    <MANUALNOTIFICATION />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BORROWERS