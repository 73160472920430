import React, { useState, useEffect } from "react";
// import { useState } from 'react';
import "../../assets/css/dashboard/dashboard.css";
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import Button from 'react-bootstrap/Button';
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import HEADER from "../../component/header/header";
import BREADCRUMB from "../../component/breadcrumb/breadcrumb";
import WELCOME from "../../component/borrower/welcome/welcome";
import COUNTER from "../../component/borrower/counter/counter";
import SIDEBAR from "../../component/sidebar/sidebar";
import useAuth from "../../hooks/permission-hook";

// import { get} from "../../utilities/api"


// import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { jwtDecode } from "jwt-decode";

// import Table from 'react-bootstrap/Table';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';s
// import Table from 'react-bootstrap/Table';
// import Dropdown from 'react-bootstrap/Dropdown';

// import mada from "../../assets/images/mada.png"
// import lendinglogo from "../../assets/images/lending-logo.png"
// import master from "../../assets/images/master.png"

const DASHBOARD = () => {
    let decoded = undefined;

    const isValidJWT = (token) => {
        // Regular expression to match JWT token format
        const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;

        return jwtRegex.test(token);
    };


    //     const [show, setShow] = useState(false);

    //   const handleClose = () => setShow(false);
    //   const handleShow = () => setShow(true);
    const navigate = useNavigate();

    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    useEffect(() => {
        // Retrieve data from localStorage
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = localStorage.getItem("userRoleId");

        const token = localStorage.getItem("userToken");
        if (!token) {
            navigate("/", { replace: true });
        } else {
            if (isValidJWT(token)) {
                decoded = jwtDecode(token);
                console.log(decoded)
            } else {
                navigate("/", { replace: true });
            }
        }

        // console.log("roleId:", decoded.roleId);
        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);

            // Find the object with the specified role ID
            const filteredRole = parsedUserData.find(role => role.roleId === String(decoded.roleId));
            // console.log({ filteredRole }, decoded.roleId)
            if (filteredRole) {
                // If a role with the specified ID is found, set the state with that role object
                setuseRoleData(filteredRole);
            } else {
                // If no role with the specified ID is found
                console.log("No role data found for the specified role ID.");
            }
        }
    }, [useRoleId]);

    // console.log(useRoleData);
    // console.log(useRoleData["adminUserManagmentRoleEdit"]);
    // console.log("useRoleDatauseRoleDatauseRoleData", useRoleData["appUserManagmentView"]);

    const [permissions, checkPermission]  = useAuth();

    return (
        <>
            <div className="dashoboard">

                <SIDEBAR />

                <div className="component">
                    <HEADER />
                    <BREADCRUMB />
                    {
                         checkPermission({subject:"app user management", action:"view summary"}) ?
                            <COUNTER />
                            :
                            null

                    }
                    <WELCOME />
                </div>
            </div>
        </>
    );
};

export default DASHBOARD;
