import React, { useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";

const INFO = ({ regDate, category, userType }) => {
    const [modalShow, setModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);

    const openConfirmModal = () => {
        setConfirmModalShow(true); // Open the confirmation modal
    };

    const handleBan = () => {
        setModalShow(false); // Close the first modal
        setConfirmModalShow(false); // Close the confirmation modal
    };

    const handleCancel = () => {
        setConfirmModalShow(false); // Close only the confirmation modal
    };

    return (
        <>
            <div className="borrowerInfoBox">
                <div className="borrowerInfo">
                    <Row>
                        <Col lg="12">
                            <Row className="row-cols-lg-6">
                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Registration Date</h5>
                                        <h4>
                                            <span className="date">{regDate ? formatDate(regDate) : "N/A"}</span>
                                            <span className="time">{regDate ? formatTime(regDate) : ""}</span>
                                        </h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>Category</h5>
                                        <h4>{category ? category : "N/A"}</h4>
                                    </div>
                                </Col>

                                <Col lg="2">
                                    <div className="infoBox">
                                        <h5>User Type</h5>
                                        <h4>{userType ? userType : "N/A"}</h4>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModal(props) {

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="banForm">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to ban this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row>
                        <Col lg="3" className="mb-4">
                            <div className="popupDv">
                                <h4>User ID</h4>
                                <h5>0152150111</h5>
                            </div>
                        </Col>
                        <Col lg="9" className="mb-4">
                            <div className="popupDv">
                                <h4>Full Name</h4>
                                <h5>Ahmed Fahad Mohammad Saud</h5>
                            </div>
                        </Col>

                        <Col lg="5" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Other</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-4">
                            <div className="popupDv">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your comment" />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Button className="btn-white me-3" onClick={props.onConfirm}>Ban</Button>

                            <Button onClick={props.onHide}>Cancel</Button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>

    );
}

function MyConfirmModal(props) {

    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm">
            <Modal.Header className="d-flex justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter" >
                    Are you sure you want to ban this user?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmBtns d-flex justify-content-center">
                    <Button className="btn-white me-3" onClick={props.onConfirm}>Yes</Button>

                    <Button onClick={props.onHide}>Cancel</Button>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}

export default INFO