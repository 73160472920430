
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";
import { showPendingApplicationDetails, getPendingApplicationsDetails } from "../../../features/applications-management/pendingApplicationDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const PendingApplicationDetail = (props) => {
    console.log("Propsssssssssssssssssss", props);

    console.log("Here in pending application detail", props.id);
    const { id } = props
    // const [id, setId] = useState(5)
    const dispatch = useDispatch();
    const pendingApplicationDetailsSlice = useSelector(getPendingApplicationsDetails)

    const pendingApplicationDetails = pendingApplicationDetailsSlice?.pendingApplicationDetails;

    // console.log("Here are the Pending Applications Details Slice", pendingApplicationDetailsSlice);


    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showPendingApplicationDetails(params));
        })()
    }, []);
    console.log("Here are the Pending Applications Details Slice", pendingApplicationDetailsSlice);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pending Loan Appliaction Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row className="mb-4">
                        <Col lg="10">
                            {pendingApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!pendingApplicationDetailsSlice?.loading && pendingApplicationDetailsSlice?.error ? <div>Error: {pendingApplicationDetailsSlice?.error}</div> : null}
                            {!pendingApplicationDetailsSlice?.loading && pendingApplicationDetails ? (
                                <Row>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Application ID</h4>
                                            <h5>{pendingApplicationDetails?.applicationId ? pendingApplicationDetails?.applicationId : "N/A"}</h5>

                                        </div>
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Display ID</h4>
                                            <h5>{pendingApplicationDetails?.displayId ? pendingApplicationDetails?.displayId : "N/A"}</h5>

                                        </div>
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower ID</h4>
                                            <h5>{pendingApplicationDetails?.borrowerIdNumber ? pendingApplicationDetails?.borrowerIdNumber : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Duration</h4>
                                            {
                                                pendingApplicationDetails?.duration ?
                                                    <><h5>{pendingApplicationDetails?.duration} Months</h5></>
                                                    :
                                                    <><h5>N/A</h5></>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Profit Rate</h4>
                                            <h5>{pendingApplicationDetails?.profitRate ? pendingApplicationDetails?.profitRate : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Created on</h4>
                                            <div className="dateTime">
                                                <h5>{pendingApplicationDetails?.createdOnDate ? formatDate(pendingApplicationDetails?.createdOnDate) : "N/A"}</h5>
                                                <span className="time">{pendingApplicationDetails?.createdOnDate ? formatTime(pendingApplicationDetails?.createdOnDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Req Status</h4>
                                            <h5>{pendingApplicationDetails?.reqStatus ? pendingApplicationDetails?.reqStatus : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>No. of Installments</h4>
                                            <h5>{pendingApplicationDetails?.noOfInstallments ? pendingApplicationDetails?.noOfInstallments : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded</h4>
                                            <h5>{pendingApplicationDetails?.funded ? pendingApplicationDetails?.funded : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Application Expiry</h4>
                                            <div className="dateTime">
                                                <h5>{pendingApplicationDetails?.applicationExpiryDate ? formatDate(pendingApplicationDetails?.applicationExpiryDate) : "N/A"}</h5>
                                                <span className="time">{pendingApplicationDetails?.applicationExpiryDate ? formatTime(pendingApplicationDetails?.applicationExpiryDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Amount</h4>
                                            {
                                                pendingApplicationDetails?.loanAmount ?
                                                    <>
                                                        <h5>SAR {formattedNumber(pendingApplicationDetails?.loanAmount)}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }

                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Per Installment</h4>
                                            {
                                                pendingApplicationDetails?.perInstallmentAmount ?
                                                    <>
                                                        <h5>SAR {pendingApplicationDetails?.perInstallmentAmount}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded Amount</h4>
                                            {
                                                pendingApplicationDetails?.fundedAmount ?
                                                    <>
                                                        <h5>SAR {pendingApplicationDetails?.fundedAmount}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower Name</h4>
                                            <h5>{pendingApplicationDetails?.borrowerName ? pendingApplicationDetails?.borrowerName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Reason</h4>
                                            <h5>{pendingApplicationDetails?.loanReason ? pendingApplicationDetails?.loanReason : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Total Repay Amount</h4>
                                            {
                                                pendingApplicationDetails?.totalRepayAmount ?
                                                    <>
                                                        <h5>SAR {pendingApplicationDetails?.totalRepayAmount}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3"></Col>

                                    <Col lg="3"></Col>
                                </Row>
                            )
                                : null
                            }

                        </Col>
                    </Row>

                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </>


    );
}

export default PendingApplicationDetail;
