import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_ANALYTICS;
// + process.env.REACT_APP_USER_ANALYTICS

;

export const showPendingRegApprAnalytics = createAsyncThunk(
    "pendingRegAppr/analytics",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // console.log("Pending Reg Appr Counter Slice - URL & Params:", URL);
        // const response = await getAPI(URL, params);
        // console.log("Pending Reg Appr Slice - response:", response);
        const analyticsPayload = await response.data.data.analytics;
        // console.log("Pending Reg Appr Slice - payload:", analyticsPayload);

        return analyticsPayload;
    }
);

const pendingRegApprAnalyticsSlice = createSlice({
    name: "pendingRegApprAnalytics",
    initialState: {
        loading: false,
        analytics: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showPendingRegApprAnalytics.pending, (state) => {
            state.loading = true;
            state.analytics = null;
            state.error = null;
        });
        builder.addCase(showPendingRegApprAnalytics.fulfilled, (state, action) => {
            state.loading = false;
            state.analytics = action.payload;
            state.error = null;
        });
        builder.addCase(showPendingRegApprAnalytics.rejected, (state, action) => {
            state.loading = false;
            state.analytics = null;
            state.error = action.error.message;
        });
    },
});
export const getPendingRegApprAnalytics = (state) => state.pendingRegApprAnalytics;
export default pendingRegApprAnalyticsSlice.reducer;
