import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";

const useAuth = () => {
    const [permissions, setPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const checkPermission = ({ subject, action }) => {
        let isAuthorized = false;
        for (let permission of permissions) {
            console.log(permission, subject, action)
            if (subject === permission?.subject && action === permission?.action) {
                isAuthorized = true;
                break;
            }
        }
        return isAuthorized;
    }
    const checkRole = ({ role }) => {
        let isAuthorized = false;
        for (let r of roles) {
            if (role === r?.name) {
                isAuthorized = true;
                break;
            }
        }
        return isAuthorized;
    }
    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        if (decoded.permissions) {
            setPermissions(decoded.permissions);
        }
        if (decoded.roles) {
            setRoles(decoded.roles)
        }
    }, []);

    return [permissions, checkPermission, checkRole];
}

export default useAuth;


