import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_BORROWER_LOAN_APPLICATIONS;


export const showloanApplications = createAsyncThunk(
    "borrower/loanApplications",
    async (data) => {
        // console.log("Active Applications Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // console.log("Lender Opportunities Slice - response:", response);
        const applications = await response.data.data;
        // console.log("Lender Opportunities Slice - payload:", applications);

        return applications;
    }
);

const loanApplicationsSlice = createSlice({
    name: "borrowerLoanApplications",
    initialState: {
        loading: false,
        applications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showloanApplications.pending, (state) => {
            state.loading = true;
            state.applications = null;
            state.error = null;
        });
        builder.addCase(showloanApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.applications = action.payload;
            state.error = null;
        });
        builder.addCase(showloanApplications.rejected, (state, action) => {
            state.loading = false;
            state.applications = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getLoanApplications = (state) => state.borrowerLoanApplications;
export default loanApplicationsSlice.reducer;
