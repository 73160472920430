import React from "react";
// import { useState } from 'react';
import "../../assets/css/dashboard/dashboard.css";
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import Button from 'react-bootstrap/Button';
import Accordion from "react-bootstrap/Accordion";
// import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import HEADER from "../../component/header/header";
import BREADCRUMB from "../../component/breadcrumb/breadcrumb";
import WELCOME from "../../component/welcome/welcome";
import COUNTER from "../../component/counter/counter";
import SIDEBAR from "../../component/sidebar/sidebar";


// import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

// import Table from 'react-bootstrap/Table';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';s
// import Table from 'react-bootstrap/Table';
// import Dropdown from 'react-bootstrap/Dropdown';

// import mada from "../../assets/images/mada.png"
// import lendinglogo from "../../assets/images/lending-logo.png"
// import master from "../../assets/images/master.png"

const DASHBOARD = () => {
    //     const [show, setShow] = useState(false);

    //   const handleClose = () => setShow(false);
    //   const handleShow = () => setShow(true);
    // const navigate = useNavigate();



    return (
        <>
            <div className="dashoboard">

                <SIDEBAR />

                <div className="component">
                    <HEADER />
                    <BREADCRUMB />
                    {/* <COUNTER />
                    <WELCOME /> */}

                </div>
            </div>
        </>
    );
};

export default DASHBOARD;
