import React, { useEffect, useState } from "react";

// Elements
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";

import { useDispatch, useSelector } from "react-redux";
import {
  showAllWalletInfo,
  getAllWalletInfo,
} from "../../../features/wallet-management/allWalletInfoSlice";
import {
  formatDate,
  formatTime,
} from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { api, postAPI } from "../../../api/apiCallMethods";
import { patchAPI } from "../../../api/apiCallMethods";
import { getUserRoleData } from "../../../utilities/helper/getUserRoleData";
import useAuth from "../../../hooks/permission-hook";

const INFO = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");

  // console.log("Reason", reason);

  const openConfirmModal = () => {
    setConfirmModalShow(true); // Open the confirmation modal
  };

  const handleBan = async () => {
    // await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_DETAILS, {
    //     changedBy: info?.fullName,
    //     changedByUserId: useRoleData.userId,
    //     changedReason: reason,
    //     comment: comments,
    //     userId: info?.idNumber
    // });
    console.log(
      "hit url",
      process.env.REACT_APP_WALLET_BASE_URL +
      "/ewallet/v1/admin/virtual-account/changeStatus"
    );
    // await patchAPI(process.env.REACT_APP_WALLET_BASE_URL + "/ewallet/v1/admin/virtual-account/changeStatus", {
    await patchAPI(
      "https://dev.thelendinghub.sa/ewallet/v1/admin/virtual-account/changeStatus",
      {
        changedBy: "info?.fullName",
        changedByUserId: useRoleData.userId,
        changedReason: reason,
        comment: comments,
        userId: "22",
      }
    );

    setModalShow(false); // Close the first modal
    setConfirmModalShow(false); // Close the confirmation modal
    setReason("");
    setComments("");
    dispatch(showAllWalletInfo(params));
  };

  const handleCancel = () => {
    setConfirmModalShow(false); // Close only the confirmation modal
  };

  const [userId, setUserId] = useState(useParams().id);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filterType, setfilterType] = useState(undefined);
  const [filterValue, setfilterValue] = useState(undefined);

  const params = {
    userId,
    limit,
    page,
    orderBy,
    sortType,
    filterType,
    filterValue,
  };
  const dispatch = useDispatch();
  const allWalletInfoSlice = useSelector(getAllWalletInfo);
  const info = allWalletInfoSlice?.info;

  // console.log("Here in info, will be displauing the ==info slice== for all wallets", allWalletInfoSlice);

  // console.log("Here in info, will be displauing the ==info== for all wallets", info);

  useEffect(() => {
    (async () => {
      await dispatch(showAllWalletInfo(params));
    })();
  }, [userId, limit, page, orderBy, sortType, filterType, filterValue]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleActionWallet = async () => {
    // await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_DETAILS, {
    //     userId: userId
    // });
    await api({
      method: "POST",
      url:
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ALL_WALLETS_DETAILS,
      data: {
        userId,
      },
    });
    dispatch(showAllWalletInfo(params));
    setShow(false);
  };

  // User Roles
  const [useRoleData, setuseRoleData] = useState([]);
  const [useRoleId, setuseRoleId] = useState();

  useEffect(() => {
    // Retrieve data from localStorage
    const storedUserData = localStorage.getItem("userRoles");
    const storedUserId = localStorage.getItem("userRoleId");

    setuseRoleId(storedUserId);

    if (storedUserData) {
      // Parse the stored data
      const parsedUserData = JSON.parse(storedUserData);
      // Find the object with the specified role ID
      const filteredRole = parsedUserData.find(
        (role) => role.roleId === useRoleId
      );

      if (filteredRole) {
        // If a role with the specified ID is found, set the state with that role object
        setuseRoleData(filteredRole);
      } else {
        // If no role with the specified ID is found
        console.log("No role data found for the specified role ID.");
      }
    }
  }, [useRoleId]);

  const [permissions, checkPermission]  = useAuth();

  return (
    <>
      <div className="borrowerInfoBox">
        <div className="borrowerInfo">
          <Row>
            <Col xl="9">
              <Row>
                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Created On</h5>
                    <h4>
                      <span className="date">
                        {info?.createdAt ? formatDate(info?.createdAt) : "N/A"}
                      </span>
                      <span className="time">
                        {info?.createdAt ? formatTime(info?.createdAt) : ""}
                      </span>
                    </h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>IBAN</h5>
                    <h4>{info?.viban ? info?.viban : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Holder ID</h5>
                    <h4>{info?.idNumber ? info?.idNumber : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="3">
                  <div className="infoBox">
                    <h5>Holder Name</h5>
                    <h4>{info?.holderName ? info?.holderName : "N/A"}</h4>
                  </div>
                </Col>

                <Col lg="4" xl="1">
                  <div className="infoBox">
                    <h5>Status</h5>
                    <h4 className="status">
                      {info?.active == "true" ? <>Active</> : <>Inactive</>}
                    </h4>
                  </div>
                </Col>

                <Col lg="4" xl="2">
                  <div className="infoBox">
                    <h5>Wallet Balance</h5>
                    <h4>
                      SAR{" "}
                      {info?.balance ? formattedNumber(info.balance) : "N/A"}{" "}
                    </h4>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl="2"></Col>
            { checkPermission({subject:"wallet management", action:"block/unblock"})  ? (
              <>
                <Col xl="1">
                  <div className="btnBox">
                    <Button
                      variant="primary"
                      onClick={() => setModalShow(true)}
                    >
                      {info?.active == "true" ? (
                        <>Block Wallet</>
                      ) : (
                        <>Unblock Wallet</>
                      )}
                    </Button>

                    <MyVerticallyCenteredModal
                      show={modalShow}
                      userId={info?.idNumber ? info?.idNumber : "N/A"}
                      fullName={info?.fullName ? info?.fullName : "N/A"}
                      status={info?.accountStatus}
                      onHide={() => setModalShow(false)}
                      onConfirm={openConfirmModal}
                      reason={reason}
                      setReason={setReason}
                      comments={comments}
                      setComments={setComments}
                    />

                    <MyConfirmModal
                      show={confirmModalShow}
                      onHide={() => setConfirmModalShow(false)}
                      onConfirm={handleBan}
                      onCancel={handleCancel}
                      status={info?.accountStatus}
                    />
                  </div>
                </Col>
              </>
            ) : null}
          </Row>
        </div>

        {/* <Modal  size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm" show={show} onHide={handleClose}>
                    <Modal.Header className="d-flex justify-content-center">
                        <Modal.Title id="contained-modal-title-vcenter" >
                            Are you sure you want to ban this user?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="confirmBtns d-flex justify-content-center">
                            <Button className="btn-white me-3" onClick={handleActionWallet}>Yes</Button>

                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal> */}
      </div>
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  const { userId, fullName, status, reason, setReason, comments, setComments } =
    props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="banForm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to{" "}
          {status
            ? status.toLowerCase() !== "banned"
              ? "ban"
              : "unban"
            : null}{" "}
          this user?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Row>
            <Col lg="3" className="mb-4">
              <div className="popupDv">
                <h4>User ID</h4>
                <h5>{userId ? userId : "N/A"}</h5>
                {/* <h5>0152150111</h5> */}
              </div>
            </Col>
            <Col lg="9" className="mb-4">
              <div className="popupDv">
                <h4>Full Name</h4>
                <h5>{fullName ? fullName : "N/A"}</h5>
              </div>
            </Col>

            <Col lg="5" className="mb-4">
              <div className="popupDv">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reason</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option>Other</option>
                    <option value="One">One</option>
                    <option value="Two">Two</option>
                    <option value="Three">Three</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>

            <Col lg="12" className="mb-4">
              <div className="popupDv">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your comment"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Button className="btn-white me-3" onClick={props.onConfirm}>
                {/* {status ? (status.toLowerCase() !== "banned" ? "Ban" : "Unban") : null} */}
                Block
              </Button>

              <Button onClick={props.onHide}>Cancel</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

function MyConfirmModal(props) {
  const { status } = props;
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="banForm confirm"
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to{" "}
          {status
            ? status.toLowerCase() !== "banned"
              ? "ban"
              : "unban"
            : null}{" "}
          this user?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="confirmBtns d-flex justify-content-center">
          <Button className="btn-white me-3" onClick={props.onConfirm}>
            Yes
          </Button>

          <Button onClick={props.onHide}>Cancel</Button>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default INFO;
