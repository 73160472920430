import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_PERMISSIONS_BY_SUBJECT;

export const listPermissions = createAsyncThunk(
    "subject/permissions",
    async (data) => {
        // // console.log("Admin Users List Slice - URL & Params:", URL, params);
        // // const response = await getAPI(URL, params)
        // const response = await getAPI(URL, params);
        // // const adminUsersPayload = await response.data.data.;
        // // console.log("Admin Users List Slice - response:", response);
        const response = await postAPI(URL, data);
        const adminPermissionsPayload = await response.data.data;
        console.log(adminPermissionsPayload)
        // console.log("Admin Users List Slice - payload:", adminUsersPayload);
        return adminPermissionsPayload;
    }
);

const listPermissionsSlice = createSlice({
    name: "listPermissions",
    initialState: {
        loading: false,
        permissions: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPermissions.pending, (state) => {
            state.loading = true;
            state.permissions = null;
            state.error = null;
        });
        builder.addCase(listPermissions.fulfilled, (state, action) => {
            state.loading = false;
            state.permissions = action.payload;
            state.error = null;
        });
        builder.addCase(listPermissions.rejected, (state, action) => {
            state.loading = false;
            state.permissions = null;
            state.error = action.error.message;
        });
    },
});

export const getListPermissions = (state) => state.adminPermissionsList;
export default listPermissionsSlice.reducer;

