import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_LOANS_LISTING;

export const listActiveLoans = createAsyncThunk(
    "activeLoans/list",
    async (data) => {

        // console.log("List Active Loans Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const activeLoansPayload = await response.data.data;
        // console.log("List Active Loans Slice - response:", response);
        // const activeLoansPayload = await response.data;
        // console.log("List Active Loans Slice - payload:", activeLoansPayload);
        return activeLoansPayload;
    }
);

const activeLoansSlice = createSlice({
    name: "activeLoans",
    initialState: {
        loading: false,
        activeLoans: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listActiveLoans.pending, (state) => {
            state.loading = true;
            state.activeLoans = null;
            state.error = null;
        });
        builder.addCase(listActiveLoans.fulfilled, (state, action) => {
            state.loading = false;
            state.activeLoans = action.payload;
            state.error = null;
        });
        builder.addCase(listActiveLoans.rejected, (state, action) => {
            state.loading = false;
            state.activeLoans = null;
            state.error = action.error.message;
        });
    },
});

export const getActiveLoansList = (state) => state.activeLoans;
export default activeLoansSlice.reducer;
