import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_ANALYTICS

    ;

export const showAdminAnalytics = createAsyncThunk(
    "admin/analytics",
    async (params) => {
        // console.log("Admin Counter Slice - URL & Params:", URL);
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // const response = await getAPI(URL, params);
        // console.log("Admin Counter Slice - response:", response);
        const analyticsPayload = await response.data.data.analytics;
        // console.log("Admin Counter Slice - payload:", analyticsPayload);

        return analyticsPayload;
    }
);

const adminAnalyticsSlice = createSlice({
    name: "adminAnalytics",
    initialState: {
        loading: false,
        analytics: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showAdminAnalytics.pending, (state) => {
            state.loading = true;
            state.analytics = null;
            state.error = null;
        });
        builder.addCase(showAdminAnalytics.fulfilled, (state, action) => {
            state.loading = false;
            state.analytics = action.payload;
            state.error = null;
        });
        builder.addCase(showAdminAnalytics.rejected, (state, action) => {
            state.loading = false;
            state.analytics = null;
            state.error = action.error.message;
        });
    },
});
export const getAdminAnalytics = (state) => state.adminAnalytics;
export default adminAnalyticsSlice.reducer;
