import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LENDER_LISTING;

export const listLender = createAsyncThunk(
    "lender/listlender",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // console.log("List Lender Slice - URL & Params:", URL, params);
        // const response = await getAPI(URL, params)
        // console.log("List Lender Slice - response:", response);
        const lenderPayload = await response.data.data;
        // console.log("List Lender Slice - payload:", lenderPayload);

        return lenderPayload;
    }
);

const listLenderSlice = createSlice({
    name: "listLender",
    initialState: {
        loading: false,
        lenders: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listLender.pending, (state) => {
            state.loading = true;
            state.lenders = null;
            state.error = null;
        });
        builder.addCase(listLender.fulfilled, (state, action) => {
            state.loading = false;
            state.lenders = action.payload;
            state.error = null;
        });
        builder.addCase(listLender.rejected, (state, action) => {
            state.loading = false;
            state.lenders = null;
            state.error = action.error.message;
        });
    },
});
export const getLenderList = (state) => state.lender;

export default listLenderSlice.reducer;
