import React from "react";
// import { useState, useEffect } from "react";

const COUNTER = () => {

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Lender</h6>
                        <h1>3000</h1>
                    </div>

                    <div className="countBox">
                        <h6>Active Lenders</h6>
                        <h1>2500</h1>
                    </div>
                    
                    <div className="countBox">
                        <h6>Sanctioned</h6>
                        <h1>45</h1>
                    </div>
                    
                    <div className={`countBox `}>
                        <h6>Banned</h6>
                        <h1>10</h1>
                    </div>
                    
                    <div className={`countBox`}>
                        <h6>PEP Type</h6>
                        <h1>50</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default COUNTER