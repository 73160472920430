import React, { useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { ColorRing } from 'react-loader-spinner';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const ACTIVATELAYOUT = () => {
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState("");
  let isTokenValid = false;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('t');
  console.log("==========================", { token }, "==========================")
  let decoded = undefined;

  const isValidJWT = (token) => {
    // Regular expression to match JWT token format
    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;

    return jwtRegex.test(token);
  };

  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    } else {
      if (isValidJWT(token)) {
        console.log("==============This is a validated jwt token==============")
        console.log(token)
        console.log("=========================================================")
        decoded = jwtDecode(token);
        console.log("==============This is a decoded jwt token==============")
        console.log({ decoded });
        console.log("=========================================================")
      }
      else {
        // navigate("/", { replace: true });
      }
    }


    if (decoded && (decoded.exp > decoded.iat)) {
      (async () => {
        try {
          const response = await axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_ACTIVATE_USER,
            headers: {
              "ignore_encryption": true
            },
            data: {
              token
            }
          });
          // console.log("==========: Response in Activate Layout :==========")
          // console.log(response.data);
          if (response.data.code === 200) {
            // console.log("User activate successfully",{state: {token:response.data.data.token}});
            navigate("/auth-password", { replace: true, state: { token: response.data.data.token } })
          }
          if (response.data.code === 400) {
            throw new Error(response.data.message);
          }
        } catch (error) {
          navigate("/", { replace: true });
          console.error(error);
        }
      })()
    } else {
      console.log("The token has been expired.")
      navigate("/", { replace: true });
    }
  }, [])



  return (
    <>
      {loading && <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperclassName="color-ring-wrapper"
        colors={["#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089", "#270089"]}
      />}

    </>
  )
}

export default ACTIVATELAYOUT