
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";
import { showRejectedApplicationDetails, getRejectedApplicationDetails } from "../../../features/applications-management/rejectedApplicationDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const RejectedApplicationDetail = (props) => {

    const { id } = props
    // const [id, setId] = useState(5)
    const dispatch = useDispatch();
    const rejectedApplicationDetailsSlice = useSelector(getRejectedApplicationDetails)
    const rejectedApplicationDetails = rejectedApplicationDetailsSlice?.rejectedApplicationDetails;

    // console.log("This is the id in rejected loan application:", id)
    // console.log("Here are the Rejected Applications Details Slice", rejectedApplicationDetailsSlice);
    // console.log("Here are the Rejected Applications Details", rejectedApplicationDetails);

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showRejectedApplicationDetails(params));
        })()
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rejected Loan Application Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {rejectedApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                    {!rejectedApplicationDetailsSlice?.loading && rejectedApplicationDetailsSlice?.error ? <div>Error: {rejectedApplicationDetailsSlice?.error}</div> : null}
                    {!rejectedApplicationDetailsSlice?.loading && rejectedApplicationDetails ? (
                        <Row>

                            {/* 1 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Application ID</h4>
                                    <h5>{id ? id : "N/A"}</h5>
                                </div>
                            </Col>
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Display ID</h4>
                                    <h5>{rejectedApplicationDetails?.displayId ? rejectedApplicationDetails?.displayId : "N/A"}</h5>
                                </div>
                            </Col>
                            {/* 2 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Borrower ID</h4>
                                    <h5>{rejectedApplicationDetails?.borrowerIdNumber ? rejectedApplicationDetails?.borrowerIdNumber : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 3 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Duration</h4>
                                    {
                                        rejectedApplicationDetails?.duration ?
                                            <><h5>{rejectedApplicationDetails?.duration} Months</h5></>
                                            :
                                            <><h5>N/A</h5></>
                                    }
                                </div>
                            </Col>

                            {/* 4 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Profit Rate</h4>
                                    <h5>{rejectedApplicationDetails?.profitRate ? rejectedApplicationDetails?.profitRate : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 5 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Created on</h4>
                                    <div className="dateTime">
                                        <h5>{rejectedApplicationDetails?.createdOnDate ? formatDate(rejectedApplicationDetails?.createdOnDate) : "N/A"}</h5>
                                        <span className="time">{rejectedApplicationDetails?.createdOnDate ? formatTime(rejectedApplicationDetails?.createdOnDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>

                            {/* 6 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Req Status</h4>
                                    <h5>{rejectedApplicationDetails?.reqStatus ? rejectedApplicationDetails?.reqStatus : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 7 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>No. of Installments</h4>
                                    <h5>{rejectedApplicationDetails?.noOfInstallments ? rejectedApplicationDetails?.noOfInstallments : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 8 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Rejection Date</h4>
                                    <div className="dateTime">
                                        <h5>{rejectedApplicationDetails?.rejectionDate ? formatDate(rejectedApplicationDetails?.rejectionDate) : "N/A"}</h5>
                                        <span className="time">{rejectedApplicationDetails?.rejectionDate ? formatTime(rejectedApplicationDetails?.rejectionDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>



                            {/* 9 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Application Expiry</h4>
                                    <div className="dateTime">
                                        <h5>{rejectedApplicationDetails?.applicationExpiryDate ? formatDate(rejectedApplicationDetails?.applicationExpiryDate) : "N/A"}</h5>
                                    </div>
                                </div>
                            </Col>

                            {/* 10 */}

                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Amount</h4>
                                    {
                                        rejectedApplicationDetails?.loanAmount ?
                                            <>
                                                <h5>SAR {formattedNumber(rejectedApplicationDetails?.loanAmount)}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>


                            {/* 11 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Per Installment</h4>
                                    {
                                        rejectedApplicationDetails?.perInstallmentAmount ?
                                            <>
                                                <h5>SAR {formattedNumber(rejectedApplicationDetails?.perInstallmentAmount)}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>


                            {/* 12 */}

                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Rejection Reason</h4>
                                    <h5>{rejectedApplicationDetails?.rejectionReason ? rejectedApplicationDetails?.rejectionReason : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 13 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Borrower Name</h4>
                                    <h5>{rejectedApplicationDetails?.borrowerName ? rejectedApplicationDetails?.borrowerName : "N/A"}</h5>
                                </div>
                            </Col>

                            {/* 14 */}
                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Reason</h4>
                                    <h5>{rejectedApplicationDetails?.loanReason ? rejectedApplicationDetails?.loanReason : "N/A"}</h5>
                                </div>
                            </Col>


                            {/* 15 */}

                            <Col lg="3" className="mb-2">
                                <div className="popupDv">
                                    <h4>Total Repay Amount</h4>
                                    {
                                        rejectedApplicationDetails?.totalRepayAmount ?
                                            <>
                                                <h5>SAR {formattedNumber(rejectedApplicationDetails?.totalRepayAmount)}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                        </Row>
                    )
                        : null
                    }

                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </>


    );
}

export default RejectedApplicationDetail;
