import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_PENDING_APPLICATIONS_LISTING;

export const listPendingApplications = createAsyncThunk(
    "pendingApplication/listPendingApplication",
    async (data) => {

        // console.log("List Pending Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const pendingApplicationsPayload = await response.data.data;
        console.log("List Pending Applications List Slice - response:", pendingApplicationsPayload);
        // const pendingApplicationsPayload = await response.data;
        // console.log("List Pending Applications List Slice - payload:", pendingApplicationsPayload);
        return pendingApplicationsPayload;
    }
);

const listPendingApplicationsSlice = createSlice({
    name: "listPendingApplications",
    initialState: {
        loading: false,
        pendingApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listPendingApplications.pending, (state) => {
            state.loading = true;
            state.pendingApplications = null;
            state.error = null;
        });
        builder.addCase(listPendingApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listPendingApplications.rejected, (state, action) => {
            state.loading = false;
            state.pendingApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getPendingApplicationsList = (state) => state.pendingApplicationsList;
export default listPendingApplicationsSlice.reducer;
