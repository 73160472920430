import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_COMPLETED_LOANS_LISTING;

export const listCompletedLoans = createAsyncThunk(
    "completedLoans/listCompletedLoans",
    async (data) => {

        // console.log("List Completed Loans Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data)
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const completedLoansPayload = await response.data.data;
        // console.log("List Completed Loans Slice - response:", response);
        // const completedLoansPayload = await response.data;
        // console.log("List Completed Loans Slice - payload:", completedLoansPayload);
        return completedLoansPayload;
    }
);

const listCompletedLoansSlice = createSlice({
    name: "listCompletedLoans",
    initialState: {
        loading: false,
        completedLoans: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listCompletedLoans.pending, (state) => {
            state.loading = true;
            state.completedLoans = null;
            state.error = null;
        });
        builder.addCase(listCompletedLoans.fulfilled, (state, action) => {
            state.loading = false;
            state.completedLoans = action.payload;
            state.error = null;
        });
        builder.addCase(listCompletedLoans.rejected, (state, action) => {
            state.loading = false;
            state.completedLoans = null;
            state.error = action.error.message;
        });
    },
});

export const getCompletedLoansList = (state) => state.completedLoans;
export default listCompletedLoansSlice.reducer;
