import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BORROWER_LISTING;

export const listBorrower = createAsyncThunk(
    "borrower/list",
    async (params) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            params
        };
        const response = await api(reqConfig);
        // console.log("List Borrower Slice - URL & Params:", URL, params);
        // const response = await getAPI(URL, params)
        // console.log("List Borrower Slice - response:", response);
        const borrowersPayload = await response.data.data;
        // console.log("List Borrower Slice - payload:", borrowersPayload);
        return borrowersPayload;
    }
);

const listBorrowerSlice = createSlice({
    name: "borrowers",
    initialState: {
        loading: false,
        borrowers: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listBorrower.pending, (state) => {
            state.loading = true;
            state.borrowers = null;
            state.error = null;
        });
        builder.addCase(listBorrower.fulfilled, (state, action) => {
            state.loading = false;
            state.borrowers = action.payload;
            state.error = null;
        });
        builder.addCase(listBorrower.rejected, (state, action) => {
            state.loading = false;
            state.borrowers = null;
            state.error = action.error.message;
        });
    },
});

export const getBorrowerList = (state) => state.borrower;
export default listBorrowerSlice.reducer;
