import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_LOANS_DETAILS;

export const showCompletedLoanDetails = createAsyncThunk(
    "completedLoan/showCompletedLoanDetails",
    async (data) => {

        // console.log("Completed Loan Details Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Completed Loan Details Slice - response:", response);
        const completedLoanDetailsPayload = await response.data.data;
        // const completedLoanDetailsPayload = await response.data;
        console.log("Completed Loan Details Slice - payload:", completedLoanDetailsPayload);

        return completedLoanDetailsPayload;
    }
);

const showCompletedLoanDetailsSlice = createSlice({
    name: "showCompletedLoanDetailsSlice",
    initialState: {
        loading: false,
        completedLoanDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showCompletedLoanDetails.pending, (state) => {
            state.loading = true;
            state.completedLoanDetails = null;
            state.error = null;
        });
        builder.addCase(showCompletedLoanDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.completedLoanDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showCompletedLoanDetails.rejected, (state, action) => {
            state.loading = false;
            state.completedLoanDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getCompletedLoanDetails = (state) => state.completedLoanDetails;
export default showCompletedLoanDetailsSlice.reducer;
