import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { api, postAPI } from "../../../api/apiCallMethods";
import { jwtDecode } from "jwt-decode";
import { BsCheckCircle, BsCheckCircleFill, BsDashCircle, BsDashCircleFill, BsXCircle, BsXCircleFill } from "react-icons/bs";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";

import { useDispatch, useSelector } from "react-redux";
import { getNotifications, showNotifications } from "../../../features/borrower/notificationSlice";


const MANUALNOTIFICATION = () => {

    const [id, setId] = useState(useParams().id);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [totalPages, setTotalPages] = useState();

    const reqConfig = {
        method: "POST",
        url: process.env.REACT_APP_NOTIFICATION_BASE_URL + process.env.REACT_APP_ADMIN_GET_MANUAL_NOTIFICATION,
        params: {
            limit,
            page
        },
        data: {
            userId: id
        }
    }
    const dispatch = useDispatch();
    const notificationsSlice = useSelector(getNotifications);
    const notificationList = notificationsSlice?.notifications?.notificationList;

    // console.log({ notificationsSlice });
    // console.log({ notificationList });


    useEffect(() => {

        (async () => {
            await dispatch(showNotifications(reqConfig));
        })()


    }, [limit, page]
    );


    const handlePageNumber = (e) => {
        const value = e.currentTarget.value

        if (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= totalPages) {
            setPage(value);
        }
    }


    const handlPrevClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) >= 1
            //  && Number(value) <= totalPages
        ) {
            if (Number(value) === 1) {
                setPage(1)
            } else {
                setPage(state => Number(state) - 1)
            }
        }
    }

    const handlNextClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) < totalPages && Number(value) >= 1) {
            setPage(state => Number(state) + 1)
            // if (Number(value) === totalPages) {
            //   setPage(totalPages)
            // }
        }
    }

    useEffect(() => {
        setTotal(notificationsSlice?.notifications?.total);
        setTotalPages(notificationsSlice?.notifications?.totalPages);
    }, [notificationsSlice])





    return (
        <>

            <div className="content">
                <div className="optionsBox">
                    <Row>
                        <Col lg="12">
                            <div className="pagination">
                                {/* <span className="totalRecord me-3">2000 Records</span> */}
                                <span className="totalRecord me-3">{total ? total : "N/A"} Records</span>
                                <Form.Control
                                    className=""
                                    id="pageCount"
                                    placeholder=""
                                    value={page}
                                    // value={1}
                                    onChange={handlePageNumber}

                                />
                                <span className="sep">- </span>
                                <span className="showRecord">{totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;</span>
                                <span className="prePage" style={{ cursor: "pointer" }} onClick={handlPrevClick}>
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="25"
                                            height="25"
                                            rx="12.5"
                                            stroke="#696969"
                                        />
                                        <g clipPath="url(#clip0_0_1)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z"
                                                fill="#696969"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_1">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className="nextPage" style={{ cursor: "pointer" }}
                                    onClick={handlNextClick} >
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="25"
                                            height="25"
                                            rx="12.5"
                                            stroke="#696969"
                                        />
                                        <g clipPath="url(#clip0_0_1)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z"
                                                fill="#696969"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_0_1">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="translate(4 22) rotate(-90)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>

                                {/* <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span> */}
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp">
                    <Table responsive>
                        <thead>
                            <tr>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Sent Date</span>
                                    </div>
                                </th>

                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Notification Title</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Notification Message</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Push</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">SMS</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="labelIco">
                                        <span className="headTitle">Email</span>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {notificationsSlice.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!notificationsSlice.loading && notificationsSlice.error ? <div className="notifyNoRecord">Error: {notificationsSlice.error}</div> : null}

                            {
                                !notificationsSlice.loading && notificationList?.length !== 0 ? notificationList?.map((notification, index) => (

                                    <tr key={index}>
                                        <td>
                                            <div className="dateTime">
                                                <span className="date">{notification.createdAt ? formatDate(notification.createdAt) : "N/A"}</span><br />
                                                <span className="time">{notification.createdAt ? formatTime(notification.createdAt) : ""}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="device">
                                                <span>{notification.title}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="status">
                                                <span>{notification.message}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "center", paddingTop: "20px" }}>

                                            {
                                                notification.sendPush === "1"
                                                    ?
                                                    (
                                                        notification.isPushSent === "1"
                                                            ? <BsCheckCircleFill size={22} color="#270089" />
                                                            : <BsXCircleFill size={22} color="#ff0f0f" />
                                                    )
                                                    : null
                                            }
                                        </td>
                                        <td style={{ textAlign: "center", paddingTop: "20px" }}>
                                            {
                                                notification.sendSms === "1"
                                                    ?
                                                    (
                                                        notification.isSmsSent === "1"
                                                            ? <BsCheckCircleFill size={22} color="#270089" />
                                                            : <BsXCircleFill size={22} color="#ff0f0f" />
                                                    )
                                                    : null
                                            }

                                        </td>
                                        <td style={{ textAlign: "center", paddingTop: "20px" }}>
                                            {
                                                notification.sendEmail === "1"
                                                    ?
                                                    (
                                                        notification.isEmailSent === "1"
                                                            ? <BsCheckCircleFill size={22} color="#270089" />
                                                            : <BsXCircleFill size={22} color="#ff0f0f" />
                                                    )
                                                    : null
                                            }
                                        </td>
                                    </tr>
                                ))
                                    : null
                            }

                        </tbody>
                    </Table>

                    {
                        !notificationsSlice.loading && !notificationsSlice.error && notificationList?.length === 0 ? <div className="notifyNoRecord" >No notifications exist!</div> : null
                    }
                </div>
            </div >

        </>
    )
}

export default MANUALNOTIFICATION