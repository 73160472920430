import { configureStore } from "@reduxjs/toolkit";

// auth user
import authUserReducer from "../features/authUserSlice";

// Borrower
import borrowerAnalyticsReducer from "../features/borrower/borrowerAnalyticsSlice";
import { listBorrowerReducer } from "../features";
import loanApplicationsReducer from "../features/borrower/loanApplicationsSlice";
import loansReducer from "../features/borrower/loansSlice";
import borrowerNotificationsReducer from "../features/borrower/notificationSlice"

// Lender
import lenderAnalyticsReducer from "../features/lender/lenderAnalyticsSlice";
import listLenderReducer from "../features/lender/listLenderSlice";
import opportunitiesReducer from "../features/lender/opportunintiesSlice";
import investmentReducer from "../features/lender/investmentsSlice";
import lenderNotificationsReducer from "../features/lender/notificationSlice"

// User
import userInfoReducer from "../features/user/infoSlice";
import profileDetailsReducer from "../features/user/profileSlice";
import otherDetailsReducer from "../features/user/otherDetailsSlice";
import showAccountDetailsReducer from "../features/user/accountDetailsSlice";
import listTransactionsHistoryReducer from "../features/user/listTransactionsHistorySlice";
import listLoginsHistoryReducer from "../features/user/listLoginsHistorySlice";

// Pep Details Managenent
import pendingRegApprAnalyticsReducer from "../features/pending-registeration-approvals/pendingRegApprAnalyticsSlice";
import listPepsReducer from "../features/pending-registeration-approvals/listPendingRegApprovalSlice";
import showPepDetailsReducer from "../features/pending-registeration-approvals/pendingRegApprovalDetailsSlice";

// Application Management
import listActiveApplicationsReducer from "../features/applications-management/listActiveApplicationsSlice";
import activeApplicationsDetailsReducer from "../features/applications-management/activeApplicationDetailsSlice";
import listPendingApplicationsReducer from "../features/applications-management/listPendingApplicationsSlice";
import pendingApplicationsDetailsReducer from "../features/applications-management/pendingApplicationDetailsSlice";
import listExpiredApplicationsReducer from "../features/applications-management/listExpiredApplicationsSlice";
import expiredApplicationsDetailsReducer from "../features/applications-management/expiredApplicationDetailsSlice";
import listCancelledApplicationsReducer from "../features/applications-management/listCancelledApplicationsSlice";
import cancelledApplicationsDetailsReducer from "../features/applications-management/cancelledApplicationDetailsSlice";
import listRejectedApplicationsReducer from "../features/applications-management/listRejectedApplicationsSlice";
import rejectedApplicationsDetailsReducer from "../features/applications-management/rejectedApplicationDetailsSlice";
import listPendingApplicationApprovalsReducer from "../features/applications-management/listPendingApplicationApprovalsSlice";
import pendingApplicationApprovalsDetailsReducer from "../features/applications-management/pendingApplicationApprovalsDetailsSlice";

// Loan Management
import loanInfoReducer from "../features/loan-management/loanInfoSlice";
import listActiveLoansReducer from "../features/loan-management/listActiveLoansSlice";
import activeLoansDetailsReducer from "../features/loan-management/activeLoanDetailsSlice";
import listCompletedLoansReducer from "../features/loan-management/listCompletedLoansSlice";
import completedLoansDetailsReducer from "../features/loan-management/completedLoanDetailsSlice";
import listDefaultedLoansReducer from "../features/loan-management/listDefaultedLoansSlice";
import defaultedLoansDetailsReducer from "../features/loan-management/defaultdLoanDetailsSlice";

// Wallet management
import listAllWalletsReducer from "../features/wallet-management/listAllWalletsSlice";
import allWalletInfoReducer from "../features/wallet-management/allWalletInfoSlice";
import allWalletStatusLogReducer from "../features/wallet-management/allWalletStatusLogSlice";
import listAllWalletsTransactionHistoryReducer from "../features/wallet-management/listAllWalletsTransactionHistorySlice";
import listPlatformWalletsReducer from "../features/wallet-management/listPlatformWalletsSlice";
import platformWalletInfoReducer from "../features/wallet-management/plaformWalletInfoSlice";
import platformWalletStatusLogReducer from "../features/wallet-management/platformWalletStatusLogSlice";
import listPlatformWalletsTransactionHistoryReducer from "../features/wallet-management/listPlatformWalletsTransactionHistorySlice";

// Admin Users
import adminAnalyticsReducer from "../features/admin-users/adminAnalyticsSlice";
import listAdminUsersReducer from "../features/admin-users/listAdminUsersSlice";
import adminUserDetailsReducer from "../features/admin-users/adminUserDetailsSlice";
import listAdminSubjectsReducer from "../features/role-management/listSubjectsSlice";
import listPermissionsReducer from "../features/role-management/listPermissionsSlice";


const store = configureStore({
  reducer: {
    // Admin Auth
    user: authUserReducer,

    // Borrower
    borrowerAnalytics: borrowerAnalyticsReducer,
    borrower: listBorrowerReducer,
    borrowerLoanApplications: loanApplicationsReducer,
    borrowerLoans: loansReducer,
    borrowerNotifications: borrowerNotificationsReducer,

    // Lenders
    lenderAnalytics: lenderAnalyticsReducer,
    lender: listLenderReducer,
    lenderOpportunities: opportunitiesReducer,
    lenderInvestments: investmentReducer,
    lenderNotifications: lenderNotificationsReducer,

    // Users
    info: userInfoReducer,
    userProfileDetails: profileDetailsReducer,
    userOtherDetails: otherDetailsReducer,
    userAccountDetails: showAccountDetailsReducer,
    userTransactionsHistory: listTransactionsHistoryReducer,
    userLoginsHistory: listLoginsHistoryReducer,

    // Pep Details Managenent
    pendingRegApprAnalytics: pendingRegApprAnalyticsReducer,
    peps: listPepsReducer,
    pepDetails: showPepDetailsReducer,

    // Application Management
    activeApplicationsList: listActiveApplicationsReducer,
    activeApplicationDetails: activeApplicationsDetailsReducer,
    pendingApplicationsList: listPendingApplicationsReducer,
    pendingApplicationsDetails: pendingApplicationsDetailsReducer,
    expiredApplicationsList: listExpiredApplicationsReducer,
    expiredApplicationsDetails: expiredApplicationsDetailsReducer,
    cancelledApplicationsList: listCancelledApplicationsReducer,
    cancelledApplicationsDetails: cancelledApplicationsDetailsReducer,
    rejectedApplicationsList: listRejectedApplicationsReducer,
    rejectedApplicationsDetails: rejectedApplicationsDetailsReducer,
    pendingApplicationApprovalsList: listPendingApplicationApprovalsReducer,
    pendingApplicationApprovalsDetails: pendingApplicationApprovalsDetailsReducer,

    // Loan Management
    loanInfo: loanInfoReducer,
    activeLoans: listActiveLoansReducer,
    activeLoanDetails: activeLoansDetailsReducer,
    completedLoans: listCompletedLoansReducer,
    completedLoanDetails: completedLoansDetailsReducer,
    defaultedLoans: listDefaultedLoansReducer,
    defaultedLoanDetails: defaultedLoansDetailsReducer,

    // Wallet management
    allWalletsList: listAllWalletsReducer,
    allWalletInfo: allWalletInfoReducer,
    allWalletsTransactionHistoryList: listAllWalletsTransactionHistoryReducer,
    allWalletStatusLog: allWalletStatusLogReducer,
    platformWalletsList: listPlatformWalletsReducer,
    platformWalletInfo: platformWalletInfoReducer,
    platformWalletsTransactionHistoryList: listPlatformWalletsTransactionHistoryReducer,
    platformWalletStatusLog: platformWalletStatusLogReducer,

    // Admin Users
    adminAnalytics: adminAnalyticsReducer,
    adminUsersList: listAdminUsersReducer,
    adminUsersDetails: adminUserDetailsReducer,
    adminSubjectsList: listAdminSubjectsReducer,
    adminPermissionsList: listPermissionsReducer,
  },
});

export default store;
