import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_APPLICATIONS_DETAILS;

export const showActiveApplicationDetails = createAsyncThunk(
    "activeApplication/details",
    async (data) => {

        // console.log("ActiveApplication Details Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("ActiveApplication Details Slice - response:", response);
        const activeApplicationDetailsPayload = await response.data.data;
        // const activeApplicationDetailsPayload = await response.data;
        // console.log("ActiveApplication Details Slice - payload:", activeApplicationDetailsPayload);

        return activeApplicationDetailsPayload;
    }
);

const showActiveApplicationDetailsSlice = createSlice({
    name: "activeApplicationDetails",
    initialState: {
        loading: false,
        activeApplicationDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showActiveApplicationDetails.pending, (state) => {
            state.loading = true;
            state.activeApplicationDetails = null;
            state.error = null;
        });
        builder.addCase(showActiveApplicationDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.activeApplicationDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showActiveApplicationDetails.rejected, (state, action) => {
            state.loading = false;
            state.activeApplicationDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getActiveApplicationDetails = (state) => state.activeApplicationDetails;
export default showActiveApplicationDetailsSlice.reducer;
