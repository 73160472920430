import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_TRANSACTION_HISTORY;

export const listTransactions = createAsyncThunk(
    "user/transactionHistory",
    async (data) => {
        const reqConfig = {
            method: "POST",
            url: URL,
            data
        };
      
       const response = await api(reqConfig);
        // console.log("Transaction History Slice - URL & Params:", URL, data);
        // const response = await postAPI(URL, data);
        // console.log("Transaction History Slice - response:", response);
        const transactionsPayload = await response.data.data.data;
        // console.log("Transaction History Slice - payload:", transactionsPayload);
        return transactionsPayload;
    }
);

const listTransactionHistorySlice = createSlice({
    name: "userTransactionHistory",
    initialState: {
        loading: false,
        transactions: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listTransactions.pending, (state) => {
            state.loading = true;
            state.transactions = null;
            state.error = null;
        });
        builder.addCase(listTransactions.fulfilled, (state, action) => {
            state.loading = false;
            state.transactions = action.payload;
            state.error = null;
        });
        builder.addCase(listTransactions.rejected, (state, action) => {
            state.loading = false;
            state.transactions = null;
            state.error = action.error.message;
        });
    },
});

export const getUserTransactions = (state) => state.userTransactionsHistory;
export default listTransactionHistorySlice.reducer;
