import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_LENDER_OPPORTUNITIES;

export const listOpportunites = createAsyncThunk(
    "lender/Opportunites",
    async (data) => {
        // console.log("List Opportunities Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data)
        // console.log("List Opportunities Slice - response:", response);
        const opportunitiesPayload = await response.data.data.data;

        // console.log("List Opportunities Slice - payload:", opportunitiesPayload);

        return opportunitiesPayload;
    }
);

const opportunitiesSlice = createSlice({
    name: "listOpportunites",
    initialState: {
        loading: false,
        opportunities: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listOpportunites.pending, (state) => {
            state.loading = true;
            state.opportunities = null;
            state.error = null;
        });
        builder.addCase(listOpportunites.fulfilled, (state, action) => {
            state.loading = false;
            state.opportunities = action.payload;
            state.error = null;
        });
        builder.addCase(listOpportunites.rejected, (state, action) => {
            state.loading = false;
            state.opportunities = null;
            state.error = action.error.message;
        });
    },
});

export const getOpportunitiesList = (state) => state.lenderOpportunities;

export default opportunitiesSlice.reducer;
