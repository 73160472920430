import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import { showProfileDetails, getProfileDetails } from "../../../features/user/profileSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";


const PROFILE = () => {
    const [id, setId] = useState(useParams().id)
    const dispatch = useDispatch();
    const profileDetailsSlice = useSelector(getProfileDetails)
    const profileDetails = profileDetailsSlice?.profileDetails;

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showProfileDetails(params));
        })()
    }, []);
    // console.log("Here is the borrower profile details", profileDetails);

    // tooltip Code 
    const Link = ({ id, children, title, placement }) => (

        <OverlayTrigger
            key="bottom"
            placement="bottom" overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a href="#">{children}</a>
        </OverlayTrigger>
    );


    return (
        <>
            <Row>
                <Col lg="7">
                    <div className="personal-details">
                        <h3>Personal Details</h3>

                        <Row>
                            <Col lg="6">
                                <Row>
                                    <Col lg="12">
                                        <div className="identityBox">
                                            <h4>Full Name</h4>
                                            <h5>{profileDetails?.fullName ? profileDetails?.fullName : "N/A"}</h5>

                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>First Name</h4>
                                            <h5>{profileDetails?.firstName ? profileDetails?.firstName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Father Name</h4>
                                            <h5>{profileDetails?.fatherName ? profileDetails?.fatherName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Grand Name</h4>
                                            <h5>{profileDetails?.grandName ? profileDetails?.grandName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Family Name</h4>
                                            <h5>{profileDetails?.familyName ? profileDetails?.familyName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Gender</h4>
                                            <h5>{profileDetails?.gender ? profileDetails?.gender : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Language</h4>
                                            <h5>{profileDetails?.language ? profileDetails?.language : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Date of Birth</h4>
                                            <h5>{profileDetails?.dob ? formatDate(profileDetails?.dob) : "N/A"}</h5>
                                            {/* <h5>{profileDetails?.dob ? profileDetails?.dob.split("-").join("/") : "N/A"}</h5> */}
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Date of Birth (H)</h4>
                                            <h5>{profileDetails?.dobH ? formatDate(profileDetails?.dobH) : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4>Nationality</h4>
                                            <h5>{profileDetails?.nationality ? profileDetails?.nationality : "N/A"}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg="6">
                                <Row>
                                    <Col lg="12">
                                        <div className="identityBox">
                                            <h4 className="text-end">Full Name</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arFullName ? profileDetails?.arFullName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4 className="text-end">First Name</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arFirstName ? profileDetails?.arFirstName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4 className="text-end">Father Name</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arFatherName ? profileDetails?.arFatherName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4 className="text-end">Grand Name</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arGrandName ? profileDetails?.arGrandName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6">
                                        <div className="identityBox">
                                            <h4 className="text-end">Family Name</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arFamilyName ? profileDetails?.arFamilyName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="12">
                                        <div className="identityBox">
                                            <h4 className="text-end">Nationality (Arabic)</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arNationality ? profileDetails?.arNationality : "N/A"}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg="5">
                    <div className="personal-details mb-0 ms-0 mx-0 pe-4">
                        <h3>National Address</h3>

                        <Row className="mb-4">
                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Short Address</h4>
                                    <h5>{profileDetails?.shortAddress ? profileDetails?.shortAddress : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Building No</h4>
                                    <h5>{profileDetails?.buildingNo ? profileDetails?.buildingNo : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Street Name</h4>
                                    <h5>{profileDetails?.streetName ? profileDetails?.streetName : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>Secondary No.</h4>
                                    <h5>{profileDetails?.secondaryNo ? profileDetails?.secondaryNo : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>District</h4>
                                    <h5>{profileDetails?.district ? profileDetails?.district : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="identityBox">
                                    <h4>City</h4>
                                    <h5>{profileDetails?.city ? profileDetails?.city : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="6">
                                <div className="identityBox">
                                    <h4>Postal Code</h4>
                                    <h5>{profileDetails?.postalCode ? profileDetails?.postalCode : "N/A"}</h5>


                                </div>
                            </Col>
                        </Row>

                        <Row className="flex-row-reverse">
                            <Col lg="10">
                                <Row className="flex-row-reverse">
                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">العنوان المختصر</h4>
                                            <h5 className="text-end arabi">{profileDetails?.shortAddress ? profileDetails?.shortAddress : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">رقم المبنى</h4>
                                            <h5 className="text-end arabi">{profileDetails?.buildingNo ? profileDetails?.buildingNo : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">اسم الشارع</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arStreetName ? profileDetails?.arStreetName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">الرقم الثانوي</h4>
                                            <h5 className="text-end arabi">{profileDetails?.secondaryNo ? profileDetails?.secondaryNo : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">الحياة</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arDistrict ? profileDetails?.arDistrict : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox">
                                            <h4 className="text-end arabi">المدينة</h4>
                                            <h5 className="text-end arabi">{profileDetails?.arCity ? profileDetails?.arCity : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="identityBox mb-1">
                                            <h4 className="text-end arabi">الرمز البريدي</h4>
                                            <h5 className="text-end arabi">{profileDetails?.postalCode ? profileDetails?.postalCode : "N/A"}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr />

            <div className="contact-details pb-2">
                <Row>
                    <Col lg="5">
                        <div className="detailBox separator-right">
                            <h3>Contact Details</h3>

                            <Row>
                                <Row><Col lg="12">
                                    <div className="identityBox">
                                        <h4>Email</h4>
                                        <div className="verify-dv">
                                            {
                                                profileDetails?.email ?
                                                    <>
                                                        <a className="linkText" href={`mailto:${profileDetails?.email}`}>{profileDetails?.email}</a>
                                                        <span className={`badge ${profileDetails?.isEmailVerified != 0 ? "bg-success" : "bg-danger"}`}>

                                                            {profileDetails?.isEmailVerified != 0 ?
                                                                <> Verified
                                                                    <Link title={formatDate(profileDetails?.emailVerificationDate) + " | " + formatTime(profileDetails?.emailVerificationDate)} id="t-1" aria-describedby="tooltip-bottom" placement="bottom">
                                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.95825 4.87501H7.04159V3.79168H5.95825M6.49992 10.8333C4.11117 10.8333 2.16659 8.88876 2.16659 6.50001C2.16659 4.11126 4.11117 2.16668 6.49992 2.16668C8.88867 2.16668 10.8333 4.11126 10.8333 6.50001C10.8333 8.88876 8.88867 10.8333 6.49992 10.8333ZM6.49992 1.08334C5.78859 1.08334 5.08423 1.22345 4.42705 1.49566C3.76987 1.76788 3.17274 2.16686 2.66976 2.66985C1.65393 3.68567 1.08325 5.06342 1.08325 6.50001C1.08325 7.9366 1.65393 9.31435 2.66976 10.3302C3.17274 10.8332 3.76987 11.2321 4.42705 11.5044C5.08423 11.7766 5.78859 11.9167 6.49992 11.9167C7.93651 11.9167 9.31426 11.346 10.3301 10.3302C11.3459 9.31435 11.9166 7.9366 11.9166 6.50001C11.9166 5.78868 11.7765 5.08432 11.5043 4.42714C11.2321 3.76996 10.8331 3.17283 10.3301 2.66985C9.8271 2.16686 9.22997 1.76788 8.57279 1.49566C7.91561 1.22345 7.21125 1.08334 6.49992 1.08334ZM5.95825 9.20834H7.04159V5.95834H5.95825V9.20834Z" fill="#044E18" />
                                                                        </svg>
                                                                    </Link>
                                                                </> : <>UnVerified</>
                                                            }
                                                        </span>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </Col></Row>


                                {/* <Col lg="6">
                                    <div className="identityBox">
                                        <h4>Previous Email</h4>
                                        <div className="verify-dv">
                                            {
                                                profileDetails?.prevEmail ?
                                                    <><a className="linkText" href={`mailto:${profileDetails?.prevEmail}`}>{profileDetails?.prevEmail}</a></>
                                                    :
                                                    <><h5>N/A</h5></>
                                            }

                                        </div>
                                    </div>
                                </Col> */}
                                <Row>

                                    <Col lg="12">
                                        <div className="identityBox">
                                            {/* <h4>Current Phone Number</h4> */}
                                            <h4>Phone Number</h4>
                                            <div className="verify-dv">
                                                {
                                                    profileDetails?.phone ?
                                                        <>
                                                            <a className="linkText" href={`tel:${profileDetails?.phone}`}>{profileDetails?.phone}</a>
                                                            <span className={`badge ${profileDetails?.isPhoneVerified != 0 ? "bg-success" : "bg-danger"}`}>
                                                                {profileDetails?.isPhoneVerified != 0 ?
                                                                    <> Verified
                                                                        <Link title={formatDate(profileDetails?.phoneVerificationDate) + " | " + formatTime(profileDetails?.phoneVerificationDate)} id="t-1" aria-describedby="tooltip-bottom" placement="bottom">
                                                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.95825 4.87501H7.04159V3.79168H5.95825M6.49992 10.8333C4.11117 10.8333 2.16659 8.88876 2.16659 6.50001C2.16659 4.11126 4.11117 2.16668 6.49992 2.16668C8.88867 2.16668 10.8333 4.11126 10.8333 6.50001C10.8333 8.88876 8.88867 10.8333 6.49992 10.8333ZM6.49992 1.08334C5.78859 1.08334 5.08423 1.22345 4.42705 1.49566C3.76987 1.76788 3.17274 2.16686 2.66976 2.66985C1.65393 3.68567 1.08325 5.06342 1.08325 6.50001C1.08325 7.9366 1.65393 9.31435 2.66976 10.3302C3.17274 10.8332 3.76987 11.2321 4.42705 11.5044C5.08423 11.7766 5.78859 11.9167 6.49992 11.9167C7.93651 11.9167 9.31426 11.346 10.3301 10.3302C11.3459 9.31435 11.9166 7.9366 11.9166 6.50001C11.9166 5.78868 11.7765 5.08432 11.5043 4.42714C11.2321 3.76996 10.8331 3.17283 10.3301 2.66985C9.8271 2.16686 9.22997 1.76788 8.57279 1.49566C7.91561 1.22345 7.21125 1.08334 6.49992 1.08334ZM5.95825 9.20834H7.04159V5.95834H5.95825V9.20834Z" fill="#044E18" />
                                                                            </svg>
                                                                        </Link>
                                                                    </> : <>UnVerified</>
                                                                }
                                                            </span>
                                                        </>
                                                        :
                                                        <><h5>N/A</h5></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* <Col lg="6">
                                    <div className="identityBox">
                                        <h4>Previous Phone Number</h4>
                                        <div className="verify-dv">
                                            {
                                                profileDetails?.prevPhone ?
                                                    <><a className="linkText" href={`tel:${profileDetails?.prevPhone}`}>{profileDetails?.prevPhone}</a></>
                                                    :
                                                    <><h5>N/A</h5></>
                                            }
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    </Col>

                    <Col lg="6">
                        <div className="detailBox">
                            <h3>ID Details</h3>

                            <Row>
                                <Col lg="3">
                                    <div className="identityBox">
                                        <h4>ID Issue Date</h4>
                                        <h5>{profileDetails?.idIssueDate ? formatDate(profileDetails?.idIssueDate) : "N/A"}</h5>
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <div className="identityBox">
                                        <h4>ID Issue Date (H)</h4>
                                        <h5>{profileDetails?.idIssueDateH ? formatDate(profileDetails?.idIssueDateH) : "N/A"}</h5>
                                        {/* <h5>{profileDetails?.idIssueDateH ? profileDetails?.idIssueDateH.split("-").join("/") : "N/A"}</h5> */}
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <div className="identityBox">
                                        <h4>Card issue place</h4>
                                        {/* <h5>Personal Status Administration, Riyadh</h5> */}
                                        <h5>{profileDetails?.cardIssuePlace ? profileDetails?.cardIssuePlace : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <div className="identityBox">
                                        <h4>ID Expiry Date</h4>
                                        <h5>{profileDetails?.idExpiryDate ? formatDate(profileDetails?.idExpiryDate) : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="3">
                                    <div className="identityBox">
                                        <h4>ID Expiry Date (H)</h4>
                                        <h5>{profileDetails?.idExpiryDateH ? formatDate(profileDetails?.idExpiryDateH) : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4">
                                    <div className="identityBox">
                                        <h4>Card Issue Place Arabic</h4>
                                        <h5 className="arabi text-end">{profileDetails?.arCardIssuePlace ? profileDetails?.arCardIssuePlace : "N/A"}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PROFILE
