import React, { useEffect, useState } from "react";
import "../../../assets/css/counter/counter.css";
// import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  listAllWallets,
  getAllWalletsList,
} from "../../../features/wallet-management/listAllWalletsSlice";
import {
  formatDate,
  formatTime,
} from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

const EXPIREDAPPCOUNTER = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filterType, setfilterType] = useState(undefined);
  const [filterValue, setfilterValue] = useState(undefined);

  const data = {
    limit,
    page,
    orderBy,
    sortType,
    filterType,
    filterValue,
  };
  const dispatch = useDispatch();
  const allWalletsSlice = useSelector(getAllWalletsList);
  const counterStats = allWalletsSlice?.allWallets?.stats;

  // console.log("All wallet Listing STATS ", counterStats);

  useEffect(() => {
    (async () => {
      await dispatch(listAllWallets(data));
    })();
  }, [limit, page, orderBy, sortType, filterType, filterValue]);

  return (
    <>
      <div className="counter">
        <div className="flex-counter">
          <div className="countBox">
            <h6>Total Wallets</h6>
            <h1>{counterStats?.total}</h1>
          </div>

          <div className="countBox">
            <h6>Total Active Wallets</h6>
            <h1>{counterStats?.active}</h1>
          </div>

          <div className="countBox">
            <h6>Total Inactive Wallets</h6>
            <h1>289</h1>
          </div>

          <div className="countBox">
            <h6>Total Suspended Wallets</h6>
            <h1>{counterStats?.suspended}</h1>
          </div>

          <div className="countBox">
            <h6>Total Wallets Balance</h6>
            <h1>
              {formattedNumber(counterStats?.balance)}
              <span className="currency">SAR</span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default EXPIREDAPPCOUNTER;
