import React from "react";
import "../../assets/css/breadcrumb/breadcrumb.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "bootstrap";
import GlobalVar from "../../utilities/global/index";

const BREADCRUMB = () => {
    const navigate = useNavigate();

    const url = window.location.href;
    const pathname = window.location.pathname;
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    console.log('currentpath', pathname);

    const { pathId } = useLocation();

    const globalVarObject = GlobalVar();

    return (
        <div className="breadCrumb">
            <ul>
                {
                    pathname == "/dashboard" ? (
                        <li>
                            <Link to="" className="bc active">{globalVarObject.key0}</Link>
                        </li>
                    ) : pathname === "/lenders-dashboard" || pathname.startsWith("/lenders-details") || pathname === "/borrowers-dashboard" || pathname.startsWith("/borrowers-details/") || pathname === "/pending-registeration" || pathname.startsWith("/pending-registration-details/") ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key1}</Link>
                            </li>
                            <li>
                                <Link to="" className="bc active">
                                    {
                                        pathname === "/lenders-dashboard" || pathname.startsWith("/lenders-details") ? (
                                            <>{globalVarObject.key1Object.subkey2}</>
                                        ) : pathname === "/borrowers-dashboard" || pathname.startsWith("/borrowers-details/") ? (
                                            <>{globalVarObject.key1Object.subkey1}</>
                                        ) : pathname === "/pending-registeration" ? (
                                            <>{globalVarObject.key1Object.subkey3}</>
                                        ) : pathname.startsWith("/pending-registration-details/") ? (
                                            <>
                                                <Link to="/pending-registeration" className="bc">{globalVarObject.key1Object.subkey3}</Link>
                                                <Link to="/pending-registeration" className="bc active">{globalVarObject.key1Object.subkey3} Details</Link>

                                            </>
                                        ) : null
                                    }
                                </Link>
                            </li>
                        </>
                    ) : pathname === "/application" || pathname === "/active-application" || pathname === "/pending-application" || pathname === "/expired-application" || pathname === "/cancelled-application" || pathname === "/rejected-application" || pathname === "/pending-application-approvals" ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key2}</Link>
                            </li>
                            {
                                pathname === "/application" ? (
                                    <li>
                                        <Link to="/application" className="bc active">
                                            {
                                                pathname === "/application" ? (
                                                    <>Applications</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/active-application" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/active-application" ? (
                                                    <>{globalVarObject.key2Object.subkey1}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/pending-application" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/pending-application" ? (
                                                    <>{globalVarObject.key2Object.subkey2}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/expired-application" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/expired-application" ? (
                                                    <>{globalVarObject.key2Object.subkey3}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/cancelled-application" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/cancelled-application" ? (
                                                    <>{globalVarObject.key2Object.subkey4}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/rejected-application" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/rejected-application" ? (
                                                    <>{globalVarObject.key2Object.subkey5}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname === "/pending-application-approvals" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/pending-application-approvals" ? (
                                                    <>{globalVarObject.key2Object.subkey6}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : null
                            }
                        </>
                    ) : pathname === "/active-loans" || pathname.startsWith("/active-loans-details") || pathname === "/completed-loans" || pathname.startsWith("/completed-loans-details") || pathname === "/defaulted-loans" || pathname.startsWith("/defaulted-loans-details") ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key3}</Link>
                            </li>
                            {
                                pathname === "/active-loans" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/active-loans" ? (
                                                    <>{globalVarObject.key3Object.subkey1}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/active-loans-details") ? (
                                    <>
                                        <li>
                                            <Link to="/active-loans" className="bc">
                                                {globalVarObject.key3Object.subkey1}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname.startsWith("/active-loans-details") ? (
                                                        <>{globalVarObject.key3Object.subkey1} Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : pathname === "/completed-loans" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/completed-loans" ? (
                                                    <>{globalVarObject.key3Object.subkey2}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/completed-loans-details") ? (
                                    <>
                                        <li>
                                            <Link to="/completed-loans" className="bc">{globalVarObject.key3Object.subkey2}</Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname.startsWith("/completed-loans-details") ? (
                                                        <>{globalVarObject.key3Object.subkey2} Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : pathname === "/defaulted-loans" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/defaulted-loans" ? (
                                                    <>{globalVarObject.key3Object.subkey3}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/defaulted-loans-details") ? (
                                    <>
                                        <li>
                                            <Link to="/defaulted-loans" className="bc">{globalVarObject.key3Object.subkey3}</Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname.startsWith("/defaulted-loans-details") ? (
                                                        <>{globalVarObject.key3Object.subkey3} Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : null
                            }
                        </>
                    ) : pathname === "/all-wallet" || pathname.startsWith("/wallet-details") || pathname === "/platform-wallet" || pathname.startsWith("/platform-wallet-details") ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key4}</Link>
                            </li>
                            {
                                pathname === "/all-wallet" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/all-wallet" ? (
                                                    <>{globalVarObject.key4Object.subkey1}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/wallet-details") ? (
                                    <>
                                        <li>
                                            <Link to="/all-wallet" className="bc">{globalVarObject.key4Object.subkey1}</Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname.startsWith("/wallet-details") ? (
                                                        <>{globalVarObject.key4Object.subkey1} Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : pathname === "/platform-wallet" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/platform-wallet" ? (
                                                    <>Platform Wallets</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/platform-wallet-details") ? (
                                    <>
                                        <li>
                                            <Link to="/platform-wallet" className="bc">
                                                <>{globalVarObject.key4Object.subkey2}</>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname.startsWith("/platform-wallet-details") ? (
                                                        <>{globalVarObject.key4Object.subkey2} Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : pathname === "/defaulted-loans" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/defaulted-loans" ? (
                                                    <>Defaulted Loans</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : pathname.startsWith("/defaulted-loans-details") ? (
                                    <>
                                        <li>
                                            <Link to="/defaulted-loans" className="bc">Defaulted Loans</Link>
                                        </li>
                                        <li>
                                            <Link to="" className="bc active">
                                                {
                                                    pathname === "/defaulted-loans-details" ? (
                                                        <>Defaulted Loans Details</>
                                                    ) : null
                                                }
                                            </Link>
                                        </li>
                                    </>
                                ) : null
                            }
                        </>
                    ) : pathname === "/user-roles" ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key8}</Link>
                            </li>
                            {
                                pathname === "/user-roles" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/user-roles" ? (
                                                    <>{globalVarObject.key8Object.subkey1}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : null
                            }
                        </>
                    ) : pathname === "/role-management" ? (
                        <>
                            <li>
                                <Link to="" className="bc">{globalVarObject.key8}</Link>
                            </li>
                            {
                                pathname === "/role-management" ? (
                                    <li>
                                        <Link to="" className="bc active">
                                            {
                                                pathname === "/role-management" ? (
                                                    <>{globalVarObject.key8Object.subkey2}</>
                                                ) : null
                                            }
                                        </Link>
                                    </li>
                                ) : null
                            }
                        </>
                    ) : null
                }
            </ul>

            <div className="backButton-dv">
                <button className="btn backBtn" onClick={() => navigate(-1)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="#270089" fillOpacity="0.1" />
                        <g clipPath="url(#clip0_763_4794)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.65808 9.38826C7.49602 9.55052 7.40499 9.77047 7.40499 9.99979C7.40499 10.2291 7.49602 10.4491 7.65808 10.6113L10.9212 13.8756C11.0835 14.0378 11.3036 14.129 11.5332 14.1289C11.7627 14.1289 11.9828 14.0376 12.1451 13.8753C12.3074 13.7129 12.3985 13.4928 12.3984 13.2632C12.3984 13.0337 12.3071 12.8136 12.1448 12.6513L9.49327 9.99979L12.1448 7.34826C12.3025 7.18512 12.3899 6.96656 12.388 6.73966C12.3861 6.51275 12.2952 6.29566 12.1348 6.13513C11.9745 5.97461 11.7575 5.88349 11.5306 5.88142C11.3037 5.87934 11.085 5.96646 10.9217 6.12403L7.6575 9.38768L7.65808 9.38826Z" fill="url(#paint0_linear_763_4794)" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_763_4794" x1="12.2994" y1="14.1474" x2="5.28411" y2="9.9005" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#236092" />
                                <stop offset="1" stopColor="#270089" />
                            </linearGradient>
                            <clipPath id="clip0_763_4794">
                                <rect width="13.8462" height="13.8462" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 16.9238 16.9229)" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default BREADCRUMB
