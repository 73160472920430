import React from "react";
import "../../../assets/css/counter/counter.css";
// import { useState, useEffect } from "react";

const CANCELLEDCOUNTER = ({ total = "", cancelled = "" }) => {

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Cancelled Applications</h6>
                        <h1>{total ? total : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Cancelled this month</h6>
                        <h1>{cancelled ? cancelled : "N/A"}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CANCELLEDCOUNTER