// import logo from './assets/images/logo.svg';
// import ReactDOM from 'react-dom'
import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';

// Views
import DASHBOARD from './views/dashboard/dashboard';
import BORROWERSDASHBOARD from './views/borrowers/dashboard';
import LENDERSDASHBOARD from './views/lenders/dashboard';
import Login from "./views/login/login";
import OTP from './views/login/otp';
import AUTHPASSWORD from "./views/login/auth-password";
import FORGOTPASSWORD from "./views/login/forgot-password";
import BORROWERS from './views/borrowers/borrowers';
import LENDERS from './views/lenders/lenders';

//Applications Management
import ACTIVEAPPLICATION from './views/applications-management/active-application/active-application';
import PENDINGAPPLICATION from './views/applications-management/pending-application/pending-application';
import EXPIREDAPPLICATION from './views/applications-management/expired-application/expired-application';
import CANCELLEDAPPLICATION from './views/applications-management/cancelled-application/cancelled-application';
import REJECTEDAPPLICATION from './views/applications-management/rejected-application/rejected-application';
import PENDINGAPPLICATIONAPPROVAL from './views/applications-management/pending-application-approvals/pending-application-approvals';

//Loans Management
import ACTIVELOAN from './views/loan-management/active-loans/active-loans';
import ACTIVELOANDETAILS from './views/loan-management/active-loans/active-loans-details';
import ACTIVELOANREQUESTLIST from './views/loan-management/active-loan-request-list/active-loan-request-list';
import PENDINGLOANREQUESTLIST from './views/loan-management/pending-loan-request-list/pending-loan-request-list';
import CANCELLEDLOANREQUESTLIST from './views/loan-management/canclelled-loan-request-list/canclelled-loan-request-list';
import COMPLETEDLOAN from './views/loan-management/completed-loans/completed-loans';
import COMPLETEDLOANDETAILS from './views/loan-management/completed-loans/completed-loans-details';
import DEFAULTEDLOAN from './views/loan-management/defaulted-loans/defaulted-loans';
import DEFAULTEDLOANDETAILS from './views/loan-management/defaulted-loans/defaulted-loans-details';


// components
import HEADER from './component/header/header';
import WELCOME from './component/welcome/welcome';
import BREADCRUMB from './component/breadcrumb/breadcrumb';
import COUNTER from './component/counter/counter';

// Borrowers
import INFO from './component/borrower/info/info';
import PROFILE from './component/borrower/profile/profile';
import OTHERDETAILS from './component/borrower/otherdetails/otherdetails';
import ACCOUNTDETAILS from './component/borrower/accountdetails/accountdetails';
import LOANAPPLICATIONS from './component/borrower/loan-applications/loan-applications';
import LOANS from './component/borrower/loans/loans';
import TRANSACTIONHISTORY from './component/borrower/transactionhistory/transactionhistory';
import LOGINHISTORY from './component/borrower/loginhistory/loginhistory';

// Lenders
import LENDERINFO from './component/lender/info/info';
import LENDERPROFILE from './component/lender/profile/profile';
import LENDEROTHERDETAILS from './component/lender/otherdetails/otherdetails';
import LENDERACCOUNTDETAILS from './component/lender/accountdetails/accountdetails';
// import LENDERLOAN from './component/lender/loan/loan';
import LENDERACTIVEINVESTMENT from './component/lender/opportunities/opportunities';
import LENDERINVESTMENTHISTORY from './component/lender/investment/investment';
import LENDERTRANSACTIONHISTORY from './component/lender/transactionhistory/transactionhistory';
import LENDERLOGINHISTORY from './component/lender/loginhistory/loginhistory';


//Loans Management
import ALLWALLETS from './views/wallet-management/wallets/all-wallets';
import WALLETDETAILS from './views/wallet-management/wallets/wallet-details';
import PLATFORMWALLETS from './views/wallet-management/platform/platform-wallets';
import PLATFORMWALLETDETAILS from './views/wallet-management/platform/platform-wallet-details';

// Admin Users
import USERROLES from './views/admin-users/user-list/user-roles';
import USERRIGHTS from './views/admin-users/role-management/user-rights';

// Pending registeration
import PENDINGREGISTERATION from './views/pending-registeration-approvals/pending-registrration';
import PENDINGREGISTERATIONDETAILS from './views/pending-registeration-approvals/pending-registeration-details';

// Activate Component
import ACTIVATELAYOUT from './views/activate/activateLayout';
import ACTIVATE from './views/activate/activate';
import EXHAUSTLIMITPAGE from './views/login/exhaust-limit-page';
import { useEffect, useState } from 'react';
import NotFound from './views/404/NotFound';
import { getUserRoleData, isValidJWT } from './utilities/helper/getUserRoleData';


function App() {

    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const route = "/dashboard";
    let roleData = {};
    // console.log("isOtpVerified", isOtpVerified);
    const token = localStorage.getItem("userToken");
    if (isOtpVerified || isValidJWT(token)) {
        roleData = getUserRoleData();
    }
    const testRoleData = {
        hideBorrowerDashboard: 0
    }

    return (

        <>
            <Routes>

                <Route exact path='/' element={<Login />}></Route>
                <Route exact path='/otp' element={<OTP setIsOtpVerified={setIsOtpVerified} route={route} />}></Route>
                <Route exact path='/auth-password' element={<AUTHPASSWORD />}></Route>
                <Route exact path='/forgot-password' element={<FORGOTPASSWORD />}></Route>
                <Route exact path='/exhaust-limit' element={<EXHAUSTLIMITPAGE />}></Route>
                <Route path="/activate/*" element={<ACTIVATELAYOUT />}>

                    <Route index element={<ACTIVATE />} />

                </Route>
                {/* {token && <> */}
                {isValidJWT(token) && <Route exact path='/dashboard' element={<DASHBOARD />}></Route>}
                {/* {testRoleData["hideBorrowerDashboard"] === "1" && <Route exact path='/borrowers-dashboard' element={<BORROWERSDASHBOARD />} />} */}
                {isValidJWT(token) && <Route exact path='/borrowers-dashboard' element={<BORROWERSDASHBOARD />} />}
                {isValidJWT(token) && <Route exact path='/lenders-dashboard' element={<LENDERSDASHBOARD />}></Route>}

                {isValidJWT(token) && <Route exact path='/header' element={<HEADER />}></Route>}
                {isValidJWT(token) && <Route exact path='/breadcrumb' element={<BREADCRUMB />}></Route>}
                {isValidJWT(token) && <Route exact path='/counter' element={<COUNTER />}></Route>}
                {isValidJWT(token) && <Route exact path='/welcome' element={<WELCOME />}></Route>}

                {/* Borrowers */}
                {isValidJWT(token) && <Route exact path='/borrowers-details/:id' element={<BORROWERS />}></Route>}
                {isValidJWT(token) && <Route exact path='/info' element={<INFO />}></Route>}
                {isValidJWT(token) && <Route exact path='/profile' element={<PROFILE />}></Route>}
                {isValidJWT(token) && <Route exact path='/other-details' element={<OTHERDETAILS />}></Route>}
                {isValidJWT(token) && <Route exact path='/account-details' element={<ACCOUNTDETAILS />}></Route>}
                {isValidJWT(token) && <Route exact path='/loan-applications' element={<LOANAPPLICATIONS />}></Route>}
                {isValidJWT(token) && <Route exact path='/loans' element={<LOANS />}></Route>}
                {isValidJWT(token) && <Route exact path='/transaction-history' element={<TRANSACTIONHISTORY />}></Route>}
                {isValidJWT(token) && <Route exact path='/login-history' element={<LOGINHISTORY />}></Route>}

                {/* Lenders */}
                {isValidJWT(token) && <Route exact path='/lenders-details/:id' element={<LENDERS />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-info' element={<LENDERINFO />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-profile' element={<LENDERPROFILE />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-other-details' element={<LENDEROTHERDETAILS />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-account-details' element={<LENDERACCOUNTDETAILS />}></Route>}
                {/* <Route exact path='/lender-loan' element={<LENDERLOAN />}></Route> */}
                {isValidJWT(token) && <Route exact path='/lender-active-investment' element={<LENDERACTIVEINVESTMENT />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-investment-history' element={<LENDERINVESTMENTHISTORY />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-transaction-history' element={<LENDERTRANSACTIONHISTORY />}></Route>}
                {isValidJWT(token) && <Route exact path='/lender-login-history' element={<LENDERLOGINHISTORY />}></Route>}

                {/* Application Management */}
                {isValidJWT(token) && <Route exact path='/active-application' element={<ACTIVEAPPLICATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/pending-application' element={<PENDINGAPPLICATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/expired-application' element={<EXPIREDAPPLICATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/cancelled-application' element={<CANCELLEDAPPLICATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/rejected-application' element={<REJECTEDAPPLICATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/pending-application-approvals' element={<PENDINGAPPLICATIONAPPROVAL />} ></Route>}

                {/* Loans Management */}
                {isValidJWT(token) && <Route exact path='/active-loans' element={<ACTIVELOAN />} ></Route>}
                {isValidJWT(token) && <Route exact path='/active-loans-details/:id' element={<ACTIVELOANDETAILS />} ></Route>}
                {isValidJWT(token) && <Route exact path='/active-loan-request-list' element={<ACTIVELOANREQUESTLIST />} ></Route>}
                {isValidJWT(token) && <Route exact path='/pending-loan-request-list' element={<PENDINGLOANREQUESTLIST />} ></Route>}
                {isValidJWT(token) && <Route exact path='/cancelled-loan-request-list' element={<CANCELLEDLOANREQUESTLIST />} ></Route>}
                {isValidJWT(token) && <Route exact path='/completed-loans' element={<COMPLETEDLOAN />} ></Route>}
                {isValidJWT(token) && <Route exact path='/completed-loans-details/:id' element={<COMPLETEDLOANDETAILS />} ></Route>}
                {isValidJWT(token) && <Route exact path='/defaulted-loans' element={<DEFAULTEDLOAN />} ></Route>}
                {isValidJWT(token) && <Route exact path='/defaulted-loans-details/:id' element={<DEFAULTEDLOANDETAILS />} ></Route>}

                {/* Wallet Management*/}
                {isValidJWT(token) && <Route exact path='/all-wallet' element={<ALLWALLETS />} ></Route>}
                {isValidJWT(token) && <Route exact path='/wallet-details/:id' element={<WALLETDETAILS />} ></Route>}

                {isValidJWT(token) && <Route exact path='/platform-wallet' element={<PLATFORMWALLETS />} ></Route>}
                {isValidJWT(token) && <Route exact path='/platform-wallet-details/:id' element={<PLATFORMWALLETDETAILS />} ></Route>}

                {/* Admin Users */}
                {isValidJWT(token) && <Route exact path='/user-roles' element={<USERROLES />} ></Route>}
                {isValidJWT(token) && <Route exact path='/role-management' element={<USERRIGHTS />} ></Route>}

                {/* Pending Registeration Start */}
                {isValidJWT(token) && <Route exact path='/pending-registeration' element={<PENDINGREGISTERATION />} ></Route>}
                {isValidJWT(token) && <Route exact path='/pending-registration-details/:id' element={<PENDINGREGISTERATIONDETAILS />} ></Route>}
                {/* </>} */}
                <Route exact path='*' element={<NotFound />} ></Route>
            </Routes>
        </>
    )
}

export default App;
