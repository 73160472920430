const GlobalVar = () => {
    const globalVarObject = {
        key0: 'Dashboard',
        key0Object: {
            subkey1: 'Critical alert',
            subkey2: 'Analytics',
        },
        key1: 'App User Management',
        key1Object: {
            subkey1: 'Borrowers',
            subkey2: 'Lenders',
            subkey3: 'Pending Account Approvals',
        },
        key2: 'Loan Applications',
        key2Object: {
            subkey1: 'Active Applications',
            subkey2: 'Pending Applications',
            subkey3: 'Expired Applications',
            subkey4: 'Cancelled Applications',
            subkey5: 'Rejected Applications',
            subkey6: 'Pending Application Approvals',
        },
        key3: 'Loans Management',
        key3Object: {
            subkey1: 'Active Loans',
            subkey2: 'Completed Loans',
            subkey3: 'Defaulted Loans',
        },
        key4: 'Wallet management',
        key4Object: {
            subkey1: 'All Wallets',
            subkey2: 'Platform Wallet',
        },
        key7: 'Platform settings',
        key8: 'Admin Users',
        key8Object: {
            subkey1: 'User List',
            subkey2: 'Role Management',
        },
    };
    return globalVarObject;
}

export default GlobalVar;