import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, getAPI, postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_ROLE_SUBJECTS;

export const listAdminSubjects = createAsyncThunk(
    "role/subjects",
    async (params) => {
        // // console.log("Admin Users List Slice - URL & Params:", URL, params);
        // // const response = await getAPI(URL, params)
        // const response = await getAPI(URL, params);
        // // const adminUsersPayload = await response.data.data.;
        // // console.log("Admin Users List Slice - response:", response);
        const response = await postAPI(URL, params);
        const adminSubjectsPayload = await response.data.data;
        console.log(adminSubjectsPayload)
        // console.log("Admin Users List Slice - payload:", adminUsersPayload);
        return adminSubjectsPayload;
    }
);

const listAdminSubjectsSlice = createSlice({
    name: "listAdminSubjects",
    initialState: {
        loading: false,
        subjects: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listAdminSubjects.pending, (state) => {
            state.loading = true;
            state.subjects = null;
            state.error = null;
        });
        builder.addCase(listAdminSubjects.fulfilled, (state, action) => {
            state.loading = false;
            state.subjects = action.payload;
            state.error = null;
        });
        builder.addCase(listAdminSubjects.rejected, (state, action) => {
            state.loading = false;
            state.subjects = null;
            state.error = action.error.message;
        });
    },
});

export const getAdminSubjectsList = (state) => state.adminSubjectsList;
export default listAdminSubjectsSlice.reducer;

