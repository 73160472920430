import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_ACTIVE_LOANS_DETAILS;

export const showActiveLoanDetails = createAsyncThunk(
    "activeLoan/showActiveLoanDetails",
    async (data) => {

        // console.log("Active Loan Details Slice - URL & data:", URL, data);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Active Loan Details Slice - response:", response);
        const activeLoanDetailsPayload = await response.data.data;
        // const activeLoanDetailsPayload = await response.data;
        // console.log("Active Loan Details Slice - payload:", activeLoanDetailsPayload);

        return activeLoanDetailsPayload;
    }
);

const showActiveLoanDetailsSlice = createSlice({
    name: "showActiveLoanDetailsSlice",
    initialState: {
        loading: false,
        activeLoanDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showActiveLoanDetails.pending, (state) => {
            state.loading = true;
            state.activeLoanDetails = null;
            state.error = null;
        });
        builder.addCase(showActiveLoanDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.activeLoanDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showActiveLoanDetails.rejected, (state, action) => {
            state.loading = false;
            state.activeLoanDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getActiveLoanDetails = (state) => state.activeLoanDetails;
export default showActiveLoanDetailsSlice.reducer;
