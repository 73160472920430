import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { showloanApplications, getLoanApplications } from "../../../features/borrower/loanApplicationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const LOAN = () => {
    const [aladetailsShowTop, setAladetailsShowTop] = useState(Array(10).fill(false));
    const [aladetailsShowBottom, setAladetailsShowBottom] = useState(Array(10).fill(false));

    const [borrowerId, setBorrowerId] = useState(useParams().id)
    const [pageFlag, setPageFlag] = useState(0)

    const params = {
        borrowerId,
        pageFlag,

    }
    const dispatch = useDispatch();
    const activeLoanApplicationSlice = useSelector(getLoanApplications)
    // const activeLoanApps = activeLoanApplicationSlice?.activeLoanApps;
    const activeLoanApps = activeLoanApplicationSlice?.applications?.activeApplications;
    const activeLoanAppHistory = activeLoanApplicationSlice?.applications?.applicationHistory;
    const activeLoanAppsLength = activeLoanApps?.length;
    // console.log(activeLoanApps);
    // console.log(activeLoanAppHistory);

    // applicationHistory
    // console.log("These are the active Loan Application....", activeLoanAppHistory);

    useEffect(() => {
        (async () => {
            await dispatch(showloanApplications(params))
        })()
    }, []
    );


    const handleAladetailsShowTop = (index) => {
        // setAladetailsShow(true);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = true;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsCloseTop = (index) => {
        // setAladetailsShow(false);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = false;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsShowBottom = (index) => {
        // setAladetailsShow(true);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = true;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const handleAladetailsCloseBottom = (index) => {
        // setAladetailsShow(false);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = false;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const reload = () => {
        dispatch(showloanApplications(params))
    }


    return (
        <>
            <div className="content">

                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Active Loan Applications</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp mb-5">
                    <Table responsive style={{ width: "1870px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Application  ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Display  ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Applied on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expiry</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Reason</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Interest Rate</span>
                                    </div>
                                </th>
                                <th style={{ width: "170px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Installment</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Repay Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "130px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Funded</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeLoanApplicationSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!activeLoanApplicationSlice?.loading && activeLoanApplicationSlice?.error ? <div>Error: {activeLoanApplicationSlice?.error}</div> : null}

                            {!activeLoanApplicationSlice?.loading && activeLoanApps?.length ?
                                (activeLoanApps.map((activeLoanApplication, index) => (
                                    <tr key={index}>
                                        <td valign="top" style={{ width: "180px" }}>
                                            <div className="loanApplicationId">
                                                <span className="date">{activeLoanApplication.loanApplicationId ? activeLoanApplication.loanApplicationId : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td valign="top" style={{ width: "180px" }}>
                                            <div className="loanApplicationId">
                                                <span className="date">{activeLoanApplication.displayId ? activeLoanApplication.displayId : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime appliedOnDate">
                                                <span className="date">{activeLoanApplication.appliedOnDate ? formatDate(activeLoanApplication.appliedOnDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanApplication.appliedOnDate ? formatTime(activeLoanApplication.appliedOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{activeLoanApplication.expiryDate ? formatDate(activeLoanApplication.expiryDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanApplication.expiryDate ? formatTime(activeLoanApplication.expiryDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance loanAmount">
                                                {
                                                    activeLoanApplication.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanApplication.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="duration">
                                                <span>{activeLoanApplication.duration ? activeLoanApplication.duration : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="loanReason">
                                                <span>{activeLoanApplication.loanReason ? activeLoanApplication.loanReason : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="interestRate">
                                                <span>{activeLoanApplication.interestRate ? activeLoanApplication.interestRate : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "170px" }}>
                                            <div className="walletBalance installmentAmount">
                                                {
                                                    activeLoanApplication.installmentAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanApplication.installmentAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="totalNoOfInstallments">
                                                {
                                                    activeLoanApplication.totalNoOfInstallments ?
                                                        <><span>{activeLoanApplication.totalNoOfInstallments}</span></>
                                                        :
                                                        <><span>N/A</span></>
                                                }

                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="walletBalance totalRepayAmount">
                                                {
                                                    activeLoanApplication.totalRepayAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanApplication.totalRepayAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "130px" }}>
                                            <div className="funded">
                                                {
                                                    activeLoanApplication.funded ?
                                                        <><span>{activeLoanApplication.funded}</span></>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowTop(index)} >View</Button>

                                                <MyVerticallyCenteredModalTop show={aladetailsShowTop[index]} onHide={() => handleAladetailsCloseTop(index)} loanApplication={activeLoanApplication} status="active" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!activeLoanApplicationSlice?.loading && !activeLoanApps?.length && !activeLoanApplicationSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}

                </div>

                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Loan Applications History</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp vertical-scroll active-loans-scroll">
                    <Table responsive style={{ width: "2070px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Application ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Display ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Applied on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Expiry</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Status</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Reason</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Interest Rate</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Installment</span>
                                    </div>
                                </th>
                                <th style={{ width: "210px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Repay Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Funded</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeLoanApplicationSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!activeLoanApplicationSlice?.loading && activeLoanApplicationSlice?.error ? <div>Error: {activeLoanApplicationSlice?.error}</div> : null}

                            {!activeLoanApplicationSlice?.loading && activeLoanAppHistory?.length ?
                                (activeLoanAppHistory.map((activeLoanAppHistoryList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="loanApplicationId">
                                                <span>{activeLoanAppHistoryList.loanApplicationId ? activeLoanAppHistoryList.loanApplicationId : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{activeLoanAppHistoryList.appliedOnDate ? formatDate(activeLoanAppHistoryList.appliedOnDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanAppHistoryList.appliedOnDate ? formatTime(activeLoanAppHistoryList.appliedOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime">
                                                <span className="date">{activeLoanAppHistoryList.expiryDate ? formatDate(activeLoanAppHistoryList.expiryDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanAppHistoryList.expiryDate ? formatTime(activeLoanAppHistoryList.expiryDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="status">
                                                <span>{activeLoanAppHistoryList.status ? activeLoanAppHistoryList.status : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="walletBalance loanAmount">
                                                {
                                                    activeLoanAppHistoryList.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanAppHistoryList.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="duration">
                                                <span>{activeLoanAppHistoryList.duration ? activeLoanAppHistoryList.duration : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="loanReason">
                                                <span>{activeLoanAppHistoryList.loanReason ? activeLoanAppHistoryList.loanReason : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="interestRate">
                                                <span>{activeLoanAppHistoryList.interestRate ? activeLoanAppHistoryList.interestRate : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="walletBalance installmentAmount">
                                                {
                                                    activeLoanAppHistoryList.installmentAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanAppHistoryList.installmentAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="totalNoOfInstallments">
                                                <span>{activeLoanAppHistoryList.totalNoOfInstallments ? activeLoanAppHistoryList.totalNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "210px" }}>
                                            <div className="walletBalance totalRepayAmount">
                                                {
                                                    activeLoanAppHistoryList.totalRepayAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanAppHistoryList.totalRepayAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="funded">
                                                <span>{activeLoanAppHistoryList.funded ? activeLoanAppHistoryList.funded : "N/A"}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowBottom(index)} >View</Button>

                                                <MyVerticallyCenteredModalBottom show={aladetailsShowBottom[index]} onHide={() => handleAladetailsCloseBottom(index)} loanAppHistory={activeLoanAppHistoryList} status="history" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!activeLoanApplicationSlice?.loading && !activeLoanAppHistory?.length && !activeLoanApplicationSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}

                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModalTop(props) {
    // console.log("Top Props", props);
    const { loanApplication } = props;
    // console.log("Top loan Applications", loanApplication);

    const modelDetails = () => {
        // console.log("active");

        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>Active Loan Application Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Application ID </h4>
                                        <h5>{loanApplication.loanApplicationId ? loanApplication.loanApplicationId : "N/A"}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Display ID </h4>
                                        <h5>{loanApplication.displayId ? loanApplication.displayId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        <h5>{loanApplication.duration ? loanApplication.duration : "N/A"} Month</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Installment</h4>
                                        <h5>{loanApplication.totalNoOfInstallments ? loanApplication.totalNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Applied on</h4>
                                        <div className="dateTime appliedOnDate">
                                            <h5>{loanApplication.appliedOnDate ? formatDate(loanApplication.appliedOnDate) : "N/A"}</h5>
                                            <span className="time">{loanApplication.appliedOnDate ? formatTime(loanApplication.appliedOnDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Reason</h4>
                                        <h5>{loanApplication.loanReason ? loanApplication.loanReason : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Repay Amount</h4>
                                        {
                                            loanApplication.totalRepayAmount ?
                                                <><h5>SAR {formattedNumber(loanApplication.totalRepayAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expiry</h4>
                                        <div className="dateTime expiryDate">
                                            <h5>{loanApplication.expiryDate ? formatDate(loanApplication.expiryDate) : "N/A"}</h5>
                                            <span className="time">{loanApplication.expiryDate ? formatTime(loanApplication.expiryDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Interest Rate</h4>
                                        <h5>{loanApplication.interestRate ? loanApplication.interestRate : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Funded</h4>
                                        <h5>{loanApplication.funded ? loanApplication.funded : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount </h4>
                                        {
                                            loanApplication.loanAmount ?
                                                <><h5>SAR {formattedNumber(loanApplication.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Amount</h4>
                                        {
                                            loanApplication.installmentAmount ?
                                                <><h5>SAR {formattedNumber(loanApplication.installmentAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )

    }
    return (
        modelDetails()
    )
}

function MyVerticallyCenteredModalBottom(props) {
    // console.log("Bottom Props", props);

    const { loanAppHistory } = props;

    const modelDetails = () => {
        // console.log("history");
        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>Loan Application History Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form>
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Application ID </h4>
                                        <h5>{loanAppHistory.loanApplicationId ? loanAppHistory.loanApplicationId : "N/A"}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Display ID </h4>
                                        <h5>{loanAppHistory.displayId ? loanAppHistory.displayId : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount </h4>
                                        {
                                            loanAppHistory.loanAmount ?
                                                <><h5>SAR {formattedNumber(loanAppHistory.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Amount</h4>
                                        {
                                            loanAppHistory.installmentAmount ?
                                                <><h5>SAR {formattedNumber(loanAppHistory.installmentAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Applied on</h4>
                                        <div className="dateTime appliedOnDate">
                                            <h5>{loanAppHistory.appliedOnDate ? formatDate(loanAppHistory.appliedOnDate) : "N/A"}</h5>
                                            <span className="time">{loanAppHistory.appliedOnDate ? formatTime(loanAppHistory.appliedOnDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        <h5>{loanAppHistory.duration ? <>{loanAppHistory.duration} Month</> : "N/A"} </h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Installment</h4>
                                        <h5>{loanAppHistory.totalNoOfInstallments ? loanAppHistory.totalNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Expiry</h4>
                                        <div className="dateTime">
                                            <h5>{loanAppHistory.expiryDate ? formatDate(loanAppHistory.expiryDate) : "N/A"}</h5>
                                            <span className="time">{loanAppHistory.expiryDate ? formatTime(loanAppHistory.expiryDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Reason</h4>
                                        <h5>{loanAppHistory.loanReason ? loanAppHistory.loanReason : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Repay Amount</h4>
                                        {
                                            loanAppHistory.totalRepayAmount ?
                                                <><h5>SAR {formattedNumber(loanAppHistory.totalRepayAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Status</h4>
                                        <h5>{loanAppHistory.status ? loanAppHistory.status : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Interest Rate</h4>
                                        <h5>{loanAppHistory.interestRate ? loanAppHistory.interestRate : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Funded</h4>
                                        <h5>{loanAppHistory.funded ? loanAppHistory.funded : "N/A"}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return (
        modelDetails()
    )
}

export default LOAN