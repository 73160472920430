import React, { useMemo } from 'react'
import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/authUserSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import GlobalVar from "../../utilities/global/index";
import axios from 'axios'

const EXHAUSTLIMITPAGE = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [otpLength,setOtpLength] = useState(0);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resend, setResend] = useState(true);
  const [timer, setTimer] = useState("");
  const [inValidOtpCount, setInValidOtpCount] = useState(1);
  const [resendOtpCount, setResendOtpCount] = useState(1);



  // console.log(location.state);
  const { password: isPassword, otp: isOtp } = location.state;


  return (
    <>
      <div className="Login">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>

            <div className="col-md-6">
              <div className="loginBox">
                <div className="loginBoxHeader">
                  <img src={logo} alt="logo" />
                </div>



                <div className="loginBoxContent">
                  {/* <div className={`loginBoxContent `}> */}
                  <form className="loginForm" action="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label for="" className="form-label otp" >
                            {
                              isPassword ? "Password Limit Exceeded" :
                                isOtp ? "OTP Limit Exceeded" : "Limit Exceeded"
                            }
                          </label>

                          <p className="exhaustNote">
                            Too many incorrect attempts. Retry in <strong>15 minutes</strong>.
                          </p>

                          <p className="exhaustNoteSub">
                            Forgot password? Please select the "Forgot Password" option to reset it.
                          </p>

                        </div>
                      </div>

                      <Link to="/" className="exhaust-link d-flex justify-content-center">
                        Back to login
                      </Link>

                      {/* <div className={'col-md-12 errorRow ' + error}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                          <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 24 14 A 2 2 0 0 0 24 18 A 2 2 0 0 0 24 14 z M 23.976562 20.978516 A 1.50015 1.50015 0 0 0 22.5 22.5 L 22.5 33.5 A 1.50015 1.50015 0 1 0 25.5 33.5 L 25.5 22.5 A 1.50015 1.50015 0 0 0 23.976562 20.978516 z"></path>
                        </svg>
                        <span className='invalidMsg'>Incorrect OTP entered</span>
                        <span className='resendMsg'>New OTP has been sent!</span>
                      </div> */}


                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EXHAUSTLIMITPAGE;
