import React from "react";
import { useState, useEffect } from "react";
import { showBorrowerAnalytics, getBorrowerAnalytics } from "../../../features/borrower/borrowerAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";

const COUNTER = () => {

    const dispatch = useDispatch();
    const counterSlice = useSelector(getBorrowerAnalytics)
    const analytics = counterSlice?.analytics;

    // console.log("======The Borrower Counter Slice======", counterSlice);
    // console.log("======The Borrower Analytics======", analytics);
    const params = {
        type: "borrower"
    }

    useEffect(() => {
        (async () => {
            await dispatch(showBorrowerAnalytics(params))
        })()
    }, []
    );

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Borrowers</h6>
                        <h1>{analytics?.all ? analytics?.all : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Active Borrowers</h6>
                        <h1>{analytics?.active ? analytics?.active : "N/A"}</h1>
                    </div>

                    <div className="countBox">
                        <h6>Sanctioned</h6>
                        <h1>{analytics?.sanctioned ? analytics?.sanctioned : "N/A"}</h1>
                    </div>

                    <div className={`countBox `}>
                        <h6>Banned</h6>
                        <h1>{analytics?.banned ? analytics?.banned : "N/A"}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default COUNTER