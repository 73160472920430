
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";

import { showActiveApplicationDetails, getActiveApplicationDetails } from "../../../features/applications-management/activeApplicationDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { api } from "../../../api/apiCallMethods";
import { useNavigate } from "react-router-dom";
import { listActiveApplications, getActiveApplicationsList } from "../../../features/applications-management/listActiveApplicationsSlice";
const ActiveApplicationDetail = (props) => {
    const navigate = useNavigate();
    const { id } = props;
    // const [id, setId] = useState(5)
    const dispatch = useDispatch();
    const activeApplicationDetailsSlice = useSelector(getActiveApplicationDetails)
    const activeApplicationDetails = activeApplicationDetailsSlice?.activeApplicationDetails;
    const fundingDetails = activeApplicationDetails?.fundingDetails;
    // console.log("Here are Active Application Details", id);
    // console.log("activeApplicationDetails", { borrowerId: activeApplicationDetails?.borrowerId })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);


    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [isExpiredAndRefunded, setIsExpiredAndRefunded] = useState(false);
    const [isCancelledAndRefund, setIsCancelledAndRefund] = useState(false);
    const openConfirmModal = () => {
        setConfirmModalShow(true); // Open the confirmation modal
    };

    const params = {
        id
    }
    const listingParams = {
        // id,
        limit: 10,
        page: 1,
        orderBy: "id",
        sortType: "desc",
        filterType: undefined,
        filterValue: undefined,
        status: "active"
    }
    useEffect(() => {
        (async () => {
            await dispatch(showActiveApplicationDetails(params));
        })()
    }, []);

    // console.log("Here are the active Applications Details Slice", activeApplicationDetailsSlice);


    const handleExpireCancelRefund = async (action, e) => {
        console.log("Clicked");
        if (action === "expire") {
            setIsExpiredAndRefunded(true);
            setIsCancelledAndRefund(false);
        } else if (action === "cancel") {
            setIsExpiredAndRefunded(false);
            setIsCancelledAndRefund(true);
        }
        console.log(action)
        // Show confirmation modal
        openConfirmModal();


    }

    // The action that is needed to be performed
    const handleExpireCancelRefundConfirm = async (applicationId) => {
        setLoading(true);
        let url;
        let data;
        if (isExpiredAndRefunded) {
            console.log("whill show === exipred ===  url")
            url = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_EXPIRE_REFUND_APPLICATION;
            data = {
                loanApplicationId: applicationId
            }
        } else if (isCancelledAndRefund) {
            console.log("whill show === cancelled === url")
            url = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_CANCEL_REFUND_APPLICATION;
            data = {
                loanId: applicationId
            }
        }
        const reqConfig = {
            method: "POST",
            url,
            data: {
                ...data,
                borrowerId: activeApplicationDetails?.borrowerId
            }

        };

        // console.log(reqConfig)
        try {
            const response = await api(reqConfig);
            console.log({ response: response.data });
            if (response.data.code !== 200) {
                throw new Error(response.data.code);
            }
            setLoading(false);
            setSuccess(true);
            setError(false);


        } catch (error) {
            console.log(error.message);
            setLoading(false);
            setSuccess(false);
            setError(true);

        }

        // dispatch(listActiveApplications(listingParams));
        ///// Using the one below ////////
        setConfirmModalShow(false); // Close the confirmation modal
        // setModalShow(false); // Close the first modal


    };

    const handleCancel = () => {
        setConfirmModalShow(false); // Close only the confirmation modal
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Active Loan Application Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row className="mb-4">
                        <Col lg="10">
                            {activeApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!activeApplicationDetailsSlice?.loading && activeApplicationDetailsSlice?.error ? <div>Error: {activeApplicationDetailsSlice?.error}</div> : null}
                            {!activeApplicationDetailsSlice?.loading && activeApplicationDetails ? (
                                <Row>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Application ID</h4>
                                            <div className="dateTime">
                                                <h5>{activeApplicationDetails?.applicationId ? activeApplicationDetails?.applicationId : "N/A"}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Display ID</h4>
                                            <div className="dateTime">
                                                <h5>{activeApplicationDetails?.displayId ? activeApplicationDetails?.displayId : "N/A"}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower ID</h4>
                                            <h5>{activeApplicationDetails?.borrowerIdNumber ? activeApplicationDetails?.borrowerIdNumber : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Duration</h4>
                                            <h5>{activeApplicationDetails?.duration ? <>{activeApplicationDetails?.duration} Months</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Profit Rate</h4>
                                            <h5>{activeApplicationDetails?.profitRate ? activeApplicationDetails?.profitRate : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Created on</h4>
                                            <div className="dateTime">
                                                <h5>{activeApplicationDetails?.createdOnDate ? formatDate(activeApplicationDetails?.createdOnDate) : "N/A"}</h5>
                                                <span className="time">{activeApplicationDetails?.createdOnDate ? formatTime(activeApplicationDetails?.createdOnDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Req Status</h4>
                                            <h5>{activeApplicationDetails?.reqStatus ? activeApplicationDetails?.reqStatus : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>No. of Installments</h4>
                                            <h5>{activeApplicationDetails?.noOfInstallments ? activeApplicationDetails?.noOfInstallments : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded</h4>
                                            <h5>{activeApplicationDetails?.funded ? activeApplicationDetails?.funded : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Application Expiry</h4>
                                            <div className="dateTime">
                                                <h5>{formatDate(activeApplicationDetails?.applicationExpiryDate)}</h5>
                                                <span className="time">{formatTime(activeApplicationDetails?.applicationExpiryDate)}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Amount</h4>
                                            <h5>{activeApplicationDetails?.loanAmount ? <>SAR {activeApplicationDetails?.loanAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Per Installment</h4>
                                            <h5>{activeApplicationDetails?.perInstallmentAmount ? <>SAR {activeApplicationDetails?.perInstallmentAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded Amount</h4>
                                            <h5>{activeApplicationDetails?.fundedAmount ? <>SAR {activeApplicationDetails?.fundedAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower Name</h4>
                                            <h5>{activeApplicationDetails?.borrowerName ? activeApplicationDetails?.borrowerName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Reason</h4>
                                            <h5>{activeApplicationDetails?.loanReason ? activeApplicationDetails?.loanReason : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Total Repay Amount</h4>
                                            <h5>{activeApplicationDetails?.totalRepayAmount ? <>SAR {activeApplicationDetails?.totalRepayAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            )
                                : null
                            }
                            {/* {!activeApplicationDetailsSlice?.loading && !activeApplicationDetailsSlice?.error && activeApplicationDetails && "Data doesn't exist!"} */}
                        </Col>
                    </Row>

                    <div className="accountSummary p-0 mb-3 d-flex justify-content-between align-items-center">
                        <h2 className="mb-3">Funding Details</h2>
                        <div className="d-flex">
                            <div className="action-dv my-4 me-3">
                                <div className="d-flex">
                                    <Button className="btn-theme-primary" style={{ "width": "fit-content" }} onClick={() => handleExpireCancelRefund("expire")}>
                                        Expire & Refund
                                    </Button>
                                </div>
                            </div>
                            <div className="action-dv my-4">
                                <div className="d-flex">
                                    <Button className="btn-theme-primary" style={{ "width": "fit-content" }} onClick={() => handleExpireCancelRefund("cancel")}>
                                        Cancel & Refund
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* come here */}
                    {loading && !error && !success && <div class="alert alert-info" role="alert"> Loading...</div>}
                    {!loading && !error && success && <div className="alert alert-primary" role="alert">Refunded Successfully!</div>}

                    {!loading && error && !success && <div className="alert alert-danger" role="alert">Something went Wrong!</div>}

                    <div className="records activeLoanApp">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th align="center">
                                        <div className="labelIco" style={{ justifyContent: "center" }}>
                                            <span className="headTitle">Sn.</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Investor ID</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Lender Name</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Funding Date</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Payment Ref No</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Amount</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="labelIco">
                                            <span className="headTitle">Funded</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                {!activeApplicationDetailsSlice?.loading && activeApplicationDetailsSlice?.error ? <div>Error: {activeApplicationDetailsSlice?.error}</div> : null}
                                {!activeApplicationDetailsSlice?.loading && fundingDetails?.length ? (

                                    fundingDetails.map(detail =>
                                    (

                                        <tr>
                                            <td align="center">
                                                <div className="serialNo">
                                                    <span>{detail?.sNo ? detail?.sNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="investorId">
                                                    <span>{detail?.investorId ? detail?.investorId : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="lenderName">
                                                    <span>{detail?.investorName ? detail?.investorName : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dateTime">
                                                    <span className="date">{formatDate(detail?.fundingDate)}</span>&nbsp;&nbsp;
                                                    <span className="time">{formatTime(detail?.fundingDate)}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refNo">
                                                    <span>{detail?.paymentRefNo ? detail?.paymentRefNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amount">
                                                    {
                                                        detail?.amount ?
                                                            <>
                                                                <span>SAR {formattedNumber(detail?.amount)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span>N/A</span>
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="funded">
                                                    <span>{detail?.funded ? detail?.funded : "N/A"}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    )
                                )
                                    : null
                                }

                            </tbody>
                        </Table>

                        {!activeApplicationDetailsSlice?.loading && !fundingDetails?.length && !activeApplicationDetailsSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>

            <MyConfirmModal
                show={confirmModalShow}
                onHide={() => setConfirmModalShow(false)}
                onConfirm={() => handleExpireCancelRefundConfirm(activeApplicationDetails?.applicationId)}
                onCancel={handleCancel}
                isExpiredAndRefunded={isExpiredAndRefunded}
                isCancelledAndRefund={isCancelledAndRefund}
            // applicationId={activeApplicationDetails?.applicationId}
            />
        </>


    );
}




function MyConfirmModal(props) {
    const { applicationId, isExpiredAndRefunded, isCancelledAndRefund } = props;
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm">
            <Modal.Header className="d-flex justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter" >
                    Are you sure you want to {isExpiredAndRefunded ? "Expire/Refund" : isCancelledAndRefund ? "Cancel/Refund" : ""} this application?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmBtns d-flex justify-content-center">
                    <Button className="btn-white me-3" onClick={props.onConfirm}>Yes</Button>

                    <Button onClick={props.onHide}>Cancel</Button>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}




export default ActiveApplicationDetail;
