import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";


const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_PENDING_APPLICATIONS_DETAILS;

export const showPendingApplicationDetails = createAsyncThunk(
    "pendingApplication/showPendingApplicationDetails",
    async (data) => {

        // console.log("Pending Application Details Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        // console.log("Pending Application Details Slice - response:", response);
        const pendingApplicationDetailsPayload = await response.data.data;
        // const pendingApplicationDetailsPayload = await response.data;
        // console.log("Pending Application Details Slice - payload:", pendingApplicationDetailsPayload);

        return pendingApplicationDetailsPayload;
    }
);

const showPendingApplicationDetailsSlice = createSlice({
    name: "showPendingApplicationDetailsSlice",
    initialState: {
        loading: false,
        pendingApplicationDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(showPendingApplicationDetails.pending, (state) => {
            state.loading = true;
            state.pendingApplicationDetails = null;
            state.error = null;
        });
        builder.addCase(showPendingApplicationDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingApplicationDetails = action.payload;
            state.error = null;
        });
        builder.addCase(showPendingApplicationDetails.rejected, (state, action) => {
            state.loading = false;
            state.pendingApplicationDetails = null;
            state.error = action.error.message;
            console.log(action.error.message);
        });
    },
});
export const getPendingApplicationsDetails = (state) => state.pendingApplicationsDetails;
export default showPendingApplicationDetailsSlice.reducer;
