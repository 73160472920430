import React from "react";
import "../../../assets/css/counter/counter.css";
// import { useState, useEffect } from "react";

const PENDINGAPPLICATIONAPPROVALSCOUNTER = ({ total = "", expiring = "" }) => {

    return (
        <>
            <div className="counter">
                <div className="flex-counter">
                    <div className="countBox">
                        <h6>Total Pending Applications</h6>
                        <h1>{total ? total : "N/A"}</h1>
                    </div>

                    {/* <div className="countBox">
                        <h6>Expiring Today</h6>
                        <h1>{expiring ? expiring : "N/A"}</h1>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default PENDINGAPPLICATIONAPPROVALSCOUNTER