import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/apiCallMethods";

const URL = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_REJECTED_APPLICATIONS_LISTING;

export const listRejectedApplications = createAsyncThunk(
    "rejectedApplication/listRejectedApplication",
    async (data) => {

        // console.log("List Rejected Applications List Slice - URL & Params:", URL, params);
        const response = await postAPI(URL, data);
        // const response = await getAPI('http://localhost:3004/get-data', params);
        const rejectedApplicationsPayload = await response.data.data;
        // console.log("List Rejected Applications List Slice - response:", response);
        // const rejectedApplicationsPayload = await response.data;
        // console.log("List Rejected Applications List Slice - payload:", rejectedApplicationsPayload);
        return rejectedApplicationsPayload;
    }
);

const listRejectedApplicationsSlice = createSlice({
    name: "listRejectedApplications",
    initialState: {
        loading: false,
        rejectedApplications: null,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(listRejectedApplications.pending, (state) => {
            state.loading = true;
            state.rejectedApplications = null;
            state.error = null;
        });
        builder.addCase(listRejectedApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.rejectedApplications = action.payload;
            state.error = null;
        });
        builder.addCase(listRejectedApplications.rejected, (state, action) => {
            state.loading = false;
            state.rejectedApplications = null;
            state.error = action.error.message;
        });
    },
});

export const getRejectedApplicationsList = (state) => state.rejectedApplicationsList;
export default listRejectedApplicationsSlice.reducer;
