import React, { createRef, useEffect } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/authUserSlice";
import { Link, useNavigate } from "react-router-dom";
import GlobalVar from "../../utilities/global/index";
import axios from "axios";
const LOGIN = () => {
    const [typePass, setTypePass] = useState("password");
    const navigate = useNavigate();

    // states
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    // redux state
    const { loading, error } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const handleAuthPass = async (e) => {
        e.preventDefault();
        try {
            // const isEmailValid = email RegExp.verify email
            // if(isEmailValid) 

            const response = await axios({
                method: "POST",
                url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_FORGET_PASSWORD,
                headers: {
                    "ignore_encryption": true
                },
                data: {
                    email
                }
            });
            if (response.data.code === 200) {
                console.log(response.data.message);
                navigate("/");
            }

            if (response.data.code === 400) {
                throw new Error(response.data.message);
            }
            console.log("====== Forgot password Response =====", response)
        } catch (error) {
            console.error(error)
        }
    };


    return (
        <>
            <div className="Login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>

                        <div className="col-md-6">
                            <div className="loginBox">
                                <div className="loginBoxHeader">
                                    <img src={logo} alt="logo" />
                                </div>

                                <div className={`loginBoxContent `}>
                                    {/* <div className={`loginBoxContent d-none`}> */}
                                    <h2>Forgot Password</h2>
                                    <p>After posting your email, please check your inbox.</p>

                                    <form className="loginForm" action="/dashboard" onSubmit={handleAuthPass} >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label for="newPassword" className="form-label" >
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="newPassword"
                                                        placeholder="Enter your email"
                                                        aria-describedby="emailHelp"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />

                                                    {
                                                        msg ?
                                                            <>
                                                                <div className="msg my-2">
                                                                    <p style={{ color: "red", textAlign: "left" }}>{msg}</p>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary w-100" disabled={loading} >
                                                    {loading ? "Loading..." : "Submit"}
                                                </button>
                                                {error && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {error}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LOGIN;
