import React, { useEffect, useState } from "react";

// Elements
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { showLoans, getLoans } from "../../../features/borrower/loansSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const LOAN = () => {

    const [aladetailsShowTop, setAladetailsShowTop] = useState(Array(10).fill(false));
    const [aladetailsShowBottom, setAladetailsShowBottom] = useState(Array(10).fill(false));

    const [borrowerId, setBorrowerId] = useState(useParams().id)
    const [pageFlag, setPageFlag] = useState(0)


    const params = {
        borrowerId,
        pageFlag,

    }
    const dispatch = useDispatch();
    const activeLoanSlice = useSelector(getLoans)
    const activeLoan = activeLoanSlice?.loans?.activeLoans;
    const loansHistories = activeLoanSlice?.loans?.loansHistory;
    // const activeLoanAppsLength = activeLoanApps?.length;
    // console.log("active Loans Data ======", activeLoanSlice);
    // console.log(activeLoanHistory);

    // applicationHistory
    // console.log("These are the active Loan Application....", activeLoanAppHistory);

    useEffect(() => {
        (async () => {
            await dispatch(showLoans(params))
        })()
    }, []
    );


    const handleAladetailsShowTop = (index) => {
        // setAladetailsShow(true);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = true;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsCloseTop = (index) => {
        // setAladetailsShow(false);

        const updateModalsTop = [...aladetailsShowTop];
        updateModalsTop[index] = false;
        setAladetailsShowTop(updateModalsTop)
    }

    const handleAladetailsShowBottom = (index) => {
        // setAladetailsShow(true);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = true;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const handleAladetailsCloseBottom = (index) => {
        // setAladetailsShow(false);

        const updateModalsBottom = [...aladetailsShowBottom];
        updateModalsBottom[index] = false;
        setAladetailsShowBottom(updateModalsBottom)
    }

    const reload = () => {
        dispatch(showLoans(params))
    }

    return (

        <>
            <div className="content">
                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Active Loan</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records activeLoanApp mb-5">
                    <Table responsive style={{ width: "1870px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Display ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Created on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Matured on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                {/* <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Type</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Transfer</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Promissory Signing</span>
                                    </div>
                                </th> */}
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Installment</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Remaining</span>
                                    </div>
                                </th>
                                <th style={{ width: "160px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Paid</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {activeLoanSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!activeLoanSlice?.loading && activeLoanSlice?.error ? <div>Error: {activeLoanSlice?.error}</div> : null}
                            {!activeLoanSlice?.loading && activeLoan?.length ?
                                (activeLoan.map((activeLoanList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="loan-application-id">
                                                <span>{activeLoanList.loanId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="loan-application-id">
                                                <span>{activeLoanList.displayId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime createdOnDate">
                                                <span className="date">{activeLoanList.createdOnDate ? formatDate(activeLoanList.createdOnDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanList.createdOnDate ? formatTime(activeLoanList.createdOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime maturedOnDate">
                                                <span className="date">{activeLoanList.maturedOnDate ? formatDate(activeLoanList.maturedOnDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanList.maturedOnDate ? formatTime(activeLoanList.maturedOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance loanAmount">
                                                {
                                                    activeLoanList.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(activeLoanList.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="duration">
                                                {
                                                    activeLoanList.duration ?
                                                        <><span>{activeLoanList.duration} Month</span></>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        {/* <td style={{ width: "150px" }}>
                                            <div className="type">
                                                <span>{activeLoanList.type ? activeLoanList.type : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime loanTransferDate">
                                                <span className="date">{activeLoanList.loanTransferDate ? formatDate(activeLoanList.loanTransferDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanList.loanTransferDate ? formatTime(activeLoanList.loanTransferDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="dateTime promissorySigningDate">
                                                <span className="date">{activeLoanList.promissorySigningDate ? formatDate(activeLoanList.promissorySigningDate) : "N/A"}</span><br />
                                                <span className="time">{activeLoanList.promissorySigningDate ? formatTime(activeLoanList.promissorySigningDate) : ""}</span>
                                            </div>
                                        </td> */}
                                        <td style={{ width: "180px" }}>
                                            <div className="totalNoOfInstallments">
                                                <span>{activeLoanList.totalNoOfInstallments ? activeLoanList.totalNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="remainingNoOfInstallments">
                                                <span>{activeLoanList.remainingNoOfInstallments ? activeLoanList.remainingNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "160px" }}>
                                            <div className="paidNoOfInstallments">
                                                <span>{activeLoanList.paidNoOfInstallments ? activeLoanList.paidNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowTop(index)} >View</Button>

                                                <MyVerticallyCenteredModalTop show={aladetailsShowTop[index]} onHide={() => handleAladetailsCloseTop(index)} activeLoan={activeLoanList} status="active" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }
                        </tbody>
                    </Table>

                    {!activeLoanSlice?.loading && !activeLoan?.length && !activeLoanSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>

                <div className="optionsBox">
                    <Row>
                        <Col lg="6">
                            <div className="titleDv">
                                <h3 className="title mb-3">Loan History</h3>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="pagination">
                                <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                    <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                        <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                        <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="records vertical-scroll active-loans-scroll">
                    <Table responsive style={{ width: "1900px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Display ID</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Created on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Matured on</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Amount</span>
                                    </div>
                                </th>
                                <th style={{ width: "120px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Duration</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Type</span>
                                    </div>
                                </th>
                                <th style={{ width: "150px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Loan Transfer</span>
                                    </div>
                                </th>
                                <th style={{ width: "190px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Promissory Signing</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Total Installment</span>
                                    </div>
                                </th>
                                <th style={{ width: "180px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Remaining</span>
                                    </div>
                                </th>
                                <th style={{ width: "170px" }}>
                                    <div className="labelIco">
                                        <span className="headTitle">Installment Paid</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {activeLoanSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!activeLoanSlice?.loading && activeLoanSlice?.error ? <div>Error: {activeLoanSlice?.error}</div> : null}

                            {!activeLoanSlice?.loading && loansHistories?.length ?
                                (loansHistories.map((loansHistoryList, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "180px" }}>
                                            <div className="device">
                                                <span>{loansHistoryList.loanId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="device">
                                                <span>{loansHistoryList.displayId}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime createdOnDate">
                                                <span className="date">{loansHistoryList.createdOnDate ? formatDate(loansHistoryList.createdOnDate) : "N/A"}</span><br />
                                                <span className="time">{loansHistoryList.createdOnDate ? formatTime(loansHistoryList.createdOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="dateTime maturedOnDate">
                                                <span className="date">{loansHistoryList.maturedOnDate ? formatDate(loansHistoryList.maturedOnDate) : "N/A"}</span><br />
                                                <span className="time">{loansHistoryList.maturedOnDate ? formatTime(loansHistoryList.maturedOnDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="walletBalance loanAmount">
                                                {
                                                    loansHistoryList.loanAmount ?
                                                        <>
                                                            <span className="price">{formattedNumber(loansHistoryList.loanAmount)}</span>
                                                            <span className="currency">SAR</span>
                                                        </>
                                                        :
                                                        <><span>N/A</span></>
                                                }
                                            </div>
                                        </td>
                                        <td style={{ width: "120px" }}>
                                            <div className="duration">
                                                <span>{loansHistoryList.duration ? loansHistoryList.duration : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="type">
                                                <span>{loansHistoryList.type ? loansHistoryList.type : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <div className="loanTransferDate">
                                                <span>{loansHistoryList.loanTransferDate ? loansHistoryList.loanTransferDate : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "190px" }}>
                                            <div className="dateTime promissorySigningDate">
                                                <span className="date">{loansHistoryList.promissorySigningDate ? formatDate(loansHistoryList.promissorySigningDate) : 'N/A'}</span><br />
                                                <span className="time">{loansHistoryList.promissorySigningDate ? formatTime(loansHistoryList.promissorySigningDate) : ""}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="totalNoOfInstallments">
                                                <span>{loansHistoryList.totalNoOfInstallments ? loansHistoryList.totalNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "180px" }}>
                                            <div className="remainingNoOfInstallments">
                                                <span>{loansHistoryList.remainingNoOfInstallments ? loansHistoryList.remainingNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td style={{ width: "170px" }}>
                                            <div className="paidNoOfInstallments">
                                                <span>{loansHistoryList.paidNoOfInstallments ? loansHistoryList.paidNoOfInstallments : "N/A"}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action">
                                                <Button className="btn btn-view" variant="primary" onClick={() => handleAladetailsShowBottom(index)} >View</Button>
                                                <MyVerticallyCenteredModalBottom show={aladetailsShowBottom[index]} onHide={() => handleAladetailsCloseBottom(index)} loanHistory={loansHistoryList} status="history" />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                ) : null
                            }

                        </tbody>
                    </Table>

                    {!activeLoanSlice?.loading && !loansHistories?.length && !activeLoanSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                </div>
            </div>
        </>
    )
}


function MyVerticallyCenteredModalTop(props) {
    // console.log("Top Props", props);
    const activeLoan = props?.activeLoan;
    const installments = activeLoan?.installments


    const modelDetails = () => {
        // console.log("active");

        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>Active Loan Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan ID</h4>
                                        <h5>{activeLoan.loanId}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Display ID</h4>
                                        <h5>{activeLoan.displayId}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Reason</h4>
                                        <h5>{activeLoan.loanReason ? activeLoan.loanReason : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Installment</h4>
                                        <h5>{activeLoan.totalNoOfInstallments ? activeLoan.totalNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Status</h4>
                                        <h5>{activeLoan.status ? activeLoan.status : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Interest Rate</h4>
                                        <h5>{activeLoan.interestRate ? activeLoan.interestRate : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Paid</h4>
                                        <h5>{activeLoan.paidNoOfInstallments ? activeLoan.paidNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        {
                                            activeLoan.loanAmount ?
                                                <><h5>SAR {formattedNumber(activeLoan.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Amount</h4>
                                        {
                                            activeLoan.installmentAmount ?
                                                <><h5>SAR {formattedNumber(activeLoan.installmentAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Promissory Signing</h4>
                                        <div className="dateTime">
                                            <h5>{activeLoan.promissorySigningDate ? formatDate(activeLoan.promissorySigningDate) : "N/A"}</h5>
                                            <span className="time">{activeLoan.promissorySigningDate ? formatTime(activeLoan.promissorySigningDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        {
                                            activeLoan.duration ?
                                                <><h5>{activeLoan.duration} Months</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Repay Amount</h4>
                                        {
                                            activeLoan.totalRepayAmount ?
                                                <><h5>SAR {formattedNumber(activeLoan.totalRepayAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Transferred</h4>
                                        <div className="dateTime">
                                            <h5>{activeLoan.loanTransferDate ? formatDate(activeLoan.loanTransferDate) : "N/A"}</h5>
                                            <span className="time">{activeLoan.loanTransferDate ? formatTime(activeLoan.loanTransferDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>

                        <h3 className="sub-title mb-3">Installment</h3>

                        <div className="records activeLoanApp">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Installment</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Due Date</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Amount</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Status</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {installments?.length ? (
                                        installments.map(installment => (
                                            <tr>
                                                <td>
                                                    <div className="labelIco">
                                                        <span>{installment.installmentNo ? installment.installmentNo : "N/A"}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dateTime">
                                                        <span className="date">{installment?.dueDate ? formatDate(installment?.dueDate) : "N/A"}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="status">
                                                        {
                                                            installment?.amount ?
                                                                <><span>SAR {Math.floor(installment?.amount)}</span></>
                                                                :
                                                                <><h5>N/A</h5></>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="status">
                                                        <span>{installment?.status ? installment?.status : "N/A"}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        : null
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )

    }
    return (
        modelDetails()
    )
}

function MyVerticallyCenteredModalBottom(props) {
    const loanHistory = props?.loanHistory;
    // console.log("Bottom Props installments", props.loanHistory.installments);
    const installments = loanHistory?.installments
    // console.log("The installments variable,,/./.", installments);

    const modelDetails = () => {
        // console.log("history");
        return (
            <>
                <Modal {...props} size="xl" dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered className="banForm" >
                    <Modal.Header closeButton>
                        <Modal.Title>Loan Details</Modal.Title>
                    </Modal.Header>


                    <Modal.Body>
                        <form className="mb-4">
                            <Row>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan ID</h4>
                                        <h5>{loanHistory.loanId}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Display ID</h4>
                                        <h5>{loanHistory.displayId}</h5>
                                    </div>
                                </Col>
                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Reason</h4>
                                        <h5>{loanHistory.loanReason ? loanHistory.loanReason : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Installment</h4>
                                        <h5>{loanHistory.totalNoOfInstallments ? loanHistory.totalNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Status</h4>
                                        <h5>{loanHistory.status ? loanHistory.status : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Interest Rate</h4>
                                        <h5>{loanHistory.interestRate ? loanHistory.interestRate : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Paid</h4>
                                        <h5>{loanHistory.paidNoOfInstallments ? loanHistory.paidNoOfInstallments : "N/A"}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Amount</h4>
                                        {
                                            loanHistory.loanAmount ?
                                                <><h5>SAR {formattedNumber(loanHistory.loanAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Installment Amount</h4>
                                        {
                                            loanHistory.installmentAmount ?
                                                <><h5>SAR {formattedNumber(loanHistory.installmentAmount)}</h5></>
                                                :
                                                <><h5>N/A</h5></>
                                        }

                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Promissory Signing</h4>
                                        <div className="dateTime">
                                            <h5>{loanHistory.promissorySigningDate ? formatDate(loanHistory.promissorySigningDate) : "N/A"}</h5>
                                            <span className="time">{loanHistory.promissorySigningDate ? formatTime(loanHistory.promissorySigningDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Duration</h4>
                                        <h5>{loanHistory.duration ? loanHistory.duration : "N/A"} Months</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Total Repay Amount</h4>
                                        <h5>SAR {loanHistory.totalRepayAmount}</h5>
                                    </div>
                                </Col>

                                <Col lg="4" className="mb-2">
                                    <div className="popupDv">
                                        <h4>Loan Transferred</h4>
                                        <div className="dateTime">
                                            <h5>{loanHistory.loanTransferDate ? formatDate(loanHistory.loanTransferDate) : "N/A"}</h5>
                                            <span className="time">{loanHistory.loanTransferDate ? formatTime(loanHistory.loanTransferDate) : ""}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>

                        <h3 className="sub-title mb-3">Installment</h3>

                        <div className="records activeLoanApp">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Installment</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Due Date</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Amount</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Status</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {installments?.length ? (
                                        installments.map(installment => (
                                            <tr>
                                                <td>
                                                    <div className="labelIco">
                                                        <span>{installment.installmentNo}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dateTime">
                                                        <span className="date">{installment.dueDate ? formatDate(installment.dueDate) : "N/A"}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="status">
                                                        <span>SAR {Math.floor(installment.dueAmount)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="status">
                                                        <span>{installment.status}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        : null
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return (
        modelDetails()
    )
}

export default LOAN