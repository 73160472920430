
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";

import { showPendingApplicationApprovalsDetails, getPendingApplicationApprovalsDetails } from "../../../features/applications-management/pendingApplicationApprovalsDetailsSlice";


import { listPendingApplicationApprovals, getPendingApplicationApprovalsList } from "../../../features/applications-management/listPendingApplicationApprovalsSlice";


import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import { api } from "../../../api/apiCallMethods";
import { useNavigate } from "react-router-dom";

const PendingApplicationApprovalDetail = (props) => {


    const [dangerButtonStyles, setDangerButtonStyles] = useState({
        width: "fit-content",
        backgroundColor: "#dc3545",
        outlineColor: "#dc3545",
        borderColor: "#dc3545",
    });
    const [successButtonStyles, setSuccessButtonStyles] = useState({
        width: "fit-content",
        backgroundColor: "#198754",
        outlineColor: "#198754",
        borderColor: "#198754",
    });

    const handleDangerBtnStyles = () => {
        setDangerButtonStyles({
            width: "fit-content",
            backgroundColor: "#dc3545",
            outlineColor: "#dc3545",
            borderColor: "#dc3545",
        });
    }
    const handleSuccessBtnStyles = () => {
        setSuccessButtonStyles({
            width: "fit-content",
            backgroundColor: "#198754",
            outlineColor: "#198754",
            borderColor: "#198754",
        })
    }
    const navigate = useNavigate();
    const { id, modalArray, modalArrayIndex } = props;
    // const [id, setId] = useState(5)
    const dispatch = useDispatch();
    const pendingApplicationApprovalsDetailsSlice = useSelector(getPendingApplicationApprovalsDetails)
    const pendingApplicationApprovalsDetails = pendingApplicationApprovalsDetailsSlice?.pendingApplicationApprovalsDetails;
    const [adminAction, setAdminAction] = useState("");
    // console.log("Here are pending Application approval Details", id);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);


    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const openConfirmModal = () => {
        setConfirmModalShow(true); // Open the confirmation modal
    };

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showPendingApplicationApprovalsDetails(params));
        })()
    }, []);

    // console.log("Here are the pending Application approval Details Slice", pendingApplicationApprovalsDetailsSlice);


    const handleApproveOrReject = async (action) => {
        // console.log("Clicked");
        setAdminAction(action)
        // Show confirmation modal
        openConfirmModal();
    }

    // The action that is needed to be performed
    const handleApproveOrRejectModal = async ({ applicationId, borrowerId, adminAction }) => {
        // console.log({
        //     applicationId, borrowerId, adminAction
        // });
        setConfirmModalShow(false); // Close the confirmation modal
        const approveUrl = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_APPROVE_PENDING_APPLICATION
        const rejectUrl = process.env.REACT_APP_LOAN_BASE_URL + process.env.REACT_APP_REJECT_PENDING_APPLICATION

        setLoading(true);
        const url = adminAction === "approve" ? approveUrl : adminAction === "reject" ? rejectUrl : ""

        const reqConfig = {
            method: "POST",
            url,
            data: {
                loanApplicationId: applicationId,
                borrowerId
            }
        };

        console.log(reqConfig)
        try {
            const response = await api(reqConfig);
            // console.log({ response: response.data });
            if (response.data.code !== 200) {
                throw new Error(response.data.code);
            }
            setLoading(false);
            setSuccess(true);
            setError(false);


        } catch (error) {
            console.log(error.message);
            setLoading(false);
            setSuccess(false);
            setError(true);

        } finally {
            setTimeout(() => {

                modalArray[modalArrayIndex] = false;

                dispatch(listPendingApplicationApprovals({
                    page: 1,
                    limit: 10,
                    status: "pending approval",
                    orderBy: "createdAt",
                    sortType: "desc",
                }));
            }, 2000)
        }

        // setModalShow(false); // Close the first modal




    };

    const handleCancel = () => {
        setConfirmModalShow(false); // Close only the confirmation modal
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pending Application Approval Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row className="mb-4">
                        <Col lg="10">
                            {pendingApplicationApprovalsDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!pendingApplicationApprovalsDetailsSlice?.loading && pendingApplicationApprovalsDetailsSlice?.error ? <div>Error: {pendingApplicationApprovalsDetailsSlice?.error}</div> : null}
                            {!pendingApplicationApprovalsDetailsSlice?.loading && pendingApplicationApprovalsDetails ? (
                                <Row>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Application ID</h4>
                                            <div className="dateTime">
                                                <h5>{pendingApplicationApprovalsDetails?.applicationId ? pendingApplicationApprovalsDetails?.applicationId : "N/A"}</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Display ID</h4>
                                            <div className="dateTime">
                                                <h5>{pendingApplicationApprovalsDetails?.displayId ? pendingApplicationApprovalsDetails?.displayId : "N/A"}</h5>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower ID</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.borrowerIdNumber ? pendingApplicationApprovalsDetails?.borrowerIdNumber : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Duration</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.duration ? <>{pendingApplicationApprovalsDetails?.duration} Months</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Profit Rate</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.profitRate ? pendingApplicationApprovalsDetails?.profitRate : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Created on</h4>
                                            <div className="dateTime">
                                                <h5>{pendingApplicationApprovalsDetails?.createdOnDate ? formatDate(pendingApplicationApprovalsDetails?.createdOnDate) : "N/A"}</h5>
                                                <span className="time">{pendingApplicationApprovalsDetails?.createdOnDate ? formatTime(pendingApplicationApprovalsDetails?.createdOnDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Req Status</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.reqStatus ? pendingApplicationApprovalsDetails?.reqStatus : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>No. of Installments</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.noOfInstallments ? pendingApplicationApprovalsDetails?.noOfInstallments : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        {/* <div className="popupDv">
                                            <h4>Funded</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.funded ? pendingApplicationApprovalsDetails?.funded : "N/A"}</h5>
                                        </div> */}
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Application Expiry</h4>
                                            <div className="dateTime">
                                                <h5>{formatDate(pendingApplicationApprovalsDetails?.applicationExpiryDate)}</h5>
                                                <span className="time">{formatTime(pendingApplicationApprovalsDetails?.applicationExpiryDate)}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Amount</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.loanAmount ? <>SAR {pendingApplicationApprovalsDetails?.loanAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Per Installment</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.perInstallmentAmount ? <>SAR {pendingApplicationApprovalsDetails?.perInstallmentAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        {/* <div className="popupDv">
                                            <h4>Funded Amount</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.fundedAmount ? <>SAR {pendingApplicationApprovalsDetails?.fundedAmount}</> : "N/A"}</h5>
                                        </div> */}
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower Name</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.borrowerName ? pendingApplicationApprovalsDetails?.borrowerName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Reason</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.loanReason ? pendingApplicationApprovalsDetails?.loanReason : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="6" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Total Repay Amount</h4>
                                            <h5>{pendingApplicationApprovalsDetails?.totalRepayAmount ? <>SAR {pendingApplicationApprovalsDetails?.totalRepayAmount}</> : "N/A"}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            )
                                : null
                            }
                            {/* {!pendingApplicationApprovalsDetailsSlice?.loading && !pendingApplicationApprovalsDetailsSlice?.error && pendingApplicationApprovalsDetails && "Data doesn't exist!"} */}
                        </Col>
                    </Row>

                    <div className="accountSummary p-0 mb-3 d-flex justify-content-around align-items-center">
                        {/* <h2 className="mb-3">Funding Details</h2> */}
                        <div className="action-dv my-4">
                            <div className="d-flex">
                                <Button className="btn-theme-primary" style={successButtonStyles} onMouseOver={handleSuccessBtnStyles} onClick={() => handleApproveOrReject("approve")}>
                                    Approve
                                </Button>
                            </div>
                        </div>
                        <div className="action-dv my-4">
                            <div className="d-flex">
                                {/* <Button className="btn-theme-primary" style={{ "width": "fit-content" }} onClick={() => handleApproveOrReject("reject")}> */}
                                <Button className="btn-theme-primary" style={dangerButtonStyles} onMouseOver={handleDangerBtnStyles} onClick={() => handleApproveOrReject("reject")}>
                                    &nbsp;&nbsp;&nbsp;Reject&nbsp;&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                    </div>


                    {/* come here */}
                    {loading && !error && !success && <div class="alert alert-info" role="alert"> Loading...</div>}
                    {!loading && !error && success && <div className="alert alert-primary" role="alert">Success!</div>}

                    {!loading && error && !success && <div className="alert alert-danger" role="alert">Something went Wrong!</div>}


                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>

            <MyConfirmModal
                show={confirmModalShow}
                onHide={() => setConfirmModalShow(false)}
                onConfirm={() => handleApproveOrRejectModal(
                    {
                        applicationId: pendingApplicationApprovalsDetails?.applicationId,
                        borrowerId: pendingApplicationApprovalsDetails?.borrowerId,
                        adminAction
                    }
                )}
                onCancel={handleCancel}
                adminAction={adminAction}
            // applicationId={pendingApplicationApprovalsDetails?.applicationId}
            />
        </>


    );
}




function MyConfirmModal(props) {
    const { adminAction } = props;
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="banForm confirm">
            <Modal.Header className="d-flex justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter" >
                    Are you sure you want to {adminAction} this application?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirmBtns d-flex justify-content-center">
                    <Button className="btn-white me-3" onClick={props.onConfirm}>Yes</Button>

                    <Button onClick={props.onHide}>Cancel</Button>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}




export default PendingApplicationApprovalDetail;
