import React, { useEffect, useState } from "react";

// Elements
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";

// Components
import HEADER from "../../../component/header/header";
import BREADCRUMB from "../../../component/breadcrumb/breadcrumb";
import INFO from "../../../component/wallet-management/info/platform-info";
import SIDEBAR from "../../../component/sidebar/sidebar";

import { useDispatch, useSelector } from "react-redux";
import {
  listPlatformWalletsTransactionHistory,
  getPlatformWalletsTransactionHistoryList,
} from "../../../features/wallet-management/listPlatformWalletsTransactionHistorySlice";

import {
  formatDate,
  formatTime,
} from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";

import WalletStatus from "../../../component/wallet-management/platform-wallet/wallet-status";

const BORROWERS = () => {
  const [userId, setUserId] = useState(useParams().id);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [transactionTypeFilter, setTransactionTypeFilter] = useState(undefined);
  const [transactionStatusFilter, setTransactionStatusFilter] =
    useState(undefined);

  // console.log("usre id wallet detials", userId)

  const data = {
    userId,
    pageSize,
    pageNo,
    sortBy,
    sortOrder,
    transactionTypeFilter,
    transactionStatusFilter,
  };
  const dispatch = useDispatch();
  const platformWalletstransactionHistorySlice = useSelector(
    getPlatformWalletsTransactionHistoryList
  );
  const transactionHistory =
    platformWalletstransactionHistorySlice?.transactionHistory;
  const transactions = transactionHistory?.transactions;

  useEffect(() => {
    (async () => {
      await dispatch(listPlatformWalletsTransactionHistory(data));
    })();
  }, [
    userId,
    pageSize,
    pageNo,
    sortBy,
    sortOrder,
    transactionTypeFilter,
    transactionStatusFilter,
  ]);

  useEffect(() => {
    // Update state values when borrowerSlice changes
    setTotal(
      platformWalletstransactionHistorySlice?.transactionHistory
        ?.totalTransactions
    );
    setTotalPages(
      platformWalletstransactionHistorySlice?.transactionHistory?.totalPages
    );
  }, [platformWalletstransactionHistorySlice]); //

  const [total, setTotal] = useState();
  const [totalPages, setTotalPages] = useState();

  // console.log("Running in the component Here are the PWTH Slice....", platformWalletstransactionHistorySlice);
  // console.log("Running in the component Here are the PWTH List....", transactions);

  const handleSearchChange = (e) => {
    e.preventDefault();
    console.log("Here in search");

    const idNumber = e.currentTarget.value;

    if (idNumber.length <= 9 && !isNaN(Number(idNumber))) {
      // if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
      transactionTypeFilter("id");

      // setId(idNumber)
    }
    if (idNumber.length === 0) {
      transactionTypeFilter(undefined);
      // setId(undefined)
    }

    setSortBy(undefined);
    setSortOrder(undefined);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const idNumber = e.currentTarget.elements.serachInput.value;
    // console.log(idNumber)
    if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
      // setfilterType("idNumber");
      // setfilterValue(idNumber);
      // setId(idNumber)
      setSortBy(undefined);
      setSortOrder(undefined);
    }
  };

  const handlePageNumber = (e) => {
    const value = e.currentTarget.value;

    if (
      !isNaN(Number(value)) &&
      Number(value) >= 1 &&
      Number(value) <= totalPages
    ) {
      setPageNo(value);
    }
  };

  const handlPrevClick = () => {
    const value = pageNo;
    if (
      !isNaN(Number(value)) &&
      Number(value) >= 1
      //  && Number(value) <= totalPages
    ) {
      if (Number(value) === 1) {
        setPageNo(1);
      } else {
        setPageNo((state) => Number(state) - 1);
      }
    }
  };

  const handlNextClick = () => {
    const value = pageNo;
    if (
      !isNaN(Number(value)) &&
      Number(value) < totalPages &&
      Number(value) >= 1
    ) {
      setPageNo((state) => Number(state) + 1);
      // if (Number(value) === totalPages) {
      //   setPage(totalPages)
      // }
    }
  };

  const reload = () => {
    setPageNo(1);
    setSortBy("createdAt");
    setSortOrder("desc");
    dispatch(listPlatformWalletsTransactionHistory(data));
  };

  return (
    <>
      <div className="dashoboard">
        <SIDEBAR />

        <div className="component">
          <HEADER />

          <BREADCRUMB />

          <div className="borrowers">
            <INFO />

            <div className="content m-0">
              <div className="optionsBox">
                <Row>
                  <Col lg="6">
                    <div className="searchBox">
                      <Form
                        className="searchForm"
                        onSubmit={handleSearchSubmit}
                      >
                        <Form.Control
                          className=""
                          id="serachInput"
                          placeholder="Search by Wallet IBAN, Holder ID"
                          onChange={handleSearchChange}
                        />
                        <Button type="submit" className="">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z"
                              stroke="url(#paint0_linear_15_1117)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_15_1117"
                                x1="1.46638"
                                y1="1.79751"
                                x2="15.8646"
                                y2="16.1974"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#236092" />
                                <stop offset="1" stopColor="#270089" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </Button>
                      </Form>
                    </div>
                  </Col>

                  <Col
                    lg="6"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="duration">
                      <form>
                        <h5>Duration</h5>

                        <div className="dateInputDv">
                          <Form.Control
                            className=""
                            type="date"
                            id="startDate"
                            placeholder="10/10/23"
                          />
                        </div>

                        <span>To</span>

                        <div className="dateInputDv">
                          <Form.Control
                            className=""
                            type="date"
                            id="endDate"
                            placeholder="10/10/23"
                          />
                        </div>

                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </form>
                    </div>

                    <div className="pagination">
                      <span className="totalRecord me-3">
                        {total ? total : "N/A"} Records{" "}
                      </span>
                      <Form.Control
                        className=""
                        id="pageCount"
                        placeholder=""
                        value={pageNo}
                        onChange={handlePageNumber}
                      />
                      <span className="sep">- </span>
                      <span className="showRecord">
                        {totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;
                      </span>
                      <span
                        className="prePage"
                        onClick={handlPrevClick}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="25"
                            height="25"
                            rx="12.5"
                            stroke="#696969"
                          />
                          <g clipPath="url(#clip0_0_1)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z"
                              fill="#696969"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_0_1">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span
                        className="nextPage"
                        style={{ cursor: "pointer" }}
                        onClick={handlNextClick}
                      >
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="25"
                            height="25"
                            rx="12.5"
                            stroke="#696969"
                          />
                          <g clipPath="url(#clip0_0_1)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z"
                              fill="#696969"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_0_1">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(4 22) rotate(-90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <span
                        className="reloadList"
                        style={{ cursor: "pointer" }}
                        onClick={reload}
                      >
                        <svg
                          fill="#696969"
                          height="12"
                          width="12"
                          viewBox="0 0 489.711 489.711"
                        >
                          <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                          <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                        </svg>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="accountSummary mb-3 pt-0 px-0 mb-5">
              <div className="records vertical-scroll active-loans-scroll activeLoanApp">
                <Table responsive className="mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>
                        <div className="labelIco">
                          <span className="headTitle">Date & Time</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Transaction ID</span>
                        </div>
                      </th>
                      <th style={{ width: "160px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Type</span>

                          <Dropdown
                            align={{ lg: "end" }}
                            className="statusDropdown"
                          >
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <span className="icon">
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.579 0C15.2539 0 1.58822 0 1.26316 0C0.565897 0 0 0.565897 0 1.26316C0 1.96043 0.565897 2.52632 1.26316 2.52632C1.58822 2.52632 15.2539 2.52632 15.579 2.52632C16.2763 2.52632 16.8422 1.96043 16.8422 1.26316C16.8422 0.565897 16.2763 0 15.579 0Z"
                                    fill="#270089"
                                  />
                                  <path
                                    opacity="0.35"
                                    d="M1.68433 2.52637L4.72349 6.17354C5.48055 7.08133 5.89487 8.2266 5.89487 9.40807V13.9588C5.89487 14.7824 6.49024 15.4847 7.30203 15.6203L9.47635 15.9824C10.2469 16.1113 10.9475 15.5176 10.9475 14.7369V9.40892C10.9475 8.2266 11.3618 7.08217 12.1189 6.17438L15.1581 2.52637H1.68433Z"
                                    fill="#270089"
                                  />
                                </svg>
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                All
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Wallet Top-up
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Withdrawal
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Installment
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Investment
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Application Fee
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </th>
                      <th style={{ width: "240px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Payment From</span>
                        </div>
                      </th>
                      <th style={{ width: "240px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Payment To</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Payment Status</span>

                          <Dropdown
                            align={{ lg: "end" }}
                            className="statusDropdown"
                          >
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <span className="icon">
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.579 0C15.2539 0 1.58822 0 1.26316 0C0.565897 0 0 0.565897 0 1.26316C0 1.96043 0.565897 2.52632 1.26316 2.52632C1.58822 2.52632 15.2539 2.52632 15.579 2.52632C16.2763 2.52632 16.8422 1.96043 16.8422 1.26316C16.8422 0.565897 16.2763 0 15.579 0Z"
                                    fill="#270089"
                                  />
                                  <path
                                    opacity="0.35"
                                    d="M1.68433 2.52637L4.72349 6.17354C5.48055 7.08133 5.89487 8.2266 5.89487 9.40807V13.9588C5.89487 14.7824 6.49024 15.4847 7.30203 15.6203L9.47635 15.9824C10.2469 16.1113 10.9475 15.5176 10.9475 14.7369V9.40892C10.9475 8.2266 11.3618 7.08217 12.1189 6.17438L15.1581 2.52637H1.68433Z"
                                    fill="#270089"
                                  />
                                </svg>
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                All
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Completed
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Pending
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Failed
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </th>
                      <th style={{ width: "130px" }}>
                        <div className="labelIco">
                          <span className="headTitle">Amount</span>
                        </div>
                      </th>
                      <th>
                        <div className="labelIco">
                          <span className="headTitle">Wallet Balance</span>
                        </div>
                      </th>
                      <th>
                        <div className="labelIco">
                          <span className="headTitle">Details</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {platformWalletstransactionHistorySlice?.loading && (
                      <div className="notifyNoRecord">Loading...</div>
                    )}
                    {!platformWalletstransactionHistorySlice?.loading &&
                      platformWalletstransactionHistorySlice?.error ? (
                      <div>
                        Error: {platformWalletstransactionHistorySlice.error}
                      </div>
                    ) : null}
                    {!platformWalletstransactionHistorySlice?.loading &&
                      transactions?.length
                      ? transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td style={{ width: "130px" }}>
                            <div className="dateTime">
                              <span className="date">
                                {formatDate(transaction.createdAt)}
                              </span>
                              <br />
                              <span className="time">
                                {formatTime(transaction.createdAt)}
                              </span>
                            </div>
                          </td>
                          <td style={{ width: "180px" }}>
                            <div className="transactionId">
                              <span>
                                {transaction.id ? transaction.id : "N/A"}
                              </span>
                            </div>
                          </td>
                          <td style={{ width: "160px" }}>
                            <div className="type">
                              <span>
                                {transaction.transactionType.details
                                  ? transaction.transactionType.details
                                  : "N/A"}
                              </span>
                            </div>
                          </td>
                          <td style={{ width: "240px" }}>
                            <div className="Payment From">
                              {transaction.fromAccount ? (
                                <>
                                  <span>
                                    IBAN No. ({transaction.fromAccount})
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>TLH PLATFORM</span>
                                </>
                              )}
                            </div>
                          </td>
                          <td style={{ width: "240px" }}>
                            <div className="paymentTo">
                              {transaction.toAccount.viban ? (
                                <>
                                  <span>
                                    IBAN No. ({transaction.toAccount.viban})
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>TLH PLATFORM</span>
                                </>
                              )}
                            </div>
                          </td>
                          <td style={{ width: "180px" }}>
                            <div className="status">
                              <span
                                className={`dot ${!transaction.status == "COMPLETED"
                                  ? "banned"
                                  : null
                                  }`}
                              ></span>
                              <span>{transaction.status}</span>
                            </div>
                          </td>
                          <td style={{ width: "130px" }}>
                            <div className="walletBalance">
                              {transaction.amount ? (
                                <>
                                  <span className="price">
                                    {formattedNumber(transaction.amount)}
                                  </span>
                                  <span className="currency">SAR</span>
                                </>
                              ) : (
                                <>
                                  <span className="price">N/A</span>
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="walletBalance">
                              {transaction?.balance ? (
                                <>
                                  <span className="price">
                                    {formattedNumber(transaction.balance)}
                                  </span>
                                  <span className="currency">SAR</span>
                                </>
                              ) : (
                                <>
                                  <span>N/A</span>
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="details">
                              {
                                transaction?.details ?
                                  <>
                                    <span>{transaction?.details}</span>
                                  </>
                                  :
                                  <>
                                    <span>N/A</span>
                                  </>
                              }
                            </div>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </Table>

                {!platformWalletstransactionHistorySlice?.loading &&
                  !transactions?.length &&
                  !platformWalletstransactionHistorySlice?.error &&
                  "No transactions exist!"}
              </div>
            </div>

            {/* <hr className="mb-4" /> */}

            {/* <WalletStatus /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BORROWERS;
