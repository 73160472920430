import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, postAPI } from "../../api/apiCallMethods";

// const URL =
//   "http://dev.thelendinghub.sa" +
//   "/ewallet/v1/admin/virtual-account/getStatusChangeLogs";
const URL =
  process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_STATUS_LOG;
// const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ALL_WALLETS_TRANSACTION_HISTORY;
// const URL = "http://localhost:8000/user/admin/wallet/get-status-log";

export const listAllWalletsStatusLog = createAsyncThunk(
  "allWallets/statusLog",
  async (params) => {
    const reqConfig = {
      method: "POST",
      url: URL,
      params,
    };
    console.log(
      "All Wallets Status Log List Slice - URL & Params:",
      URL,
      params
    );
    const response = await api(reqConfig);
    console.log("All Wallets Status Log List Slice - response:", response);
    // const response = await postAPI(URL, params);
    const listAllWalletsStatusLogPayload = await response.data.data;
    console.log(
      "All Wallets Status Log List Slice - payload:",
      listAllWalletsStatusLogPayload
    );
    return listAllWalletsStatusLogPayload;
  }
);

const listAllWalletsStatusLogSlice = createSlice({
  name: "allWallets/statusLog",
  initialState: {
    loading: false,
    statusLog: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(listAllWalletsStatusLog.pending, (state) => {
      state.loading = true;
      state.statusLog = null;
      state.error = null;
    });
    builder.addCase(listAllWalletsStatusLog.fulfilled, (state, action) => {
      state.loading = false;
      state.statusLog = action.payload;
      state.error = null;
    });
    builder.addCase(listAllWalletsStatusLog.rejected, (state, action) => {
      state.loading = false;
      state.statusLog = null;
      state.error = action.error.message;
    });
  },
});

export const getListAllWalletsStatusLogList = (state) =>
  state.allWalletStatusLog;
export default listAllWalletsStatusLogSlice.reducer;
